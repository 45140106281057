@import 'styles/common/index.scss';

.entity-view {
    header.entity-view-header {
        font-size: $fontSizeLarge;
    }

    .metrics {
        display: grid;
        margin: $padder 0;
        padding: $padder 0;
        border-top: $borderDashedThin;
        border-bottom: $borderDashedThin;
        grid-template-columns: repeat(4, max-content);

        >label {
            text-align: right;
        }

        .metric-value {
            margin: 0 $spacer 0 $padder;
        }
    }
}