@import 'styles/common/index.scss';

.character-sheet-demographics {
    @include character-panel();
    display: flex;
    flex-direction: row;
    flex: 0 1 0;

    @media screen and (min-width: $tabletThreshold) {
        grid-column: 1 / 3;
    }

    .main-info {
        @include flex-vertical();
        @include flex-fill();
    }

    .demographics {
        @include flex-fill();

        .name {
            @include font-fancy();
            font-size: $fontSizeTitle;
        }

        .description {
            display: flex;
            font-size: 0.9em;

            .kinship, .class-name {
                margin-left: $padder;
            }
        }
    }

    .body-mods {
        @include center-flexed();
        display: flex;

        .sheet-slot {
            margin: 0;

            .text {
                height: auto;
                line-height: normal;
            }
        }
    }

    .kinship-selector {
        width: auto;
    }

    .demographic-editor {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: $spacer;
        row-gap: $spacer;
        width: 100%;

        &:first-child {
            margin-top: 0;
        }

        label {
            display: flex;
            align-items: center;
        }

        .demographics-field {
            display: flex;

            >.demographics-field-container {
                flex: 1 0 0;
                display: flex;
                align-items: center;

                >.loader {
                    margin-top: 8px;
                    margin-left: 4px;
                }
            }
        }
    }
}