@font-face {
	font-family: "game-icons";
	// src: url('/public/assets/fonts/game-icons/game-icons.eot');
	// src: url('/public/assets/fonts/game-icons/game-icons.eot?#iefix') format('eot'),
	// src: url('/public/assets/fonts/game-icons/game-icons.woff?57151a33553d4cd40fbd2ab28fc85d88') format('woff'),
	// 	url('/public/assets/fonts/game-icons/game-icons.ttf') format('truetype');
	src: url("/public/assets/fonts/game-icons/game-icons.eot") format("embedded-opentype"),
		url("/public/assets/fonts/game-icons/game-icons.woff") format("woff"),
		url("/public/assets/fonts/game-icons/game-icons.ttf") format("truetype");
}

.game-icon:before {
	font-family: "game-icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}

.game-icon-3d-glasses:before {
	content: "\f000";
}
.game-icon-3d-hammer:before {
	content: "\f001";
}
.game-icon-3d-meeple:before {
content: "\f002";
}
.game-icon-3d-stairs:before {
	content: "\f003";
}
.game-icon-abacus:before {
	content: "\f004";
}
.game-icon-abbot-meeple:before {
	content: "\f005";
}
.game-icon-abdominal-armor:before {
	content: "\f006";
}
.game-icon-abstract-001:before {
	content: "\f007";
}
.game-icon-abstract-002:before {
	content: "\f008";
}
.game-icon-abstract-003:before {
	content: "\f009";
}
.game-icon-abstract-004:before {
	content: "\f00a";
}
.game-icon-abstract-005:before {
	content: "\f00b";
}
.game-icon-abstract-006:before {
	content: "\f00c";
}
.game-icon-abstract-007:before {
	content: "\f00d";
}
.game-icon-abstract-008:before {
	content: "\f00e";
}
.game-icon-abstract-009:before {
	content: "\f00f";
}
.game-icon-abstract-010:before {
	content: "\f010";
}
.game-icon-abstract-011:before {
	content: "\f011";
}
.game-icon-abstract-012:before {
	content: "\f012";
}
.game-icon-abstract-013:before {
	content: "\f013";
}
.game-icon-abstract-014:before {
	content: "\f014";
}
.game-icon-abstract-015:before {
	content: "\f015";
}
.game-icon-abstract-016:before {
	content: "\f016";
}
.game-icon-abstract-017:before {
	content: "\f017";
}
.game-icon-abstract-018:before {
	content: "\f018";
}
.game-icon-abstract-019:before {
	content: "\f019";
}
.game-icon-abstract-020:before {
	content: "\f01a";
}
.game-icon-abstract-021:before {
	content: "\f01b";
}
.game-icon-abstract-022:before {
	content: "\f01c";
}
.game-icon-abstract-023:before {
	content: "\f01d";
}
.game-icon-abstract-024:before {
	content: "\f01e";
}
.game-icon-abstract-025:before {
	content: "\f01f";
}
.game-icon-abstract-026:before {
	content: "\f020";
}
.game-icon-abstract-027:before {
	content: "\f021";
}
.game-icon-abstract-028:before {
	content: "\f022";
}
.game-icon-abstract-029:before {
	content: "\f023";
}
.game-icon-abstract-030:before {
	content: "\f024";
}
.game-icon-abstract-031:before {
	content: "\f025";
}
.game-icon-abstract-032:before {
	content: "\f026";
}
.game-icon-abstract-033:before {
	content: "\f027";
}
.game-icon-abstract-034:before {
	content: "\f028";
}
.game-icon-abstract-035:before {
	content: "\f029";
}
.game-icon-abstract-036:before {
	content: "\f02a";
}
.game-icon-abstract-037:before {
	content: "\f02b";
}
.game-icon-abstract-038:before {
	content: "\f02c";
}
.game-icon-abstract-039:before {
	content: "\f02d";
}
.game-icon-abstract-040:before {
	content: "\f02e";
}
.game-icon-abstract-041:before {
	content: "\f02f";
}
.game-icon-abstract-042:before {
	content: "\f030";
}
.game-icon-abstract-043:before {
	content: "\f031";
}
.game-icon-abstract-044:before {
	content: "\f032";
}
.game-icon-abstract-045:before {
	content: "\f033";
}
.game-icon-abstract-046:before {
	content: "\f034";
}
.game-icon-abstract-047:before {
	content: "\f035";
}
.game-icon-abstract-048:before {
	content: "\f036";
}
.game-icon-abstract-049:before {
	content: "\f037";
}
.game-icon-abstract-050:before {
	content: "\f038";
}
.game-icon-abstract-051:before {
	content: "\f039";
}
.game-icon-abstract-052:before {
	content: "\f03a";
}
.game-icon-abstract-053:before {
	content: "\f03b";
}
.game-icon-abstract-054:before {
	content: "\f03c";
}
.game-icon-abstract-055:before {
	content: "\f03d";
}
.game-icon-abstract-056:before {
	content: "\f03e";
}
.game-icon-abstract-057:before {
	content: "\f03f";
}
.game-icon-abstract-058:before {
	content: "\f040";
}
.game-icon-abstract-059:before {
	content: "\f041";
}
.game-icon-abstract-060:before {
	content: "\f042";
}
.game-icon-abstract-061:before {
	content: "\f043";
}
.game-icon-abstract-062:before {
	content: "\f044";
}
.game-icon-abstract-063:before {
	content: "\f045";
}
.game-icon-abstract-064:before {
	content: "\f046";
}
.game-icon-abstract-065:before {
	content: "\f047";
}
.game-icon-abstract-066:before {
	content: "\f048";
}
.game-icon-abstract-067:before {
	content: "\f049";
}
.game-icon-abstract-068:before {
	content: "\f04a";
}
.game-icon-abstract-069:before {
	content: "\f04b";
}
.game-icon-abstract-070:before {
	content: "\f04c";
}
.game-icon-abstract-071:before {
	content: "\f04d";
}
.game-icon-abstract-072:before {
	content: "\f04e";
}
.game-icon-abstract-073:before {
	content: "\f04f";
}
.game-icon-abstract-074:before {
	content: "\f050";
}
.game-icon-abstract-075:before {
	content: "\f051";
}
.game-icon-abstract-076:before {
	content: "\f052";
}
.game-icon-abstract-077:before {
	content: "\f053";
}
.game-icon-abstract-078:before {
	content: "\f054";
}
.game-icon-abstract-079:before {
	content: "\f055";
}
.game-icon-abstract-080:before {
	content: "\f056";
}
.game-icon-abstract-081:before {
	content: "\f057";
}
.game-icon-abstract-082:before {
	content: "\f058";
}
.game-icon-abstract-083:before {
	content: "\f059";
}
.game-icon-abstract-084:before {
	content: "\f05a";
}
.game-icon-abstract-085:before {
	content: "\f05b";
}
.game-icon-abstract-086:before {
	content: "\f05c";
}
.game-icon-abstract-087:before {
	content: "\f05d";
}
.game-icon-abstract-088:before {
	content: "\f05e";
}
.game-icon-abstract-089:before {
	content: "\f05f";
}
.game-icon-abstract-090:before {
	content: "\f060";
}
.game-icon-abstract-091:before {
	content: "\f061";
}
.game-icon-abstract-092:before {
	content: "\f062";
}
.game-icon-abstract-093:before {
	content: "\f063";
}
.game-icon-abstract-094:before {
	content: "\f064";
}
.game-icon-abstract-095:before {
	content: "\f065";
}
.game-icon-abstract-096:before {
	content: "\f066";
}
.game-icon-abstract-097:before {
	content: "\f067";
}
.game-icon-abstract-098:before {
	content: "\f068";
}
.game-icon-abstract-099:before {
	content: "\f069";
}
.game-icon-abstract-100:before {
	content: "\f06a";
}
.game-icon-abstract-101:before {
	content: "\f06b";
}
.game-icon-abstract-102:before {
	content: "\f06c";
}
.game-icon-abstract-103:before {
	content: "\f06d";
}
.game-icon-abstract-104:before {
	content: "\f06e";
}
.game-icon-abstract-105:before {
	content: "\f06f";
}
.game-icon-abstract-106:before {
	content: "\f070";
}
.game-icon-abstract-107:before {
	content: "\f071";
}
.game-icon-abstract-108:before {
	content: "\f072";
}
.game-icon-abstract-109:before {
	content: "\f073";
}
.game-icon-abstract-110:before {
	content: "\f074";
}
.game-icon-abstract-111:before {
	content: "\f075";
}
.game-icon-abstract-112:before {
	content: "\f076";
}
.game-icon-abstract-113:before {
	content: "\f077";
}
.game-icon-abstract-114:before {
	content: "\f078";
}
.game-icon-abstract-115:before {
	content: "\f079";
}
.game-icon-abstract-116:before {
	content: "\f07a";
}
.game-icon-abstract-117:before {
	content: "\f07b";
}
.game-icon-abstract-118:before {
	content: "\f07c";
}
.game-icon-abstract-119:before {
	content: "\f07d";
}
.game-icon-abstract-120:before {
	content: "\f07e";
}
.game-icon-abstract-121:before {
	content: "\f07f";
}
.game-icon-accordion:before {
	content: "\f080";
}
.game-icon-ace:before {
	content: "\f081";
}
.game-icon-achievement:before {
	content: "\f082";
}
.game-icon-achilles-heel:before {
	content: "\f083";
}
.game-icon-acid-blob:before {
	content: "\f084";
}
.game-icon-acid-tube:before {
	content: "\f085";
}
.game-icon-acid:before {
	content: "\f086";
}
.game-icon-acorn:before {
	content: "\f087";
}
.game-icon-acoustic-megaphone:before {
	content: "\f088";
}
.game-icon-acrobatic:before {
	content: "\f089";
}
.game-icon-aerial-signal:before {
	content: "\f08a";
}
.game-icon-aerodynamic-harpoon:before {
	content: "\f08b";
}
.game-icon-aerosol:before {
	content: "\f08c";
}
.game-icon-africa:before {
	content: "\f08d";
}
.game-icon-afterburn:before {
	content: "\f08e";
}
.game-icon-agave:before {
	content: "\f08f";
}
.game-icon-ages:before {
	content: "\f090";
}
.game-icon-air-balloon:before {
	content: "\f091";
}
.game-icon-air-force:before {
	content: "\f092";
}
.game-icon-air-man:before {
	content: "\f093";
}
.game-icon-air-zigzag:before {
	content: "\f094";
}
.game-icon-airplane-arrival:before {
	content: "\f095";
}
.game-icon-airplane-departure:before {
	content: "\f096";
}
.game-icon-airplane:before {
	content: "\f097";
}
.game-icon-airtight-hatch:before {
	content: "\f098";
}
.game-icon-ak47:before {
	content: "\f099";
}
.game-icon-ak47u:before {
	content: "\f09a";
}
.game-icon-akhet:before {
	content: "\f09b";
}
.game-icon-alarm-clock:before {
	content: "\f09c";
}
.game-icon-algae:before {
	content: "\f09d";
}
.game-icon-algeria:before {
	content: "\f09e";
}
.game-icon-alien-bug:before {
	content: "\f09f";
}
.game-icon-alien-egg:before {
	content: "\f0a0";
}
.game-icon-alien-fire:before {
	content: "\f0a1";
}
.game-icon-alien-skull:before {
	content: "\f0a2";
}
.game-icon-alien-stare:before {
	content: "\f0a3";
}
.game-icon-all-for-one:before {
	content: "\f0a4";
}
.game-icon-all-seeing-eye:before {
	content: "\f0a5";
}
.game-icon-allied-star:before {
	content: "\f0a6";
}
.game-icon-alligator-clip:before {
	content: "\f0a7";
}
.game-icon-almond:before {
	content: "\f0a8";
}
.game-icon-amber-mosquito:before {
	content: "\f0a9";
}
.game-icon-ambulance:before {
	content: "\f0aa";
}
.game-icon-american-football-ball:before {
	content: "\f0ab";
}
.game-icon-american-football-helmet:before {
	content: "\f0ac";
}
.game-icon-american-football-player:before {
	content: "\f0ad";
}
.game-icon-american-shield:before {
	content: "\f0ae";
}
.game-icon-amethyst:before {
	content: "\f0af";
}
.game-icon-ammo-box:before {
	content: "\f0b0";
}
.game-icon-ammonite-fossil:before {
	content: "\f0b1";
}
.game-icon-ammonite:before {
	content: "\f0b2";
}
.game-icon-amphora:before {
	content: "\f0b3";
}
.game-icon-ample-dress:before {
	content: "\f0b4";
}
.game-icon-amplitude:before {
	content: "\f0b5";
}
.game-icon-amputation:before {
	content: "\f0b6";
}
.game-icon-anarchy:before {
	content: "\f0b7";
}
.game-icon-anatomy:before {
	content: "\f0b8";
}
.game-icon-anchor:before {
	content: "\f0b9";
}
.game-icon-ancient-columns:before {
	content: "\f0ba";
}
.game-icon-ancient-ruins:before {
	content: "\f0bb";
}
.game-icon-ancient-screw:before {
	content: "\f0bc";
}
.game-icon-ancient-sword:before {
	content: "\f0bd";
}
.game-icon-android-mask:before {
	content: "\f0be";
}
.game-icon-andromeda-chain:before {
	content: "\f0bf";
}
.game-icon-angel-outfit:before {
	content: "\f0c0";
}
.game-icon-angel-wings:before {
	content: "\f0c1";
}
.game-icon-angler-fish:before {
	content: "\f0c2";
}
.game-icon-angola:before {
	content: "\f0c3";
}
.game-icon-angry-eyes:before {
	content: "\f0c4";
}
.game-icon-angular-spider:before {
	content: "\f0c5";
}
.game-icon-animal-hide:before {
	content: "\f0c6";
}
.game-icon-animal-skull:before {
	content: "\f0c7";
}
.game-icon-ankh:before {
	content: "\f0c8";
}
.game-icon-annexation:before {
	content: "\f0c9";
}
.game-icon-ant:before {
	content: "\f0ca";
}
.game-icon-antarctica:before {
	content: "\f0cb";
}
.game-icon-anteater:before {
	content: "\f0cc";
}
.game-icon-anthem:before {
	content: "\f0cd";
}
.game-icon-anti-aircraft-gun:before {
	content: "\f0ce";
}
.game-icon-antibody:before {
	content: "\f0cf";
}
.game-icon-anticlockwise-rotation:before {
	content: "\f0d0";
}
.game-icon-ants:before {
	content: "\f0d1";
}
.game-icon-anubis:before {
	content: "\f0d2";
}
.game-icon-anvil-impact:before {
	content: "\f0d3";
}
.game-icon-anvil:before {
	content: "\f0d4";
}
.game-icon-apc:before {
	content: "\f0d5";
}
.game-icon-apollo-capsule:before {
	content: "\f0d6";
}
.game-icon-apothecary:before {
	content: "\f0d7";
}
.game-icon-apple-core:before {
	content: "\f0d8";
}
.game-icon-apple-maggot:before {
	content: "\f0d9";
}
.game-icon-apple-seeds:before {
	content: "\f0da";
}
.game-icon-aquarium:before {
	content: "\f0db";
}
.game-icon-aquarius:before {
	content: "\f0dc";
}
.game-icon-aqueduct:before {
	content: "\f0dd";
}
.game-icon-arabic-door:before {
	content: "\f0de";
}
.game-icon-arc-triomphe:before {
	content: "\f0df";
}
.game-icon-arch-bridge:before {
	content: "\f0e0";
}
.game-icon-archaeopteryx-fossil:before {
	content: "\f0e1";
}
.game-icon-archer:before {
	content: "\f0e2";
}
.game-icon-archery-target:before {
	content: "\f0e3";
}
.game-icon-architect-mask:before {
	content: "\f0e4";
}
.game-icon-archive-register:before {
	content: "\f0e5";
}
.game-icon-archive-research:before {
	content: "\f0e6";
}
.game-icon-arcing-bolt:before {
	content: "\f0e7";
}
.game-icon-arena:before {
	content: "\f0e8";
}
.game-icon-aries:before {
	content: "\f0e9";
}
.game-icon-arm-bandage:before {
	content: "\f0ea";
}
.game-icon-arm-sling:before {
	content: "\f0eb";
}
.game-icon-arm:before {
	content: "\f0ec";
}
.game-icon-armadillo-tail:before {
	content: "\f0ed";
}
.game-icon-armadillo:before {
	content: "\f0ee";
}
.game-icon-armor-downgrade:before {
	content: "\f0ef";
}
.game-icon-armor-punch:before {
	content: "\f0f0";
}
.game-icon-armor-upgrade:before {
	content: "\f0f1";
}
.game-icon-armor-vest:before {
	content: "\f0f2";
}
.game-icon-armored-boomerang:before {
	content: "\f0f3";
}
.game-icon-armored-pants:before {
	content: "\f0f4";
}
.game-icon-armoured-shell:before {
	content: "\f0f5";
}
.game-icon-arrest:before {
	content: "\f0f6";
}
.game-icon-arrow-cluster:before {
	content: "\f0f7";
}
.game-icon-arrow-cursor:before {
	content: "\f0f8";
}
.game-icon-arrow-dunk:before {
	content: "\f0f9";
}
.game-icon-arrow-flights:before {
	content: "\f0fa";
}
.game-icon-arrow-scope:before {
	content: "\f0fb";
}
.game-icon-arrow-wings:before {
	content: "\f0fc";
}
.game-icon-arrowed:before {
	content: "\f0fd";
}
.game-icon-arrowhead:before {
	content: "\f0fe";
}
.game-icon-arrows-shield:before {
	content: "\f0ff";
}
.game-icon-arson:before {
	content: "\f100";
}
.game-icon-artificial-hive:before {
	content: "\f101";
}
.game-icon-artificial-intelligence:before {
	content: "\f102";
}
.game-icon-artillery-shell:before {
	content: "\f103";
}
.game-icon-ascending-block:before {
	content: "\f104";
}
.game-icon-asian-lantern:before {
	content: "\f105";
}
.game-icon-asparagus:before {
	content: "\f106";
}
.game-icon-aspergillum:before {
	content: "\f107";
}
.game-icon-assassin-pocket:before {
	content: "\f108";
}
.game-icon-asteroid:before {
	content: "\f109";
}
.game-icon-astrolabe:before {
	content: "\f10a";
}
.game-icon-astronaut-helmet:before {
	content: "\f10b";
}
.game-icon-at-sea:before {
	content: "\f10c";
}
.game-icon-atlas:before {
	content: "\f10d";
}
.game-icon-atom-core:before {
	content: "\f10e";
}
.game-icon-atom:before {
	content: "\f10f";
}
.game-icon-atomic-slashes:before {
	content: "\f110";
}
.game-icon-attached-shield:before {
	content: "\f111";
}
.game-icon-aubergine:before {
	content: "\f112";
}
.game-icon-audio-cassette:before {
	content: "\f113";
}
.game-icon-aura:before {
	content: "\f114";
}
.game-icon-australia:before {
	content: "\f115";
}
.game-icon-auto-repair:before {
	content: "\f116";
}
.game-icon-autogun:before {
	content: "\f117";
}
.game-icon-automatic-sas:before {
	content: "\f118";
}
.game-icon-avocado:before {
	content: "\f119";
}
.game-icon-avoidance:before {
	content: "\f11a";
}
.game-icon-awareness:before {
	content: "\f11b";
}
.game-icon-axe-in-log:before {
	content: "\f11c";
}
.game-icon-axe-in-stump:before {
	content: "\f11d";
}
.game-icon-axe-swing:before {
	content: "\f11e";
}
.game-icon-axe-sword:before {
	content: "\f11f";
}
.game-icon-axolotl:before {
	content: "\f120";
}
.game-icon-aztec-calendar-sun:before {
	content: "\f121";
}
.game-icon-azul-flake:before {
	content: "\f122";
}
.game-icon-baby-bottle:before {
	content: "\f123";
}
.game-icon-baby-face:before {
	content: "\f124";
}
.game-icon-babyfoot-players:before {
	content: "\f125";
}
.game-icon-back-forth:before {
	content: "\f126";
}
.game-icon-back-pain:before {
	content: "\f127";
}
.game-icon-backbone-shell:before {
	content: "\f128";
}
.game-icon-backgammon:before {
	content: "\f129";
}
.game-icon-backpack:before {
	content: "\f12a";
}
.game-icon-backstab:before {
	content: "\f12b";
}
.game-icon-backup:before {
	content: "\f12c";
}
.game-icon-backward-time:before {
	content: "\f12d";
}
.game-icon-bacon:before {
	content: "\f12e";
}
.game-icon-bad-breath:before {
	content: "\f12f";
}
.game-icon-bad-gnome:before {
	content: "\f130";
}
.game-icon-bagpipes:before {
	content: "\f131";
}
.game-icon-bal-leth:before {
	content: "\f132";
}
.game-icon-balaclava:before {
	content: "\f133";
}
.game-icon-balkenkreuz:before {
	content: "\f134";
}
.game-icon-ball-glow:before {
	content: "\f135";
}
.game-icon-ball-heart:before {
	content: "\f136";
}
.game-icon-ball-pyramid:before {
	content: "\f137";
}
.game-icon-ballerina-shoes:before {
	content: "\f138";
}
.game-icon-ballista:before {
	content: "\f139";
}
.game-icon-balloon-dog:before {
	content: "\f13a";
}
.game-icon-balloons:before {
	content: "\f13b";
}
.game-icon-bamboo-fountain:before {
	content: "\f13c";
}
.game-icon-bamboo:before {
	content: "\f13d";
}
.game-icon-banana-bunch:before {
	content: "\f13e";
}
.game-icon-banana-peel:before {
	content: "\f13f";
}
.game-icon-banana-peeled:before {
	content: "\f140";
}
.game-icon-banana:before {
	content: "\f141";
}
.game-icon-bandage-roll:before {
	content: "\f142";
}
.game-icon-bandaged:before {
	content: "\f143";
}
.game-icon-bandana:before {
	content: "\f144";
}
.game-icon-bandit:before {
	content: "\f145";
}
.game-icon-banging-gavel:before {
	content: "\f146";
}
.game-icon-banjo:before {
	content: "\f147";
}
.game-icon-bank:before {
	content: "\f148";
}
.game-icon-banknote:before {
	content: "\f149";
}
.game-icon-baobab:before {
	content: "\f14a";
}
.game-icon-bar-stool:before {
	content: "\f14b";
}
.game-icon-barbarian:before {
	content: "\f14c";
}
.game-icon-barbecue:before {
	content: "\f14d";
}
.game-icon-barbed-arrow:before {
	content: "\f14e";
}
.game-icon-barbed-coil:before {
	content: "\f14f";
}
.game-icon-barbed-nails:before {
	content: "\f150";
}
.game-icon-barbed-spear:before {
	content: "\f151";
}
.game-icon-barbed-star:before {
	content: "\f152";
}
.game-icon-barbed-sun:before {
	content: "\f153";
}
.game-icon-barbed-wire:before {
	content: "\f154";
}
.game-icon-barbute:before {
	content: "\f155";
}
.game-icon-barefoot:before {
	content: "\f156";
}
.game-icon-barn:before {
	content: "\f157";
}
.game-icon-barracks-tent:before {
	content: "\f158";
}
.game-icon-barracks:before {
	content: "\f159";
}
.game-icon-barrel-leak:before {
	content: "\f15a";
}
.game-icon-barrel:before {
	content: "\f15b";
}
.game-icon-barricade:before {
	content: "\f15c";
}
.game-icon-barrier:before {
	content: "\f15d";
}
.game-icon-base-dome:before {
	content: "\f15e";
}
.game-icon-baseball-bat:before {
	content: "\f15f";
}
.game-icon-baseball-glove:before {
	content: "\f160";
}
.game-icon-basket:before {
	content: "\f161";
}
.game-icon-basketball-ball:before {
	content: "\f162";
}
.game-icon-basketball-basket:before {
	content: "\f163";
}
.game-icon-basketball-jersey:before {
	content: "\f164";
}
.game-icon-basset-hound-head:before {
	content: "\f165";
}
.game-icon-bassoon:before {
	content: "\f166";
}
.game-icon-bastet:before {
	content: "\f167";
}
.game-icon-bat-2:before {
	content: "\f168";
}
.game-icon-bat-blade:before {
	content: "\f169";
}
.game-icon-bat-leth:before {
	content: "\f16a";
}
.game-icon-bat-mask:before {
	content: "\f16b";
}
.game-icon-bat-wing:before {
	content: "\f16c";
}
.game-icon-bat:before {
	content: "\f16d";
}
.game-icon-bathtub:before {
	content: "\f16e";
}
.game-icon-baton:before {
	content: "\f16f";
}
.game-icon-battered-axe:before {
	content: "\f170";
}
.game-icon-batteries:before {
	content: "\f171";
}
.game-icon-battery-0:before {
	content: "\f172";
}
.game-icon-battery-100:before {
	content: "\f173";
}
.game-icon-battery-25:before {
	content: "\f174";
}
.game-icon-battery-50:before {
	content: "\f175";
}
.game-icon-battery-75:before {
	content: "\f176";
}
.game-icon-battery-minus:before {
	content: "\f177";
}
.game-icon-battery-pack-alt:before {
	content: "\f178";
}
.game-icon-battery-pack:before {
	content: "\f179";
}
.game-icon-battery-plus:before {
	content: "\f17a";
}
.game-icon-battle-axe:before {
	content: "\f17b";
}
.game-icon-battle-gear:before {
	content: "\f17c";
}
.game-icon-battle-mech:before {
	content: "\f17d";
}
.game-icon-battle-tank:before {
	content: "\f17e";
}
.game-icon-battleship:before {
	content: "\f17f";
}
.game-icon-batwing-emblem:before {
	content: "\f180";
}
.game-icon-bayonet:before {
	content: "\f181";
}
.game-icon-beach-bag:before {
	content: "\f182";
}
.game-icon-beach-ball:before {
	content: "\f183";
}
.game-icon-beach-bucket:before {
	content: "\f184";
}
.game-icon-beam-satellite:before {
	content: "\f185";
}
.game-icon-beam-wake:before {
	content: "\f186";
}
.game-icon-beams-aura:before {
	content: "\f187";
}
.game-icon-beanstalk:before {
	content: "\f188";
}
.game-icon-bear-face:before {
	content: "\f189";
}
.game-icon-bear-head:before {
	content: "\f18a";
}
.game-icon-beard:before {
	content: "\f18b";
}
.game-icon-beast-eye:before {
	content: "\f18c";
}
.game-icon-beaver:before {
	content: "\f18d";
}
.game-icon-bed-lamp:before {
	content: "\f18e";
}
.game-icon-bed:before {
	content: "\f18f";
}
.game-icon-bee:before {
	content: "\f190";
}
.game-icon-beech:before {
	content: "\f191";
}
.game-icon-beehive:before {
	content: "\f192";
}
.game-icon-beer-bottle:before {
	content: "\f193";
}
.game-icon-beer-horn:before {
	content: "\f194";
}
.game-icon-beer-stein:before {
	content: "\f195";
}
.game-icon-beet:before {
	content: "\f196";
}
.game-icon-beetle-shell:before {
	content: "\f197";
}
.game-icon-behold:before {
	content: "\f198";
}
.game-icon-belgium:before {
	content: "\f199";
}
.game-icon-bell-pepper:before {
	content: "\f19a";
}
.game-icon-bell-shield:before {
	content: "\f19b";
}
.game-icon-bellows:before {
	content: "\f19c";
}
.game-icon-belt-armor:before {
	content: "\f19d";
}
.game-icon-belt-buckles:before {
	content: "\f19e";
}
.game-icon-belt:before {
	content: "\f19f";
}
.game-icon-berries-bowl:before {
	content: "\f1a0";
}
.game-icon-berry-bush:before {
	content: "\f1a1";
}
.game-icon-bestial-fangs:before {
	content: "\f1a2";
}
.game-icon-beveled-star:before {
	content: "\f1a3";
}
.game-icon-biceps:before {
	content: "\f1a4";
}
.game-icon-big-diamond-ring:before {
	content: "\f1a5";
}
.game-icon-big-egg:before {
	content: "\f1a6";
}
.game-icon-big-gear:before {
	content: "\f1a7";
}
.game-icon-big-wave:before {
	content: "\f1a8";
}
.game-icon-billed-cap:before {
	content: "\f1a9";
}
.game-icon-bindle:before {
	content: "\f1aa";
}
.game-icon-binoculars:before {
	content: "\f1ab";
}
.game-icon-biohazard:before {
	content: "\f1ac";
}
.game-icon-biplane:before {
	content: "\f1ad";
}
.game-icon-birch-trees:before {
	content: "\f1ae";
}
.game-icon-bird-cage:before {
	content: "\f1af";
}
.game-icon-bird-claw:before {
	content: "\f1b0";
}
.game-icon-bird-house:before {
	content: "\f1b1";
}
.game-icon-bird-limb:before {
	content: "\f1b2";
}
.game-icon-bird-mask:before {
	content: "\f1b3";
}
.game-icon-bird-twitter:before {
	content: "\f1b4";
}
.game-icon-bison:before {
	content: "\f1b5";
}
.game-icon-black-bar:before {
	content: "\f1b6";
}
.game-icon-black-belt:before {
	content: "\f1b7";
}
.game-icon-black-book:before {
	content: "\f1b8";
}
.game-icon-black-bridge:before {
	content: "\f1b9";
}
.game-icon-black-cat:before {
	content: "\f1ba";
}
.game-icon-black-flag:before {
	content: "\f1bb";
}
.game-icon-black-hand-shield:before {
	content: "\f1bc";
}
.game-icon-black-hole-bolas:before {
	content: "\f1bd";
}
.game-icon-black-knight-helm:before {
	content: "\f1be";
}
.game-icon-black-sea:before {
	content: "\f1bf";
}
.game-icon-blackball:before {
	content: "\f1c0";
}
.game-icon-blackcurrant:before {
	content: "\f1c1";
}
.game-icon-blacksmith:before {
	content: "\f1c2";
}
.game-icon-blade-bite:before {
	content: "\f1c3";
}
.game-icon-blade-drag:before {
	content: "\f1c4";
}
.game-icon-blade-fall:before {
	content: "\f1c5";
}
.game-icon-blast:before {
	content: "\f1c6";
}
.game-icon-blaster:before {
	content: "\f1c7";
}
.game-icon-bleeding-eye:before {
	content: "\f1c8";
}
.game-icon-bleeding-heart:before {
	content: "\f1c9";
}
.game-icon-bleeding-wound:before {
	content: "\f1ca";
}
.game-icon-blender:before {
	content: "\f1cb";
}
.game-icon-blindfold:before {
	content: "\f1cc";
}
.game-icon-block-house:before {
	content: "\f1cd";
}
.game-icon-blood:before {
	content: "\f1ce";
}
.game-icon-bloody-stash:before {
	content: "\f1cf";
}
.game-icon-bloody-sword:before {
	content: "\f1d0";
}
.game-icon-blunderbuss:before {
	content: "\f1d1";
}
.game-icon-bo:before {
	content: "\f1d2";
}
.game-icon-boar-ensign:before {
	content: "\f1d3";
}
.game-icon-boar-tusks:before {
	content: "\f1d4";
}
.game-icon-boar:before {
	content: "\f1d5";
}
.game-icon-boat-engine:before {
	content: "\f1d6";
}
.game-icon-boat-fishing:before {
	content: "\f1d7";
}
.game-icon-boat-horizon:before {
	content: "\f1d8";
}
.game-icon-boat-propeller:before {
	content: "\f1d9";
}
.game-icon-boba:before {
	content: "\f1da";
}
.game-icon-body-balance:before {
	content: "\f1db";
}
.game-icon-body-height:before {
	content: "\f1dc";
}
.game-icon-body-swapping:before {
	content: "\f1dd";
}
.game-icon-boiling-bubbles:before {
	content: "\f1de";
}
.game-icon-bok-choy:before {
	content: "\f1df";
}
.game-icon-bolas:before {
	content: "\f1e0";
}
.game-icon-bolivia:before {
	content: "\f1e1";
}
.game-icon-bolt-bomb:before {
	content: "\f1e2";
}
.game-icon-bolt-cutter:before {
	content: "\f1e3";
}
.game-icon-bolt-drop:before {
	content: "\f1e4";
}
.game-icon-bolt-eye:before {
	content: "\f1e5";
}
.game-icon-bolt-saw:before {
	content: "\f1e6";
}
.game-icon-bolt-shield:before {
	content: "\f1e7";
}
.game-icon-bolt-spell-cast:before {
	content: "\f1e8";
}
.game-icon-bolter-gun:before {
	content: "\f1e9";
}
.game-icon-bomber:before {
	content: "\f1ea";
}
.game-icon-bombing-run:before {
	content: "\f1eb";
}
.game-icon-bone-gnawer:before {
	content: "\f1ec";
}
.game-icon-bone-knife-2:before {
	content: "\f1ed";
}
.game-icon-bone-knife:before {
	content: "\f1ee";
}
.game-icon-bone-mace:before {
	content: "\f1ef";
}
.game-icon-bonsai-tree:before {
	content: "\f1f0";
}
.game-icon-book-aura:before {
	content: "\f1f1";
}
.game-icon-book-cover-2:before {
	content: "\f1f2";
}
.game-icon-book-cover:before {
	content: "\f1f3";
}
.game-icon-book-pile:before {
	content: "\f1f4";
}
.game-icon-book-storm:before {
	content: "\f1f5";
}
.game-icon-bookmark:before {
	content: "\f1f6";
}
.game-icon-bookmarklet:before {
	content: "\f1f7";
}
.game-icon-bookshelf:before {
	content: "\f1f8";
}
.game-icon-boombox:before {
	content: "\f1f9";
}
.game-icon-boomerang-cross:before {
	content: "\f1fa";
}
.game-icon-boomerang-sun:before {
	content: "\f1fb";
}
.game-icon-boomerang:before {
	content: "\f1fc";
}
.game-icon-boot-kick:before {
	content: "\f1fd";
}
.game-icon-boot-prints:before {
	content: "\f1fe";
}
.game-icon-boot-stomp:before {
	content: "\f1ff";
}
.game-icon-boots:before {
	content: "\f200";
}
.game-icon-booze:before {
	content: "\f201";
}
.game-icon-bordered-shield:before {
	content: "\f202";
}
.game-icon-boss-key:before {
	content: "\f203";
}
.game-icon-bottle-cap:before {
	content: "\f204";
}
.game-icon-bottle-vapors:before {
	content: "\f205";
}
.game-icon-bottled-bolt:before {
	content: "\f206";
}
.game-icon-bottled-shadow:before {
	content: "\f207";
}
.game-icon-bottom-right-3d-arrow:before {
	content: "\f208";
}
.game-icon-boulder-dash:before {
	content: "\f209";
}
.game-icon-bouncing-spring:before {
	content: "\f20a";
}
.game-icon-bouncing-sword:before {
	content: "\f20b";
}
.game-icon-bow-arrow:before {
	content: "\f20c";
}
.game-icon-bow-string:before {
	content: "\f20d";
}
.game-icon-bow-tie-ribbon:before {
	content: "\f20e";
}
.game-icon-bow-tie:before {
	content: "\f20f";
}
.game-icon-bowels:before {
	content: "\f210";
}
.game-icon-bowen-knot:before {
	content: "\f211";
}
.game-icon-bowie-knife-2:before {
	content: "\f212";
}
.game-icon-bowie-knife:before {
	content: "\f213";
}
.game-icon-bowl-of-rice:before {
	content: "\f214";
}
.game-icon-bowl-spiral:before {
	content: "\f215";
}
.game-icon-bowling-alley:before {
	content: "\f216";
}
.game-icon-bowling-pin:before {
	content: "\f217";
}
.game-icon-bowling-propulsion:before {
	content: "\f218";
}
.game-icon-bowling-strike:before {
	content: "\f219";
}
.game-icon-bowman:before {
	content: "\f21a";
}
.game-icon-box-cutter:before {
	content: "\f21b";
}
.game-icon-box-trap:before {
	content: "\f21c";
}
.game-icon-box-unpacking:before {
	content: "\f21d";
}
.game-icon-boxing-glove-surprise:before {
	content: "\f21e";
}
.game-icon-boxing-glove:before {
	content: "\f21f";
}
.game-icon-boxing-ring:before {
	content: "\f220";
}
.game-icon-bracer:before {
	content: "\f221";
}
.game-icon-bracers:before {
	content: "\f222";
}
.game-icon-brain-dump:before {
	content: "\f223";
}
.game-icon-brain-freeze:before {
	content: "\f224";
}
.game-icon-brain-leak:before {
	content: "\f225";
}
.game-icon-brain-stem:before {
	content: "\f226";
}
.game-icon-brain-tentacle:before {
	content: "\f227";
}
.game-icon-brain:before {
	content: "\f228";
}
.game-icon-brainstorm:before {
	content: "\f229";
}
.game-icon-branch-arrow:before {
	content: "\f22a";
}
.game-icon-brandy-bottle:before {
	content: "\f22b";
}
.game-icon-brasero:before {
	content: "\f22c";
}
.game-icon-brass-eye:before {
	content: "\f22d";
}
.game-icon-brass-knuckles:before {
	content: "\f22e";
}
.game-icon-brazil-flag:before {
	content: "\f22f";
}
.game-icon-brazil:before {
	content: "\f230";
}
.game-icon-bread-slice:before {
	content: "\f231";
}
.game-icon-bread:before {
	content: "\f232";
}
.game-icon-breaking-chain:before {
	content: "\f233";
}
.game-icon-breastplate:before {
	content: "\f234";
}
.game-icon-brick-pile:before {
	content: "\f235";
}
.game-icon-brick-wall:before {
	content: "\f236";
}
.game-icon-bridge:before {
	content: "\f237";
}
.game-icon-briefcase:before {
	content: "\f238";
}
.game-icon-bright-explosion:before {
	content: "\f239";
}
.game-icon-broad-dagger:before {
	content: "\f23a";
}
.game-icon-broadhead-arrow:before {
	content: "\f23b";
}
.game-icon-broadsword:before {
	content: "\f23c";
}
.game-icon-broccoli:before {
	content: "\f23d";
}
.game-icon-brodie-helmet:before {
	content: "\f23e";
}
.game-icon-broken-arrow:before {
	content: "\f23f";
}
.game-icon-broken-axe:before {
	content: "\f240";
}
.game-icon-broken-bone:before {
	content: "\f241";
}
.game-icon-broken-bottle:before {
	content: "\f242";
}
.game-icon-broken-heart-zone:before {
	content: "\f243";
}
.game-icon-broken-heart:before {
	content: "\f244";
}
.game-icon-broken-pottery:before {
	content: "\f245";
}
.game-icon-broken-ribbon:before {
	content: "\f246";
}
.game-icon-broken-shield:before {
	content: "\f247";
}
.game-icon-broken-skull:before {
	content: "\f248";
}
.game-icon-broken-tablet:before {
	content: "\f249";
}
.game-icon-broken-wall:before {
	content: "\f24a";
}
.game-icon-broom:before {
	content: "\f24b";
}
.game-icon-brutal-helm:before {
	content: "\f24c";
}
.game-icon-brute:before {
	content: "\f24d";
}
.game-icon-bubble-field:before {
	content: "\f24e";
}
.game-icon-bubbles:before {
	content: "\f24f";
}
.game-icon-bubbling-beam:before {
	content: "\f250";
}
.game-icon-bubbling-bowl:before {
	content: "\f251";
}
.game-icon-bubbling-flask:before {
	content: "\f252";
}
.game-icon-bud:before {
	content: "\f253";
}
.game-icon-buffalo-head:before {
	content: "\f254";
}
.game-icon-bug-net:before {
	content: "\f255";
}
.game-icon-bugle-call:before {
	content: "\f256";
}
.game-icon-bulb:before {
	content: "\f257";
}
.game-icon-bulgaria:before {
	content: "\f258";
}
.game-icon-bull-horns:before {
	content: "\f259";
}
.game-icon-bull:before {
	content: "\f25a";
}
.game-icon-bulldozer:before {
	content: "\f25b";
}
.game-icon-bullet-bill:before {
	content: "\f25c";
}
.game-icon-bullet-impacts:before {
	content: "\f25d";
}
.game-icon-bullets:before {
	content: "\f25e";
}
.game-icon-bullseye:before {
	content: "\f25f";
}
.game-icon-bully-minion:before {
	content: "\f260";
}
.game-icon-bundle-grenade:before {
	content: "\f261";
}
.game-icon-bunk-beds:before {
	content: "\f262";
}
.game-icon-bunker-assault:before {
	content: "\f263";
}
.game-icon-bunker:before {
	content: "\f264";
}
.game-icon-bunny-slippers:before {
	content: "\f265";
}
.game-icon-buoy:before {
	content: "\f266";
}
.game-icon-burn:before {
	content: "\f267";
}
.game-icon-burning-blobs:before {
	content: "\f268";
}
.game-icon-burning-book:before {
	content: "\f269";
}
.game-icon-burning-dot:before {
	content: "\f26a";
}
.game-icon-burning-embers:before {
	content: "\f26b";
}
.game-icon-burning-eye:before {
	content: "\f26c";
}
.game-icon-burning-forest:before {
	content: "\f26d";
}
.game-icon-burning-meteor:before {
	content: "\f26e";
}
.game-icon-burning-passion:before {
	content: "\f26f";
}
.game-icon-burning-round-shot:before {
	content: "\f270";
}
.game-icon-burning-skull:before {
	content: "\f271";
}
.game-icon-burning-tree:before {
	content: "\f272";
}
.game-icon-burst-blob:before {
	content: "\f273";
}
.game-icon-bus-doors:before {
	content: "\f274";
}
.game-icon-bus-stop:before {
	content: "\f275";
}
.game-icon-bus:before {
	content: "\f276";
}
.game-icon-butter-toast:before {
	content: "\f277";
}
.game-icon-butter:before {
	content: "\f278";
}
.game-icon-butterfly-flower:before {
	content: "\f279";
}
.game-icon-butterfly-knife-2:before {
	content: "\f27a";
}
.game-icon-butterfly-knife:before {
	content: "\f27b";
}
.game-icon-butterfly-warning:before {
	content: "\f27c";
}
.game-icon-butterfly:before {
	content: "\f27d";
}
.game-icon-button-finger:before {
	content: "\f27e";
}
.game-icon-buy-card:before {
	content: "\f27f";
}
.game-icon-byzantin-temple:before {
	content: "\f280";
}
.game-icon-c96:before {
	content: "\f281";
}
.game-icon-cabbage:before {
	content: "\f282";
}
.game-icon-cable-stayed-bridge:before {
	content: "\f283";
}
.game-icon-cactus-pot:before {
	content: "\f284";
}
.game-icon-cactus-tap:before {
	content: "\f285";
}
.game-icon-cactus:before {
	content: "\f286";
}
.game-icon-cadillac-helm:before {
	content: "\f287";
}
.game-icon-caduceus:before {
	content: "\f288";
}
.game-icon-caesar:before {
	content: "\f289";
}
.game-icon-cage:before {
	content: "\f28a";
}
.game-icon-caged-ball:before {
	content: "\f28b";
}
.game-icon-cake-slice:before {
	content: "\f28c";
}
.game-icon-calavera:before {
	content: "\f28d";
}
.game-icon-calculator:before {
	content: "\f28e";
}
.game-icon-caldera:before {
	content: "\f28f";
}
.game-icon-calendar-half-year:before {
	content: "\f290";
}
.game-icon-calendar:before {
	content: "\f291";
}
.game-icon-caltrops:before {
	content: "\f292";
}
.game-icon-camargue-cross:before {
	content: "\f293";
}
.game-icon-cambodia:before {
	content: "\f294";
}
.game-icon-camel-head:before {
	content: "\f295";
}
.game-icon-camel:before {
	content: "\f296";
}
.game-icon-camp-cooking-pot:before {
	content: "\f297";
}
.game-icon-campfire:before {
	content: "\f298";
}
.game-icon-camping-tent:before {
	content: "\f299";
}
.game-icon-cancel:before {
	content: "\f29a";
}
.game-icon-cancer:before {
	content: "\f29b";
}
.game-icon-candle-flame:before {
	content: "\f29c";
}
.game-icon-candle-holder:before {
	content: "\f29d";
}
.game-icon-candle-light:before {
	content: "\f29e";
}
.game-icon-candle-skull:before {
	content: "\f29f";
}
.game-icon-candlebright:before {
	content: "\f2a0";
}
.game-icon-candles:before {
	content: "\f2a1";
}
.game-icon-candlestick-phone:before {
	content: "\f2a2";
}
.game-icon-candy-canes:before {
	content: "\f2a3";
}
.game-icon-canned-fish:before {
	content: "\f2a4";
}
.game-icon-cannister:before {
	content: "\f2a5";
}
.game-icon-cannon-ball:before {
	content: "\f2a6";
}
.game-icon-cannon-shot:before {
	content: "\f2a7";
}
.game-icon-cannon:before {
	content: "\f2a8";
}
.game-icon-canoe:before {
	content: "\f2a9";
}
.game-icon-cantua:before {
	content: "\f2aa";
}
.game-icon-cape-armor:before {
	content: "\f2ab";
}
.game-icon-cape:before {
	content: "\f2ac";
}
.game-icon-capitol:before {
	content: "\f2ad";
}
.game-icon-capricorn:before {
	content: "\f2ae";
}
.game-icon-captain-hat-profile:before {
	content: "\f2af";
}
.game-icon-capybara:before {
	content: "\f2b0";
}
.game-icon-car-battery:before {
	content: "\f2b1";
}
.game-icon-car-door:before {
	content: "\f2b2";
}
.game-icon-car-key:before {
	content: "\f2b3";
}
.game-icon-car-seat:before {
	content: "\f2b4";
}
.game-icon-car-wheel:before {
	content: "\f2b5";
}
.game-icon-carabiner:before {
	content: "\f2b6";
}
.game-icon-carambola:before {
	content: "\f2b7";
}
.game-icon-caravan:before {
	content: "\f2b8";
}
.game-icon-caravel:before {
	content: "\f2b9";
}
.game-icon-card-10-clubs:before {
	content: "\f2ba";
}
.game-icon-card-10-diamonds:before {
	content: "\f2bb";
}
.game-icon-card-10-hearts:before {
	content: "\f2bc";
}
.game-icon-card-10-spades:before {
	content: "\f2bd";
}
.game-icon-card-2-clubs:before {
	content: "\f2be";
}
.game-icon-card-2-diamonds:before {
	content: "\f2bf";
}
.game-icon-card-2-hearts:before {
	content: "\f2c0";
}
.game-icon-card-2-spades:before {
	content: "\f2c1";
}
.game-icon-card-3-clubs:before {
	content: "\f2c2";
}
.game-icon-card-3-diamonds:before {
	content: "\f2c3";
}
.game-icon-card-3-hearts:before {
	content: "\f2c4";
}
.game-icon-card-3-spades:before {
	content: "\f2c5";
}
.game-icon-card-4-clubs:before {
	content: "\f2c6";
}
.game-icon-card-4-diamonds:before {
	content: "\f2c7";
}
.game-icon-card-4-hearts:before {
	content: "\f2c8";
}
.game-icon-card-4-spades:before {
	content: "\f2c9";
}
.game-icon-card-5-clubs:before {
	content: "\f2ca";
}
.game-icon-card-5-diamonds:before {
	content: "\f2cb";
}
.game-icon-card-5-hearts:before {
	content: "\f2cc";
}
.game-icon-card-5-spades:before {
	content: "\f2cd";
}
.game-icon-card-6-clubs:before {
	content: "\f2ce";
}
.game-icon-card-6-diamonds:before {
	content: "\f2cf";
}
.game-icon-card-6-hearts:before {
	content: "\f2d0";
}
.game-icon-card-6-spades:before {
	content: "\f2d1";
}
.game-icon-card-7-clubs:before {
	content: "\f2d2";
}
.game-icon-card-7-diamonds:before {
	content: "\f2d3";
}
.game-icon-card-7-hearts:before {
	content: "\f2d4";
}
.game-icon-card-7-spades:before {
	content: "\f2d5";
}
.game-icon-card-8-clubs:before {
	content: "\f2d6";
}
.game-icon-card-8-diamonds:before {
	content: "\f2d7";
}
.game-icon-card-8-hearts:before {
	content: "\f2d8";
}
.game-icon-card-8-spades:before {
	content: "\f2d9";
}
.game-icon-card-9-clubs:before {
	content: "\f2da";
}
.game-icon-card-9-diamonds:before {
	content: "\f2db";
}
.game-icon-card-9-hearts:before {
	content: "\f2dc";
}
.game-icon-card-9-spades:before {
	content: "\f2dd";
}
.game-icon-card-ace-clubs:before {
	content: "\f2de";
}
.game-icon-card-ace-diamonds:before {
	content: "\f2df";
}
.game-icon-card-ace-hearts:before {
	content: "\f2e0";
}
.game-icon-card-ace-spades:before {
	content: "\f2e1";
}
.game-icon-card-burn:before {
	content: "\f2e2";
}
.game-icon-card-discard:before {
	content: "\f2e3";
}
.game-icon-card-draw:before {
	content: "\f2e4";
}
.game-icon-card-exchange:before {
	content: "\f2e5";
}
.game-icon-card-jack-clubs:before {
	content: "\f2e6";
}
.game-icon-card-jack-diamonds:before {
	content: "\f2e7";
}
.game-icon-card-jack-hearts:before {
	content: "\f2e8";
}
.game-icon-card-jack-spades:before {
	content: "\f2e9";
}
.game-icon-card-joker:before {
	content: "\f2ea";
}
.game-icon-card-king-clubs:before {
	content: "\f2eb";
}
.game-icon-card-king-diamonds:before {
	content: "\f2ec";
}
.game-icon-card-king-hearts:before {
	content: "\f2ed";
}
.game-icon-card-king-spades:before {
	content: "\f2ee";
}
.game-icon-card-pick:before {
	content: "\f2ef";
}
.game-icon-card-pickup:before {
	content: "\f2f0";
}
.game-icon-card-play:before {
	content: "\f2f1";
}
.game-icon-card-queen-clubs:before {
	content: "\f2f2";
}
.game-icon-card-queen-diamonds:before {
	content: "\f2f3";
}
.game-icon-card-queen-hearts:before {
	content: "\f2f4";
}
.game-icon-card-queen-spades:before {
	content: "\f2f5";
}
.game-icon-card-random:before {
	content: "\f2f6";
}
.game-icon-cardboard-box-closed:before {
	content: "\f2f7";
}
.game-icon-cardboard-box:before {
	content: "\f2f8";
}
.game-icon-cargo-crane:before {
	content: "\f2f9";
}
.game-icon-cargo-crate:before {
	content: "\f2fa";
}
.game-icon-cargo-ship:before {
	content: "\f2fb";
}
.game-icon-carillon:before {
	content: "\f2fc";
}
.game-icon-carnival-mask:before {
	content: "\f2fd";
}
.game-icon-carnivore-mouth:before {
	content: "\f2fe";
}
.game-icon-carnivorous-plant:before {
	content: "\f2ff";
}
.game-icon-carnyx:before {
	content: "\f300";
}
.game-icon-carousel:before {
	content: "\f301";
}
.game-icon-carpet-bombing:before {
	content: "\f302";
}
.game-icon-carrier:before {
	content: "\f303";
}
.game-icon-carrion:before {
	content: "\f304";
}
.game-icon-carrot:before {
	content: "\f305";
}
.game-icon-cartwheel:before {
	content: "\f306";
}
.game-icon-cash:before {
	content: "\f307";
}
.game-icon-cassowary-head:before {
	content: "\f308";
}
.game-icon-castle-2:before {
	content: "\f309";
}
.game-icon-castle-ruins:before {
	content: "\f30a";
}
.game-icon-castle:before {
	content: "\f30b";
}
.game-icon-cat:before {
	content: "\f30c";
}
.game-icon-catapult:before {
	content: "\f30d";
}
.game-icon-catch:before {
	content: "\f30e";
}
.game-icon-caterpillar:before {
	content: "\f30f";
}
.game-icon-cauldron-2:before {
	content: "\f310";
}
.game-icon-cauldron:before {
	content: "\f311";
}
.game-icon-cavalry:before {
	content: "\f312";
}
.game-icon-cave-entrance:before {
	content: "\f313";
}
.game-icon-caveman:before {
	content: "\f314";
}
.game-icon-cctv-camera:before {
	content: "\f315";
}
.game-icon-ceiling-barnacle:before {
	content: "\f316";
}
.game-icon-ceiling-light:before {
	content: "\f317";
}
.game-icon-celebration-fire:before {
	content: "\f318";
}
.game-icon-cellar-barrels:before {
	content: "\f319";
}
.game-icon-cement-shoes:before {
	content: "\f31a";
}
.game-icon-centaur-heart:before {
	content: "\f31b";
}
.game-icon-centaur:before {
	content: "\f31c";
}
.game-icon-centipede:before {
	content: "\f31d";
}
.game-icon-centurion-helmet:before {
	content: "\f31e";
}
.game-icon-ceremonial-mask:before {
	content: "\f31f";
}
.game-icon-chain-lightning:before {
	content: "\f320";
}
.game-icon-chain-mail:before {
	content: "\f321";
}
.game-icon-chained-arrow-heads:before {
	content: "\f322";
}
.game-icon-chained-heart:before {
	content: "\f323";
}
.game-icon-chaingun:before {
	content: "\f324";
}
.game-icon-chainsaw:before {
	content: "\f325";
}
.game-icon-chakram:before {
	content: "\f326";
}
.game-icon-chalice-drops:before {
	content: "\f327";
}
.game-icon-chalk-outline-murder:before {
	content: "\f328";
}
.game-icon-chameleon-glyph:before {
	content: "\f329";
}
.game-icon-champagne-cork:before {
	content: "\f32a";
}
.game-icon-champions:before {
	content: "\f32b";
}
.game-icon-chanterelles:before {
	content: "\f32c";
}
.game-icon-character:before {
	content: "\f32d";
}
.game-icon-charcuterie:before {
	content: "\f32e";
}
.game-icon-charged-arrow:before {
	content: "\f32f";
}
.game-icon-charging-bull:before {
	content: "\f330";
}
.game-icon-charging:before {
	content: "\f331";
}
.game-icon-chariot:before {
	content: "\f332";
}
.game-icon-charm:before {
	content: "\f333";
}
.game-icon-chart:before {
	content: "\f334";
}
.game-icon-chat-bubble:before {
	content: "\f335";
}
.game-icon-check-mark:before {
	content: "\f336";
}
.game-icon-checkbox-tree:before {
	content: "\f337";
}
.game-icon-checked-shield:before {
	content: "\f338";
}
.game-icon-checkered-diamond:before {
	content: "\f339";
}
.game-icon-checkered-flag:before {
	content: "\f33a";
}
.game-icon-checklist:before {
	content: "\f33b";
}
.game-icon-cheerful:before {
	content: "\f33c";
}
.game-icon-cheese-wedge:before {
	content: "\f33d";
}
.game-icon-chef-toque:before {
	content: "\f33e";
}
.game-icon-chelsea-boot:before {
	content: "\f33f";
}
.game-icon-chemical-arrow:before {
	content: "\f340";
}
.game-icon-chemical-bolt:before {
	content: "\f341";
}
.game-icon-chemical-drop:before {
	content: "\f342";
}
.game-icon-chemical-tank:before {
	content: "\f343";
}
.game-icon-cherish:before {
	content: "\f344";
}
.game-icon-cherry:before {
	content: "\f345";
}
.game-icon-chess-bishop:before {
	content: "\f346";
}
.game-icon-chess-king:before {
	content: "\f347";
}
.game-icon-chess-knight:before {
	content: "\f348";
}
.game-icon-chess-pawn:before {
	content: "\f349";
}
.game-icon-chess-queen:before {
	content: "\f34a";
}
.game-icon-chess-rook:before {
	content: "\f34b";
}
.game-icon-chest-armor:before {
	content: "\f34c";
}
.game-icon-chest:before {
	content: "\f34d";
}
.game-icon-chestnut-leaf:before {
	content: "\f34e";
}
.game-icon-chewed-heart:before {
	content: "\f34f";
}
.game-icon-chewed-skull:before {
	content: "\f350";
}
.game-icon-chicken-leg:before {
	content: "\f351";
}
.game-icon-chicken-oven:before {
	content: "\f352";
}
.game-icon-chicken:before {
	content: "\f353";
}
.game-icon-chili-pepper:before {
	content: "\f354";
}
.game-icon-chimney:before {
	content: "\f355";
}
.game-icon-chips-bag:before {
	content: "\f356";
}
.game-icon-chisel:before {
	content: "\f357";
}
.game-icon-chocolate-bar:before {
	content: "\f358";
}
.game-icon-choice:before {
	content: "\f359";
}
.game-icon-chopped-skull:before {
	content: "\f35a";
}
.game-icon-chopsticks:before {
	content: "\f35b";
}
.game-icon-church:before {
	content: "\f35c";
}
.game-icon-cigale:before {
	content: "\f35d";
}
.game-icon-cigar:before {
	content: "\f35e";
}
.game-icon-cigarette:before {
	content: "\f35f";
}
.game-icon-circle-cage:before {
	content: "\f360";
}
.game-icon-circle-claws:before {
	content: "\f361";
}
.game-icon-circle-forest:before {
	content: "\f362";
}
.game-icon-circle-sparks:before {
	content: "\f363";
}
.game-icon-circle:before {
	content: "\f364";
}
.game-icon-circling-fish:before {
	content: "\f365";
}
.game-icon-circuitry:before {
	content: "\f366";
}
.game-icon-circular-saw:before {
	content: "\f367";
}
.game-icon-circular-sawblade:before {
	content: "\f368";
}
.game-icon-city-car:before {
	content: "\f369";
}
.game-icon-clamp:before {
	content: "\f36a";
}
.game-icon-clapperboard:before {
	content: "\f36b";
}
.game-icon-clarinet:before {
	content: "\f36c";
}
.game-icon-classical-knowledge:before {
	content: "\f36d";
}
.game-icon-claw-hammer:before {
	content: "\f36e";
}
.game-icon-claw-slashes:before {
	content: "\f36f";
}
.game-icon-claw-string:before {
	content: "\f370";
}
.game-icon-claw:before {
	content: "\f371";
}
.game-icon-claws:before {
	content: "\f372";
}
.game-icon-clay-brick:before {
	content: "\f373";
}
.game-icon-claymore-explosive:before {
	content: "\f374";
}
.game-icon-cleaver:before {
	content: "\f375";
}
.game-icon-cleopatra:before {
	content: "\f376";
}
.game-icon-click:before {
	content: "\f377";
}
.game-icon-cliff-crossing:before {
	content: "\f378";
}
.game-icon-cloak-dagger:before {
	content: "\f379";
}
.game-icon-cloak:before {
	content: "\f37a";
}
.game-icon-cloaked-figure-on-horseback:before {
	content: "\f37b";
}
.game-icon-clockwise-rotation:before {
	content: "\f37c";
}
.game-icon-clockwork:before {
	content: "\f37d";
}
.game-icon-closed-barbute:before {
	content: "\f37e";
}
.game-icon-closed-doors:before {
	content: "\f37f";
}
.game-icon-cloth-jar:before {
	content: "\f380";
}
.game-icon-clothes:before {
	content: "\f381";
}
.game-icon-clothesline:before {
	content: "\f382";
}
.game-icon-clothespin:before {
	content: "\f383";
}
.game-icon-cloud-download:before {
	content: "\f384";
}
.game-icon-cloud-ring:before {
	content: "\f385";
}
.game-icon-cloud-upload:before {
	content: "\f386";
}
.game-icon-cloudy-fork:before {
	content: "\f387";
}
.game-icon-clout:before {
	content: "\f388";
}
.game-icon-clover-2:before {
	content: "\f389";
}
.game-icon-clover-spiked:before {
	content: "\f38a";
}
.game-icon-clover:before {
	content: "\f38b";
}
.game-icon-clown:before {
	content: "\f38c";
}
.game-icon-clownfish:before {
	content: "\f38d";
}
.game-icon-clubs:before {
	content: "\f38e";
}
.game-icon-cluster-bomb:before {
	content: "\f38f";
}
.game-icon-coa-de-jima:before {
	content: "\f390";
}
.game-icon-coal-pile:before {
	content: "\f391";
}
.game-icon-coal-wagon:before {
	content: "\f392";
}
.game-icon-cobra-2:before {
	content: "\f393";
}
.game-icon-cobra:before {
	content: "\f394";
}
.game-icon-cobweb:before {
	content: "\f395";
}
.game-icon-coconuts:before {
	content: "\f396";
}
.game-icon-coffee-beans:before {
	content: "\f397";
}
.game-icon-coffee-cup:before {
	content: "\f398";
}
.game-icon-coffee-mug:before {
	content: "\f399";
}
.game-icon-coffee-pot:before {
	content: "\f39a";
}
.game-icon-coffin:before {
	content: "\f39b";
}
.game-icon-cog-lock:before {
	content: "\f39c";
}
.game-icon-cog:before {
	content: "\f39d";
}
.game-icon-cogsplosion:before {
	content: "\f39e";
}
.game-icon-coiled-nail:before {
	content: "\f39f";
}
.game-icon-coiling-curl:before {
	content: "\f3a0";
}
.game-icon-coinflip:before {
	content: "\f3a1";
}
.game-icon-coins-pile:before {
	content: "\f3a2";
}
.game-icon-coins:before {
	content: "\f3a3";
}
.game-icon-cold-heart:before {
	content: "\f3a4";
}
.game-icon-coliseum:before {
	content: "\f3a5";
}
.game-icon-colombia:before {
	content: "\f3a6";
}
.game-icon-colombian-statue:before {
	content: "\f3a7";
}
.game-icon-colt-m1911:before {
	content: "\f3a8";
}
.game-icon-column-vase:before {
	content: "\f3a9";
}
.game-icon-coma:before {
	content: "\f3aa";
}
.game-icon-comb:before {
	content: "\f3ab";
}
.game-icon-combination-lock:before {
	content: "\f3ac";
}
.game-icon-comet-spark:before {
	content: "\f3ad";
}
.game-icon-commercial-airplane:before {
	content: "\f3ae";
}
.game-icon-compact-disc:before {
	content: "\f3af";
}
.game-icon-companion-cube:before {
	content: "\f3b0";
}
.game-icon-compass:before {
	content: "\f3b1";
}
.game-icon-computer-fan:before {
	content: "\f3b2";
}
.game-icon-computing:before {
	content: "\f3b3";
}
.game-icon-concentration-orb:before {
	content: "\f3b4";
}
.game-icon-concentric-crescents:before {
	content: "\f3b5";
}
.game-icon-concrete-bag:before {
	content: "\f3b6";
}
.game-icon-condor-emblem:before {
	content: "\f3b7";
}
.game-icon-condylura-skull:before {
	content: "\f3b8";
}
.game-icon-confirmed:before {
	content: "\f3b9";
}
.game-icon-confrontation:before {
	content: "\f3ba";
}
.game-icon-congress:before {
	content: "\f3bb";
}
.game-icon-conqueror:before {
	content: "\f3bc";
}
.game-icon-console-controller:before {
	content: "\f3bd";
}
.game-icon-contortionist:before {
	content: "\f3be";
}
.game-icon-contract:before {
	content: "\f3bf";
}
.game-icon-control-tower:before {
	content: "\f3c0";
}
.game-icon-convergence-target:before {
	content: "\f3c1";
}
.game-icon-conversation:before {
	content: "\f3c2";
}
.game-icon-converse-shoe:before {
	content: "\f3c3";
}
.game-icon-convict:before {
	content: "\f3c4";
}
.game-icon-convince:before {
	content: "\f3c5";
}
.game-icon-conway-life-glider:before {
	content: "\f3c6";
}
.game-icon-cook:before {
	content: "\f3c7";
}
.game-icon-cookie:before {
	content: "\f3c8";
}
.game-icon-cooking-glove:before {
	content: "\f3c9";
}
.game-icon-cooking-pot:before {
	content: "\f3ca";
}
.game-icon-cool-spices:before {
	content: "\f3cb";
}
.game-icon-cooler:before {
	content: "\f3cc";
}
.game-icon-cootie-catcher:before {
	content: "\f3cd";
}
.game-icon-coral:before {
	content: "\f3ce";
}
.game-icon-cork-hat:before {
	content: "\f3cf";
}
.game-icon-corked-tube:before {
	content: "\f3d0";
}
.game-icon-corkscrew:before {
	content: "\f3d1";
}
.game-icon-corn:before {
	content: "\f3d2";
}
.game-icon-corner-explosion:before {
	content: "\f3d3";
}
.game-icon-corner-flag:before {
	content: "\f3d4";
}
.game-icon-cornucopia:before {
	content: "\f3d5";
}
.game-icon-coronation:before {
	content: "\f3d6";
}
.game-icon-corporal:before {
	content: "\f3d7";
}
.game-icon-corset:before {
	content: "\f3d8";
}
.game-icon-corsica:before {
	content: "\f3d9";
}
.game-icon-cosmic-egg:before {
	content: "\f3da";
}
.game-icon-cotton-flower:before {
	content: "\f3db";
}
.game-icon-covered-jar:before {
	content: "\f3dc";
}
.game-icon-cow:before {
	content: "\f3dd";
}
.game-icon-cowboy-boot:before {
	content: "\f3de";
}
.game-icon-cowboy-holster:before {
	content: "\f3df";
}
.game-icon-cowled:before {
	content: "\f3e0";
}
.game-icon-cpu-shot:before {
	content: "\f3e1";
}
.game-icon-cpu:before {
	content: "\f3e2";
}
.game-icon-crab-claw:before {
	content: "\f3e3";
}
.game-icon-crab:before {
	content: "\f3e4";
}
.game-icon-cracked-alien-skull:before {
	content: "\f3e5";
}
.game-icon-cracked-ball-dunk:before {
	content: "\f3e6";
}
.game-icon-cracked-disc:before {
	content: "\f3e7";
}
.game-icon-cracked-glass:before {
	content: "\f3e8";
}
.game-icon-cracked-helm:before {
	content: "\f3e9";
}
.game-icon-cracked-mask:before {
	content: "\f3ea";
}
.game-icon-cracked-saber:before {
	content: "\f3eb";
}
.game-icon-cracked-shield:before {
	content: "\f3ec";
}
.game-icon-crafting:before {
	content: "\f3ed";
}
.game-icon-crags:before {
	content: "\f3ee";
}
.game-icon-crane:before {
	content: "\f3ef";
}
.game-icon-credits-currency:before {
	content: "\f3f0";
}
.game-icon-crenel-crown:before {
	content: "\f3f1";
}
.game-icon-crenulated-shield:before {
	content: "\f3f2";
}
.game-icon-crescent-blade-2:before {
	content: "\f3f3";
}
.game-icon-crescent-blade:before {
	content: "\f3f4";
}
.game-icon-crescent-staff:before {
	content: "\f3f5";
}
.game-icon-crested-helmet:before {
	content: "\f3f6";
}
.game-icon-cricket-bat:before {
	content: "\f3f7";
}
.game-icon-cricket:before {
	content: "\f3f8";
}
.game-icon-crime-scene-tape:before {
	content: "\f3f9";
}
.game-icon-croc-jaws:before {
	content: "\f3fa";
}
.game-icon-croc-sword:before {
	content: "\f3fb";
}
.game-icon-croissant:before {
	content: "\f3fc";
}
.game-icon-croissants-pupil:before {
	content: "\f3fd";
}
.game-icon-crook-flail:before {
	content: "\f3fe";
}
.game-icon-cross-flare:before {
	content: "\f3ff";
}
.game-icon-cross-mark:before {
	content: "\f400";
}
.game-icon-cross-shield:before {
	content: "\f401";
}
.game-icon-crossbow:before {
	content: "\f402";
}
.game-icon-crosscut-saw:before {
	content: "\f403";
}
.game-icon-crossed-air-flows:before {
	content: "\f404";
}
.game-icon-crossed-axes:before {
	content: "\f405";
}
.game-icon-crossed-bones:before {
	content: "\f406";
}
.game-icon-crossed-chains:before {
	content: "\f407";
}
.game-icon-crossed-claws:before {
	content: "\f408";
}
.game-icon-crossed-pistols:before {
	content: "\f409";
}
.game-icon-crossed-sabres:before {
	content: "\f40a";
}
.game-icon-crossed-slashes:before {
	content: "\f40b";
}
.game-icon-crossed-swords:before {
	content: "\f40c";
}
.game-icon-crosshair-arrow:before {
	content: "\f40d";
}
.game-icon-crosshair:before {
	content: "\f40e";
}
.game-icon-crossroad:before {
	content: "\f40f";
}
.game-icon-crow-dive:before {
	content: "\f410";
}
.game-icon-crow-nest:before {
	content: "\f411";
}
.game-icon-crowbar:before {
	content: "\f412";
}
.game-icon-crown-coin:before {
	content: "\f413";
}
.game-icon-crown-of-thorns:before {
	content: "\f414";
}
.game-icon-crown:before {
	content: "\f415";
}
.game-icon-crowned-explosion:before {
	content: "\f416";
}
.game-icon-crowned-heart:before {
	content: "\f417";
}
.game-icon-crowned-skull:before {
	content: "\f418";
}
.game-icon-crucifix:before {
	content: "\f419";
}
.game-icon-cruiser:before {
	content: "\f41a";
}
.game-icon-crumbling-ball:before {
	content: "\f41b";
}
.game-icon-crush:before {
	content: "\f41c";
}
.game-icon-cryo-chamber:before {
	content: "\f41d";
}
.game-icon-crypt-entrance:before {
	content: "\f41e";
}
.game-icon-crystal-ball:before {
	content: "\f41f";
}
.game-icon-crystal-bars:before {
	content: "\f420";
}
.game-icon-crystal-cluster:before {
	content: "\f421";
}
.game-icon-crystal-earrings:before {
	content: "\f422";
}
.game-icon-crystal-eye:before {
	content: "\f423";
}
.game-icon-crystal-growth:before {
	content: "\f424";
}
.game-icon-crystal-shine:before {
	content: "\f425";
}
.game-icon-crystal-shrine:before {
	content: "\f426";
}
.game-icon-crystal-wand:before {
	content: "\f427";
}
.game-icon-crystalize:before {
	content: "\f428";
}
.game-icon-cuauhtli:before {
	content: "\f429";
}
.game-icon-cube:before {
	content: "\f42a";
}
.game-icon-cubeforce:before {
	content: "\f42b";
}
.game-icon-cubes:before {
	content: "\f42c";
}
.game-icon-cuckoo-clock:before {
	content: "\f42d";
}
.game-icon-cultist-2:before {
	content: "\f42e";
}
.game-icon-cultist:before {
	content: "\f42f";
}
.game-icon-cupcake:before {
	content: "\f430";
}
.game-icon-cupidon-arrow:before {
	content: "\f431";
}
.game-icon-curled-leaf:before {
	content: "\f432";
}
.game-icon-curled-tentacle:before {
	content: "\f433";
}
.game-icon-curling-stone:before {
	content: "\f434";
}
.game-icon-curling-vines:before {
	content: "\f435";
}
.game-icon-curly-mask:before {
	content: "\f436";
}
.game-icon-curly-wing:before {
	content: "\f437";
}
.game-icon-cursed-star:before {
	content: "\f438";
}
.game-icon-curvy-knife:before {
	content: "\f439";
}
.game-icon-custodian-helmet:before {
	content: "\f43a";
}
.game-icon-cut-diamond:before {
	content: "\f43b";
}
.game-icon-cut-lemon:before {
	content: "\f43c";
}
.game-icon-cut-palm:before {
	content: "\f43d";
}
.game-icon-cyber-eye:before {
	content: "\f43e";
}
.game-icon-cyborg-face:before {
	content: "\f43f";
}
.game-icon-cycle:before {
	content: "\f440";
}
.game-icon-cycling:before {
	content: "\f441";
}
.game-icon-cyclops:before {
	content: "\f442";
}
.game-icon-cz-skorpion:before {
	content: "\f443";
}
.game-icon-d10:before {
	content: "\f444";
}
.game-icon-d12:before {
	content: "\f445";
}
.game-icon-d4:before {
	content: "\f446";
}
.game-icon-daemon-pull:before {
	content: "\f447";
}
.game-icon-daemon-skull:before {
	content: "\f448";
}
.game-icon-dagger-rose:before {
	content: "\f449";
}
.game-icon-daggers:before {
	content: "\f44a";
}
.game-icon-daisy:before {
	content: "\f44b";
}
.game-icon-dam:before {
	content: "\f44c";
}
.game-icon-damaged-house:before {
	content: "\f44d";
}
.game-icon-dandelion-flower:before {
	content: "\f44e";
}
.game-icon-dango:before {
	content: "\f44f";
}
.game-icon-dark-squad:before {
	content: "\f450";
}
.game-icon-dart:before {
	content: "\f451";
}
.game-icon-database:before {
	content: "\f452";
}
.game-icon-dead-eye:before {
	content: "\f453";
}
.game-icon-dead-head:before {
	content: "\f454";
}
.game-icon-dead-wood:before {
	content: "\f455";
}
.game-icon-deadly-strike:before {
	content: "\f456";
}
.game-icon-death-juice:before {
	content: "\f457";
}
.game-icon-death-note:before {
	content: "\f458";
}
.game-icon-death-skull:before {
	content: "\f459";
}
.game-icon-death-star:before {
	content: "\f45a";
}
.game-icon-death-zone:before {
	content: "\f45b";
}
.game-icon-deathcab:before {
	content: "\f45c";
}
.game-icon-decapitation:before {
	content: "\f45d";
}
.game-icon-deer-head:before {
	content: "\f45e";
}
.game-icon-deer-track:before {
	content: "\f45f";
}
.game-icon-deer:before {
	content: "\f460";
}
.game-icon-defense-satellite:before {
	content: "\f461";
}
.game-icon-defensive-wall:before {
	content: "\f462";
}
.game-icon-defibrilate:before {
	content: "\f463";
}
.game-icon-deku-tree:before {
	content: "\f464";
}
.game-icon-delicate-perfume:before {
	content: "\f465";
}
.game-icon-delighted:before {
	content: "\f466";
}
.game-icon-delivery-drone:before {
	content: "\f467";
}
.game-icon-demolish:before {
	content: "\f468";
}
.game-icon-dervish-swords:before {
	content: "\f469";
}
.game-icon-desert-camp:before {
	content: "\f46a";
}
.game-icon-desert-eagle:before {
	content: "\f46b";
}
.game-icon-desert-skull:before {
	content: "\f46c";
}
.game-icon-desert:before {
	content: "\f46d";
}
.game-icon-deshret-red-crown:before {
	content: "\f46e";
}
.game-icon-desk-lamp:before {
	content: "\f46f";
}
.game-icon-desk:before {
	content: "\f470";
}
.game-icon-despair:before {
	content: "\f471";
}
.game-icon-detonator:before {
	content: "\f472";
}
.game-icon-detour:before {
	content: "\f473";
}
.game-icon-devil-mask:before {
	content: "\f474";
}
.game-icon-dew:before {
	content: "\f475";
}
.game-icon-diablo-skull:before {
	content: "\f476";
}
.game-icon-diagram:before {
	content: "\f477";
}
.game-icon-dial-padlock:before {
	content: "\f478";
}
.game-icon-diamond-hard:before {
	content: "\f479";
}
.game-icon-diamond-hilt:before {
	content: "\f47a";
}
.game-icon-diamond-ring:before {
	content: "\f47b";
}
.game-icon-diamond-trophy:before {
	content: "\f47c";
}
.game-icon-diamonds-smile:before {
	content: "\f47d";
}
.game-icon-diamonds:before {
	content: "\f47e";
}
.game-icon-dice-eight-faces-eight:before {
	content: "\f47f";
}
.game-icon-dice-fire:before {
	content: "\f480";
}
.game-icon-dice-shield:before {
	content: "\f481";
}
.game-icon-dice-six-faces-five:before {
	content: "\f482";
}
.game-icon-dice-six-faces-four:before {
	content: "\f483";
}
.game-icon-dice-six-faces-one:before {
	content: "\f484";
}
.game-icon-dice-six-faces-six:before {
	content: "\f485";
}
.game-icon-dice-six-faces-three:before {
	content: "\f486";
}
.game-icon-dice-six-faces-two:before {
	content: "\f487";
}
.game-icon-dice-target:before {
	content: "\f488";
}
.game-icon-dice-twenty-faces-one:before {
	content: "\f489";
}
.game-icon-dice-twenty-faces-twenty:before {
	content: "\f48a";
}
.game-icon-dig-dug:before {
	content: "\f48b";
}
.game-icon-dig-hole:before {
	content: "\f48c";
}
.game-icon-digital-trace:before {
	content: "\f48d";
}
.game-icon-dimetrodon:before {
	content: "\f48e";
}
.game-icon-dinosaur-bones:before {
	content: "\f48f";
}
.game-icon-dinosaur-egg:before {
	content: "\f490";
}
.game-icon-dinosaur-rex:before {
	content: "\f491";
}
.game-icon-diplodocus:before {
	content: "\f492";
}
.game-icon-diploma:before {
	content: "\f493";
}
.game-icon-direction-sign:before {
	content: "\f494";
}
.game-icon-direction-signs:before {
	content: "\f495";
}
.game-icon-director-chair:before {
	content: "\f496";
}
.game-icon-direwolf:before {
	content: "\f497";
}
.game-icon-disc-golf-bag:before {
	content: "\f498";
}
.game-icon-disc-golf-basket:before {
	content: "\f499";
}
.game-icon-disc:before {
	content: "\f49a";
}
.game-icon-discobolus:before {
	content: "\f49b";
}
.game-icon-discussion:before {
	content: "\f49c";
}
.game-icon-disintegrate:before {
	content: "\f49d";
}
.game-icon-distraction-2:before {
	content: "\f49e";
}
.game-icon-distraction:before {
	content: "\f49f";
}
.game-icon-distress-signal:before {
	content: "\f4a0";
}
.game-icon-divergence:before {
	content: "\f4a1";
}
.game-icon-divert:before {
	content: "\f4a2";
}
.game-icon-divided-spiral:before {
	content: "\f4a3";
}
.game-icon-divided-square:before {
	content: "\f4a4";
}
.game-icon-diving-dagger:before {
	content: "\f4a5";
}
.game-icon-diving-helmet:before {
	content: "\f4a6";
}
.game-icon-djed-pillar:before {
	content: "\f4a7";
}
.game-icon-djembe:before {
	content: "\f4a8";
}
.game-icon-djinn:before {
	content: "\f4a9";
}
.game-icon-dna1:before {
	content: "\f4aa";
}
.game-icon-dna2:before {
	content: "\f4ab";
}
.game-icon-doctor-face:before {
	content: "\f4ac";
}
.game-icon-dodge:before {
	content: "\f4ad";
}
.game-icon-dodging:before {
	content: "\f4ae";
}
.game-icon-dog-bowl:before {
	content: "\f4af";
}
.game-icon-dog-house:before {
	content: "\f4b0";
}
.game-icon-dolmen:before {
	content: "\f4b1";
}
.game-icon-dolphin:before {
	content: "\f4b2";
}
.game-icon-domino-mask:before {
	content: "\f4b3";
}
.game-icon-domino-tiles:before {
	content: "\f4b4";
}
.game-icon-doner-kebab:before {
	content: "\f4b5";
}
.game-icon-donkey:before {
	content: "\f4b6";
}
.game-icon-donut:before {
	content: "\f4b7";
}
.game-icon-door-handle:before {
	content: "\f4b8";
}
.game-icon-door-ring-handle:before {
	content: "\f4b9";
}
.game-icon-door-watcher:before {
	content: "\f4ba";
}
.game-icon-door:before {
	content: "\f4bb";
}
.game-icon-doorway:before {
	content: "\f4bc";
}
.game-icon-dorsal-scales:before {
	content: "\f4bd";
}
.game-icon-double-diaphragm:before {
	content: "\f4be";
}
.game-icon-double-dragon:before {
	content: "\f4bf";
}
.game-icon-double-face-mask:before {
	content: "\f4c0";
}
.game-icon-double-fish:before {
	content: "\f4c1";
}
.game-icon-double-necklace:before {
	content: "\f4c2";
}
.game-icon-double-quaver:before {
	content: "\f4c3";
}
.game-icon-double-ringed-orb:before {
	content: "\f4c4";
}
.game-icon-double-shot:before {
	content: "\f4c5";
}
.game-icon-double-street-lights:before {
	content: "\f4c6";
}
.game-icon-doubled:before {
	content: "\f4c7";
}
.game-icon-dough-roller:before {
	content: "\f4c8";
}
.game-icon-dove:before {
	content: "\f4c9";
}
.game-icon-dozen:before {
	content: "\f4ca";
}
.game-icon-dragon-balls:before {
	content: "\f4cb";
}
.game-icon-dragon-breath:before {
	content: "\f4cc";
}
.game-icon-dragon-head-2:before {
	content: "\f4cd";
}
.game-icon-dragon-head:before {
	content: "\f4ce";
}
.game-icon-dragon-orb:before {
	content: "\f4cf";
}
.game-icon-dragon-shield:before {
	content: "\f4d0";
}
.game-icon-dragon-spiral:before {
	content: "\f4d1";
}
.game-icon-dragonfly:before {
	content: "\f4d2";
}
.game-icon-drakkar-dragon:before {
	content: "\f4d3";
}
.game-icon-drakkar:before {
	content: "\f4d4";
}
.game-icon-drama-masks:before {
	content: "\f4d5";
}
.game-icon-drawbridge:before {
	content: "\f4d6";
}
.game-icon-dread-skull:before {
	content: "\f4d7";
}
.game-icon-dread:before {
	content: "\f4d8";
}
.game-icon-dreadnought:before {
	content: "\f4d9";
}
.game-icon-dream-catcher:before {
	content: "\f4da";
}
.game-icon-dress:before {
	content: "\f4db";
}
.game-icon-drill-2:before {
	content: "\f4dc";
}
.game-icon-drill:before {
	content: "\f4dd";
}
.game-icon-drink-me:before {
	content: "\f4de";
}
.game-icon-drinking:before {
	content: "\f4df";
}
.game-icon-dripping-blade:before {
	content: "\f4e0";
}
.game-icon-dripping-goo:before {
	content: "\f4e1";
}
.game-icon-dripping-honey:before {
	content: "\f4e2";
}
.game-icon-dripping-knife:before {
	content: "\f4e3";
}
.game-icon-dripping-star:before {
	content: "\f4e4";
}
.game-icon-dripping-stone:before {
	content: "\f4e5";
}
.game-icon-dripping-sword:before {
	content: "\f4e6";
}
.game-icon-dripping-tube:before {
	content: "\f4e7";
}
.game-icon-drop-earrings:before {
	content: "\f4e8";
}
.game-icon-drop-weapon:before {
	content: "\f4e9";
}
.game-icon-drop:before {
	content: "\f4ea";
}
.game-icon-droplet-splash:before {
	content: "\f4eb";
}
.game-icon-droplets:before {
	content: "\f4ec";
}
.game-icon-drowning:before {
	content: "\f4ed";
}
.game-icon-drum-kit:before {
	content: "\f4ee";
}
.game-icon-drum:before {
	content: "\f4ef";
}
.game-icon-duality-mask:before {
	content: "\f4f0";
}
.game-icon-duality:before {
	content: "\f4f1";
}
.game-icon-duck-palm:before {
	content: "\f4f2";
}
.game-icon-duck:before {
	content: "\f4f3";
}
.game-icon-duel:before {
	content: "\f4f4";
}
.game-icon-duffel-bag:before {
	content: "\f4f5";
}
.game-icon-dumpling-bao:before {
	content: "\f4f6";
}
.game-icon-dumpling:before {
	content: "\f4f7";
}
.game-icon-dunce-cap:before {
	content: "\f4f8";
}
.game-icon-dungeon-gate:before {
	content: "\f4f9";
}
.game-icon-dungeon-light:before {
	content: "\f4fa";
}
.game-icon-duration:before {
	content: "\f4fb";
}
.game-icon-dust-cloud:before {
	content: "\f4fc";
}
.game-icon-dutch-bike:before {
	content: "\f4fd";
}
.game-icon-dwarf-face:before {
	content: "\f4fe";
}
.game-icon-dwarf-helmet:before {
	content: "\f4ff";
}
.game-icon-dwarf-king:before {
	content: "\f500";
}
.game-icon-dwennimmen:before {
	content: "\f501";
}
.game-icon-dynamite:before {
	content: "\f502";
}
.game-icon-eagle-emblem:before {
	content: "\f503";
}
.game-icon-eagle-head:before {
	content: "\f504";
}
.game-icon-earbuds:before {
	content: "\f505";
}
.game-icon-earrings:before {
	content: "\f506";
}
.game-icon-earth-africa-europe:before {
	content: "\f507";
}
.game-icon-earth-america:before {
	content: "\f508";
}
.game-icon-earth-asia-oceania:before {
	content: "\f509";
}
.game-icon-earth-crack:before {
	content: "\f50a";
}
.game-icon-earth-spit:before {
	content: "\f50b";
}
.game-icon-earth-worm:before {
	content: "\f50c";
}
.game-icon-earwig:before {
	content: "\f50d";
}
.game-icon-easel:before {
	content: "\f50e";
}
.game-icon-easter-egg:before {
	content: "\f50f";
}
.game-icon-eating-pelican:before {
	content: "\f510";
}
.game-icon-eating:before {
	content: "\f511";
}
.game-icon-echo-ripples:before {
	content: "\f512";
}
.game-icon-eclipse-flare:before {
	content: "\f513";
}
.game-icon-eclipse-saw:before {
	content: "\f514";
}
.game-icon-eclipse:before {
	content: "\f515";
}
.game-icon-ecology:before {
	content: "\f516";
}
.game-icon-edge-crack:before {
	content: "\f517";
}
.game-icon-edged-shield:before {
	content: "\f518";
}
.game-icon-eel:before {
	content: "\f519";
}
.game-icon-egg-clutch:before {
	content: "\f51a";
}
.game-icon-egg-defense:before {
	content: "\f51b";
}
.game-icon-egg-eye:before {
	content: "\f51c";
}
.game-icon-egg-pod:before {
	content: "\f51d";
}
.game-icon-egypt:before {
	content: "\f51e";
}
.game-icon-egyptian-bird:before {
	content: "\f51f";
}
.game-icon-egyptian-profile:before {
	content: "\f520";
}
.game-icon-egyptian-pyramids:before {
	content: "\f521";
}
.game-icon-egyptian-sphinx:before {
	content: "\f522";
}
.game-icon-egyptian-temple:before {
	content: "\f523";
}
.game-icon-egyptian-urns:before {
	content: "\f524";
}
.game-icon-egyptian-walk:before {
	content: "\f525";
}
.game-icon-eight-ball:before {
	content: "\f526";
}
.game-icon-elbow-pad:before {
	content: "\f527";
}
.game-icon-elderberry:before {
	content: "\f528";
}
.game-icon-electric-whip:before {
	content: "\f529";
}
.game-icon-electric:before {
	content: "\f52a";
}
.game-icon-electrical-crescent:before {
	content: "\f52b";
}
.game-icon-electrical-resistance:before {
	content: "\f52c";
}
.game-icon-electrical-socket:before {
	content: "\f52d";
}
.game-icon-elephant-head:before {
	content: "\f52e";
}
.game-icon-elephant:before {
	content: "\f52f";
}
.game-icon-elevator:before {
	content: "\f530";
}
.game-icon-elf-ear:before {
	content: "\f531";
}
.game-icon-elf-helmet:before {
	content: "\f532";
}
.game-icon-elven-castle:before {
	content: "\f533";
}
.game-icon-elysium-shade:before {
	content: "\f534";
}
.game-icon-ember-shot:before {
	content: "\f535";
}
.game-icon-embrassed-energy:before {
	content: "\f536";
}
.game-icon-embryo:before {
	content: "\f537";
}
.game-icon-emerald-necklace:before {
	content: "\f538";
}
.game-icon-emerald:before {
	content: "\f539";
}
.game-icon-empty-chessboard:before {
	content: "\f53a";
}
.game-icon-empty-hourglass:before {
	content: "\f53b";
}
.game-icon-empty-metal-bucket-handle:before {
	content: "\f53c";
}
.game-icon-empty-metal-bucket:before {
	content: "\f53d";
}
.game-icon-empty-wood-bucket-handle:before {
	content: "\f53e";
}
.game-icon-empty-wood-bucket:before {
	content: "\f53f";
}
.game-icon-encirclement:before {
	content: "\f540";
}
.game-icon-energise:before {
	content: "\f541";
}
.game-icon-energy-arrow:before {
	content: "\f542";
}
.game-icon-energy-breath:before {
	content: "\f543";
}
.game-icon-energy-shield:before {
	content: "\f544";
}
.game-icon-energy-sword:before {
	content: "\f545";
}
.game-icon-energy-tank:before {
	content: "\f546";
}
.game-icon-engagement-ring:before {
	content: "\f547";
}
.game-icon-enlightenment:before {
	content: "\f548";
}
.game-icon-enrage:before {
	content: "\f549";
}
.game-icon-ent-mouth:before {
	content: "\f54a";
}
.game-icon-entangled-typhoon:before {
	content: "\f54b";
}
.game-icon-entry-door:before {
	content: "\f54c";
}
.game-icon-envelope:before {
	content: "\f54d";
}
.game-icon-erlenmeyer:before {
	content: "\f54e";
}
.game-icon-ermine:before {
	content: "\f54f";
}
.game-icon-eruption:before {
	content: "\f550";
}
.game-icon-escalator:before {
	content: "\f551";
}
.game-icon-eskimo:before {
	content: "\f552";
}
.game-icon-eternal-love:before {
	content: "\f553";
}
.game-icon-european-flag:before {
	content: "\f554";
}
.game-icon-evasion:before {
	content: "\f555";
}
.game-icon-evil-bat:before {
	content: "\f556";
}
.game-icon-evil-book:before {
	content: "\f557";
}
.game-icon-evil-bud:before {
	content: "\f558";
}
.game-icon-evil-comet:before {
	content: "\f559";
}
.game-icon-evil-eyes:before {
	content: "\f55a";
}
.game-icon-evil-fork:before {
	content: "\f55b";
}
.game-icon-evil-hand:before {
	content: "\f55c";
}
.game-icon-evil-love:before {
	content: "\f55d";
}
.game-icon-evil-minion:before {
	content: "\f55e";
}
.game-icon-evil-moon:before {
	content: "\f55f";
}
.game-icon-evil-tower:before {
	content: "\f560";
}
.game-icon-evil-tree:before {
	content: "\f561";
}
.game-icon-evil-wings:before {
	content: "\f562";
}
.game-icon-executioner-hood:before {
	content: "\f563";
}
.game-icon-exit-door:before {
	content: "\f564";
}
.game-icon-expand:before {
	content: "\f565";
}
.game-icon-expanded-rays:before {
	content: "\f566";
}
.game-icon-expander:before {
	content: "\f567";
}
.game-icon-expense:before {
	content: "\f568";
}
.game-icon-exploding-planet:before {
	content: "\f569";
}
.game-icon-explosion-rays:before {
	content: "\f56a";
}
.game-icon-explosive-materials:before {
	content: "\f56b";
}
.game-icon-explosive-meeting:before {
	content: "\f56c";
}
.game-icon-extra-lucid:before {
	content: "\f56d";
}
.game-icon-extra-time:before {
	content: "\f56e";
}
.game-icon-extraction-orb:before {
	content: "\f56f";
}
.game-icon-eye-of-horus:before {
	content: "\f570";
}
.game-icon-eye-shield:before {
	content: "\f571";
}
.game-icon-eye-target:before {
	content: "\f572";
}
.game-icon-eyeball:before {
	content: "\f573";
}
.game-icon-eyedropper:before {
	content: "\f574";
}
.game-icon-eyelashes:before {
	content: "\f575";
}
.game-icon-eyepatch:before {
	content: "\f576";
}
.game-icon-eyestalk:before {
	content: "\f577";
}
.game-icon-f-clef:before {
	content: "\f578";
}
.game-icon-f1-car:before {
	content: "\f579";
}
.game-icon-face-to-face:before {
	content: "\f57a";
}
.game-icon-factory-arm:before {
	content: "\f57b";
}
.game-icon-factory:before {
	content: "\f57c";
}
.game-icon-fairy-2:before {
	content: "\f57d";
}
.game-icon-fairy-wand:before {
	content: "\f57e";
}
.game-icon-fairy-wings:before {
	content: "\f57f";
}
.game-icon-fairy:before {
	content: "\f580";
}
.game-icon-falcon-moon:before {
	content: "\f581";
}
.game-icon-fall-down:before {
	content: "\f582";
}
.game-icon-falling-blob:before {
	content: "\f583";
}
.game-icon-falling-bomb:before {
	content: "\f584";
}
.game-icon-falling-boulder:before {
	content: "\f585";
}
.game-icon-falling-eye:before {
	content: "\f586";
}
.game-icon-falling-leaf:before {
	content: "\f587";
}
.game-icon-falling-ovoid:before {
	content: "\f588";
}
.game-icon-falling-rocks:before {
	content: "\f589";
}
.game-icon-falling-star:before {
	content: "\f58a";
}
.game-icon-falling:before {
	content: "\f58b";
}
.game-icon-fallout-shelter:before {
	content: "\f58c";
}
.game-icon-famas:before {
	content: "\f58d";
}
.game-icon-family-house:before {
	content: "\f58e";
}
.game-icon-family-tree:before {
	content: "\f58f";
}
.game-icon-fanged-skull:before {
	content: "\f590";
}
.game-icon-fangs-circle:before {
	content: "\f591";
}
.game-icon-fangs:before {
	content: "\f592";
}
.game-icon-farm-tractor:before {
	content: "\f593";
}
.game-icon-farmer:before {
	content: "\f594";
}
.game-icon-fast-arrow:before {
	content: "\f595";
}
.game-icon-fast-backward-button:before {
	content: "\f596";
}
.game-icon-fast-forward-button:before {
	content: "\f597";
}
.game-icon-fast-noodles:before {
	content: "\f598";
}
.game-icon-fat:before {
	content: "\f599";
}
.game-icon-feather-necklace:before {
	content: "\f59a";
}
.game-icon-feather-wound:before {
	content: "\f59b";
}
.game-icon-feather:before {
	content: "\f59c";
}
.game-icon-feathered-wing:before {
	content: "\f59d";
}
.game-icon-fedora:before {
	content: "\f59e";
}
.game-icon-feline:before {
	content: "\f59f";
}
.game-icon-female-legs:before {
	content: "\f5a0";
}
.game-icon-female-vampire:before {
	content: "\f5a1";
}
.game-icon-female:before {
	content: "\f5a2";
}
.game-icon-fencer:before {
	content: "\f5a3";
}
.game-icon-fern:before {
	content: "\f5a4";
}
.game-icon-fertilizer-bag:before {
	content: "\f5a5";
}
.game-icon-fetus:before {
	content: "\f5a6";
}
.game-icon-fez:before {
	content: "\f5a7";
}
.game-icon-field-gun:before {
	content: "\f5a8";
}
.game-icon-field:before {
	content: "\f5a9";
}
.game-icon-figurehead:before {
	content: "\f5aa";
}
.game-icon-files:before {
	content: "\f5ab";
}
.game-icon-film-projector:before {
	content: "\f5ac";
}
.game-icon-film-spool:before {
	content: "\f5ad";
}
.game-icon-film-strip:before {
	content: "\f5ae";
}
.game-icon-finch:before {
	content: "\f5af";
}
.game-icon-finger-print:before {
	content: "\f5b0";
}
.game-icon-fingernail:before {
	content: "\f5b1";
}
.game-icon-fingers-crossed:before {
	content: "\f5b2";
}
.game-icon-finish-line:before {
	content: "\f5b3";
}
.game-icon-fire-ace:before {
	content: "\f5b4";
}
.game-icon-fire-axe:before {
	content: "\f5b5";
}
.game-icon-fire-bomb:before {
	content: "\f5b6";
}
.game-icon-fire-bottle:before {
	content: "\f5b7";
}
.game-icon-fire-bowl:before {
	content: "\f5b8";
}
.game-icon-fire-breath:before {
	content: "\f5b9";
}
.game-icon-fire-dash:before {
	content: "\f5ba";
}
.game-icon-fire-extinguisher:before {
	content: "\f5bb";
}
.game-icon-fire-flower:before {
	content: "\f5bc";
}
.game-icon-fire-gem:before {
	content: "\f5bd";
}
.game-icon-fire-punch:before {
	content: "\f5be";
}
.game-icon-fire-ray:before {
	content: "\f5bf";
}
.game-icon-fire-ring:before {
	content: "\f5c0";
}
.game-icon-fire-shield:before {
	content: "\f5c1";
}
.game-icon-fire-shrine:before {
	content: "\f5c2";
}
.game-icon-fire-silhouette:before {
	content: "\f5c3";
}
.game-icon-fire-spell-cast:before {
	content: "\f5c4";
}
.game-icon-fire-tail:before {
	content: "\f5c5";
}
.game-icon-fire-wave:before {
	content: "\f5c6";
}
.game-icon-fire-zone:before {
	content: "\f5c7";
}
.game-icon-fire:before {
	content: "\f5c8";
}
.game-icon-fireball:before {
	content: "\f5c9";
}
.game-icon-fireflake:before {
	content: "\f5ca";
}
.game-icon-fireplace:before {
	content: "\f5cb";
}
.game-icon-firewall:before {
	content: "\f5cc";
}
.game-icon-firework-rocket:before {
	content: "\f5cd";
}
.game-icon-first-aid-kit:before {
	content: "\f5ce";
}
.game-icon-fish-bucket:before {
	content: "\f5cf";
}
.game-icon-fish-cooked:before {
	content: "\f5d0";
}
.game-icon-fish-corpse:before {
	content: "\f5d1";
}
.game-icon-fish-eggs:before {
	content: "\f5d2";
}
.game-icon-fish-escape:before {
	content: "\f5d3";
}
.game-icon-fish-monster:before {
	content: "\f5d4";
}
.game-icon-fish-scales:before {
	content: "\f5d5";
}
.game-icon-fish-smoking:before {
	content: "\f5d6";
}
.game-icon-fishbone:before {
	content: "\f5d7";
}
.game-icon-fishhook-fork:before {
	content: "\f5d8";
}
.game-icon-fishing-boat:before {
	content: "\f5d9";
}
.game-icon-fishing-hook:before {
	content: "\f5da";
}
.game-icon-fishing-jig:before {
	content: "\f5db";
}
.game-icon-fishing-lure:before {
	content: "\f5dc";
}
.game-icon-fishing-net:before {
	content: "\f5dd";
}
.game-icon-fishing-pole:before {
	content: "\f5de";
}
.game-icon-fishing-spoon:before {
	content: "\f5df";
}
.game-icon-fishing:before {
	content: "\f5e0";
}
.game-icon-fission:before {
	content: "\f5e1";
}
.game-icon-fist-2:before {
	content: "\f5e2";
}
.game-icon-fist:before {
	content: "\f5e3";
}
.game-icon-fizzing-flask:before {
	content: "\f5e4";
}
.game-icon-flag-objective:before {
	content: "\f5e5";
}
.game-icon-flail:before {
	content: "\f5e6";
}
.game-icon-flake:before {
	content: "\f5e7";
}
.game-icon-flame-claws:before {
	content: "\f5e8";
}
.game-icon-flame-spin:before {
	content: "\f5e9";
}
.game-icon-flame-tunnel:before {
	content: "\f5ea";
}
.game-icon-flame:before {
	content: "\f5eb";
}
.game-icon-flamed-leaf:before {
	content: "\f5ec";
}
.game-icon-flamer:before {
	content: "\f5ed";
}
.game-icon-flamethrower-soldier:before {
	content: "\f5ee";
}
.game-icon-flamethrower:before {
	content: "\f5ef";
}
.game-icon-flaming-arrow:before {
	content: "\f5f0";
}
.game-icon-flaming-claw:before {
	content: "\f5f1";
}
.game-icon-flaming-sheet:before {
	content: "\f5f2";
}
.game-icon-flaming-trident:before {
	content: "\f5f3";
}
.game-icon-flamingo:before {
	content: "\f5f4";
}
.game-icon-flanged-mace:before {
	content: "\f5f5";
}
.game-icon-flash-grenade:before {
	content: "\f5f6";
}
.game-icon-flashlight:before {
	content: "\f5f7";
}
.game-icon-flat-hammer:before {
	content: "\f5f8";
}
.game-icon-flat-paw-print:before {
	content: "\f5f9";
}
.game-icon-flat-platform:before {
	content: "\f5fa";
}
.game-icon-flat-star:before {
	content: "\f5fb";
}
.game-icon-flat-tire:before {
	content: "\f5fc";
}
.game-icon-flatbed-covered:before {
	content: "\f5fd";
}
.game-icon-flatbed:before {
	content: "\f5fe";
}
.game-icon-flatfish:before {
	content: "\f5ff";
}
.game-icon-flax:before {
	content: "\f600";
}
.game-icon-fleshy-mass:before {
	content: "\f601";
}
.game-icon-fleur-de-lys:before {
	content: "\f602";
}
.game-icon-flexible-lamp:before {
	content: "\f603";
}
.game-icon-flexible-star:before {
	content: "\f604";
}
.game-icon-flint-spark:before {
	content: "\f605";
}
.game-icon-flip-flops:before {
	content: "\f606";
}
.game-icon-floating-crystal:before {
	content: "\f607";
}
.game-icon-floating-ghost:before {
	content: "\f608";
}
.game-icon-floating-platforms:before {
	content: "\f609";
}
.game-icon-floating-tentacles:before {
	content: "\f60a";
}
.game-icon-flood:before {
	content: "\f60b";
}
.game-icon-floor-hatch:before {
	content: "\f60c";
}
.game-icon-floor-polisher:before {
	content: "\f60d";
}
.game-icon-flour:before {
	content: "\f60e";
}
.game-icon-flower-emblem:before {
	content: "\f60f";
}
.game-icon-flower-hat:before {
	content: "\f610";
}
.game-icon-flower-pot:before {
	content: "\f611";
}
.game-icon-flower-star:before {
	content: "\f612";
}
.game-icon-flower-twirl:before {
	content: "\f613";
}
.game-icon-flowers:before {
	content: "\f614";
}
.game-icon-fluffy-cloud:before {
	content: "\f615";
}
.game-icon-fluffy-flame:before {
	content: "\f616";
}
.game-icon-fluffy-swirl:before {
	content: "\f617";
}
.game-icon-fluffy-trefoil:before {
	content: "\f618";
}
.game-icon-fluffy-wing:before {
	content: "\f619";
}
.game-icon-flute:before {
	content: "\f61a";
}
.game-icon-fly:before {
	content: "\f61b";
}
.game-icon-flying-beetle:before {
	content: "\f61c";
}
.game-icon-flying-dagger:before {
	content: "\f61d";
}
.game-icon-flying-flag:before {
	content: "\f61e";
}
.game-icon-flying-fox:before {
	content: "\f61f";
}
.game-icon-flying-shuriken:before {
	content: "\f620";
}
.game-icon-flying-target:before {
	content: "\f621";
}
.game-icon-flying-trout:before {
	content: "\f622";
}
.game-icon-fn-fal:before {
	content: "\f623";
}
.game-icon-foam:before {
	content: "\f624";
}
.game-icon-foamy-disc:before {
	content: "\f625";
}
.game-icon-focused-lightning:before {
	content: "\f626";
}
.game-icon-fog-light:before {
	content: "\f627";
}
.game-icon-fog:before {
	content: "\f628";
}
.game-icon-folded-paper:before {
	content: "\f629";
}
.game-icon-fomorian:before {
	content: "\f62a";
}
.game-icon-food-chain:before {
	content: "\f62b";
}
.game-icon-food-truck:before {
	content: "\f62c";
}
.game-icon-foot-plaster:before {
	content: "\f62d";
}
.game-icon-foot-trip:before {
	content: "\f62e";
}
.game-icon-footprint:before {
	content: "\f62f";
}
.game-icon-footsteps:before {
	content: "\f630";
}
.game-icon-footy-field:before {
	content: "\f631";
}
.game-icon-forearm:before {
	content: "\f632";
}
.game-icon-forest-camp:before {
	content: "\f633";
}
.game-icon-forest-entrance:before {
	content: "\f634";
}
.game-icon-forest:before {
	content: "\f635";
}
.game-icon-fork-knife-spoon:before {
	content: "\f636";
}
.game-icon-forklift:before {
	content: "\f637";
}
.game-icon-forward-field:before {
	content: "\f638";
}
.game-icon-forward-sun:before {
	content: "\f639";
}
.game-icon-fossil:before {
	content: "\f63a";
}
.game-icon-foundry-bucket:before {
	content: "\f63b";
}
.game-icon-fountain-pen:before {
	content: "\f63c";
}
.game-icon-fountain:before {
	content: "\f63d";
}
.game-icon-fox-head:before {
	content: "\f63e";
}
.game-icon-fox-tail:before {
	content: "\f63f";
}
.game-icon-fox:before {
	content: "\f640";
}
.game-icon-fragmented-meteor:before {
	content: "\f641";
}
.game-icon-fragmented-sword:before {
	content: "\f642";
}
.game-icon-fragrance:before {
	content: "\f643";
}
.game-icon-france:before {
	content: "\f644";
}
.game-icon-frankenstein-creature:before {
	content: "\f645";
}
.game-icon-frayed-arrow:before {
	content: "\f646";
}
.game-icon-freedom-dove:before {
	content: "\f647";
}
.game-icon-freemasonry:before {
	content: "\f648";
}
.game-icon-french-fries:before {
	content: "\f649";
}
.game-icon-french-horn:before {
	content: "\f64a";
}
.game-icon-fridge:before {
	content: "\f64b";
}
.game-icon-fried-eggs:before {
	content: "\f64c";
}
.game-icon-fried-fish:before {
	content: "\f64d";
}
.game-icon-frisbee:before {
	content: "\f64e";
}
.game-icon-froe-and-mallet:before {
	content: "\f64f";
}
.game-icon-frog-foot:before {
	content: "\f650";
}
.game-icon-frog-prince:before {
	content: "\f651";
}
.game-icon-frog:before {
	content: "\f652";
}
.game-icon-front-teeth:before {
	content: "\f653";
}
.game-icon-frontal-lobe:before {
	content: "\f654";
}
.game-icon-frostfire:before {
	content: "\f655";
}
.game-icon-frozen-arrow:before {
	content: "\f656";
}
.game-icon-frozen-block:before {
	content: "\f657";
}
.game-icon-frozen-body:before {
	content: "\f658";
}
.game-icon-frozen-orb:before {
	content: "\f659";
}
.game-icon-frozen-ring:before {
	content: "\f65a";
}
.game-icon-fruit-bowl:before {
	content: "\f65b";
}
.game-icon-fruit-tree:before {
	content: "\f65c";
}
.game-icon-fruiting:before {
	content: "\f65d";
}
.game-icon-fuel-tank:before {
	content: "\f65e";
}
.game-icon-fuji:before {
	content: "\f65f";
}
.game-icon-fulguro-punch:before {
	content: "\f660";
}
.game-icon-full-folder:before {
	content: "\f661";
}
.game-icon-full-metal-bucket-handle:before {
	content: "\f662";
}
.game-icon-full-metal-bucket:before {
	content: "\f663";
}
.game-icon-full-motorcycle-helmet:before {
	content: "\f664";
}
.game-icon-full-pizza:before {
	content: "\f665";
}
.game-icon-full-wood-bucket-handle:before {
	content: "\f666";
}
.game-icon-full-wood-bucket:before {
	content: "\f667";
}
.game-icon-funnel:before {
	content: "\f668";
}
.game-icon-fur-boot:before {
	content: "\f669";
}
.game-icon-fur-shirt:before {
	content: "\f66a";
}
.game-icon-furnace:before {
	content: "\f66b";
}
.game-icon-g-clef:before {
	content: "\f66c";
}
.game-icon-galaxy:before {
	content: "\f66d";
}
.game-icon-galea:before {
	content: "\f66e";
}
.game-icon-galleon:before {
	content: "\f66f";
}
.game-icon-galley:before {
	content: "\f670";
}
.game-icon-game-console:before {
	content: "\f671";
}
.game-icon-gamepad-cross:before {
	content: "\f672";
}
.game-icon-gamepad:before {
	content: "\f673";
}
.game-icon-gardening-shears:before {
	content: "\f674";
}
.game-icon-gargoyle:before {
	content: "\f675";
}
.game-icon-garlic:before {
	content: "\f676";
}
.game-icon-gas-mask-2:before {
	content: "\f677";
}
.game-icon-gas-mask:before {
	content: "\f678";
}
.game-icon-gas-pump:before {
	content: "\f679";
}
.game-icon-gas-stove:before {
	content: "\f67a";
}
.game-icon-gate:before {
	content: "\f67b";
}
.game-icon-gauls-helm:before {
	content: "\f67c";
}
.game-icon-gauntlet:before {
	content: "\f67d";
}
.game-icon-gavel:before {
	content: "\f67e";
}
.game-icon-gaze:before {
	content: "\f67f";
}
.game-icon-gear-hammer:before {
	content: "\f680";
}
.game-icon-gear-stick-pattern:before {
	content: "\f681";
}
.game-icon-gear-stick:before {
	content: "\f682";
}
.game-icon-gears:before {
	content: "\f683";
}
.game-icon-gecko:before {
	content: "\f684";
}
.game-icon-gem-chain:before {
	content: "\f685";
}
.game-icon-gem-necklace:before {
	content: "\f686";
}
.game-icon-gem-pendant:before {
	content: "\f687";
}
.game-icon-gemini:before {
	content: "\f688";
}
.game-icon-gems:before {
	content: "\f689";
}
.game-icon-ghost-ally:before {
	content: "\f68a";
}
.game-icon-ghost:before {
	content: "\f68b";
}
.game-icon-giant-squid:before {
	content: "\f68c";
}
.game-icon-giant:before {
	content: "\f68d";
}
.game-icon-gibbet:before {
	content: "\f68e";
}
.game-icon-gift-of-knowledge:before {
	content: "\f68f";
}
.game-icon-gift-trap:before {
	content: "\f690";
}
.game-icon-gingerbread-man:before {
	content: "\f691";
}
.game-icon-ginkgo-leaf:before {
	content: "\f692";
}
.game-icon-gladius:before {
	content: "\f693";
}
.game-icon-glaive:before {
	content: "\f694";
}
.game-icon-glass-ball:before {
	content: "\f695";
}
.game-icon-glass-celebration:before {
	content: "\f696";
}
.game-icon-glass-heart:before {
	content: "\f697";
}
.game-icon-glass-shot:before {
	content: "\f698";
}
.game-icon-glider:before {
	content: "\f699";
}
.game-icon-globe-ring:before {
	content: "\f69a";
}
.game-icon-globe:before {
	content: "\f69b";
}
.game-icon-glock:before {
	content: "\f69c";
}
.game-icon-gloop:before {
	content: "\f69d";
}
.game-icon-gloves:before {
	content: "\f69e";
}
.game-icon-glowing-artifact:before {
	content: "\f69f";
}
.game-icon-glowing-hands:before {
	content: "\f6a0";
}
.game-icon-gluttonous-smile:before {
	content: "\f6a1";
}
.game-icon-gluttony:before {
	content: "\f6a2";
}
.game-icon-goal-keeper:before {
	content: "\f6a3";
}
.game-icon-goat:before {
	content: "\f6a4";
}
.game-icon-goblin-camp:before {
	content: "\f6a5";
}
.game-icon-goblin-head:before {
	content: "\f6a6";
}
.game-icon-gold-bar:before {
	content: "\f6a7";
}
.game-icon-gold-mine:before {
	content: "\f6a8";
}
.game-icon-gold-nuggets:before {
	content: "\f6a9";
}
.game-icon-gold-scarab:before {
	content: "\f6aa";
}
.game-icon-gold-shell:before {
	content: "\f6ab";
}
.game-icon-gold-stack:before {
	content: "\f6ac";
}
.game-icon-golem-head:before {
	content: "\f6ad";
}
.game-icon-golf-flag:before {
	content: "\f6ae";
}
.game-icon-golf-tee:before {
	content: "\f6af";
}
.game-icon-gong:before {
	content: "\f6b0";
}
.game-icon-goo-explosion:before {
	content: "\f6b1";
}
.game-icon-goo-skull:before {
	content: "\f6b2";
}
.game-icon-goo-spurt:before {
	content: "\f6b3";
}
.game-icon-gooey-daemon:before {
	content: "\f6b4";
}
.game-icon-gooey-eyed-sun:before {
	content: "\f6b5";
}
.game-icon-gooey-impact:before {
	content: "\f6b6";
}
.game-icon-gooey-molecule:before {
	content: "\f6b7";
}
.game-icon-gooey-sword:before {
	content: "\f6b8";
}
.game-icon-goose:before {
	content: "\f6b9";
}
.game-icon-gorilla:before {
	content: "\f6ba";
}
.game-icon-gothic-cross:before {
	content: "\f6bb";
}
.game-icon-gps:before {
	content: "\f6bc";
}
.game-icon-grab:before {
	content: "\f6bd";
}
.game-icon-graduate-cap:before {
	content: "\f6be";
}
.game-icon-grain-bundle:before {
	content: "\f6bf";
}
.game-icon-grain:before {
	content: "\f6c0";
}
.game-icon-granary:before {
	content: "\f6c1";
}
.game-icon-grand-piano:before {
	content: "\f6c2";
}
.game-icon-grapes:before {
	content: "\f6c3";
}
.game-icon-grapple:before {
	content: "\f6c4";
}
.game-icon-grasping-claws:before {
	content: "\f6c5";
}
.game-icon-grasping-slug:before {
	content: "\f6c6";
}
.game-icon-grass-mushroom:before {
	content: "\f6c7";
}
.game-icon-grass:before {
	content: "\f6c8";
}
.game-icon-grave-flowers:before {
	content: "\f6c9";
}
.game-icon-graveyard:before {
	content: "\f6ca";
}
.game-icon-grease-trap:before {
	content: "\f6cb";
}
.game-icon-great-pyramid:before {
	content: "\f6cc";
}
.game-icon-great-war-tank:before {
	content: "\f6cd";
}
.game-icon-greaves:before {
	content: "\f6ce";
}
.game-icon-greek-sphinx:before {
	content: "\f6cf";
}
.game-icon-greek-temple:before {
	content: "\f6d0";
}
.game-icon-green-power:before {
	content: "\f6d1";
}
.game-icon-greenhouse:before {
	content: "\f6d2";
}
.game-icon-grenade-2:before {
	content: "\f6d3";
}
.game-icon-grenade:before {
	content: "\f6d4";
}
.game-icon-griffin-shield:before {
	content: "\f6d5";
}
.game-icon-griffin-symbol:before {
	content: "\f6d6";
}
.game-icon-grim-reaper:before {
	content: "\f6d7";
}
.game-icon-ground-sprout:before {
	content: "\f6d8";
}
.game-icon-groundbreaker:before {
	content: "\f6d9";
}
.game-icon-grouped-drops:before {
	content: "\f6da";
}
.game-icon-growth:before {
	content: "\f6db";
}
.game-icon-guarded-tower:before {
	content: "\f6dc";
}
.game-icon-guards:before {
	content: "\f6dd";
}
.game-icon-guatemala:before {
	content: "\f6de";
}
.game-icon-guillotine:before {
	content: "\f6df";
}
.game-icon-guitar-bass-head:before {
	content: "\f6e0";
}
.game-icon-guitar-head:before {
	content: "\f6e1";
}
.game-icon-guitar:before {
	content: "\f6e2";
}
.game-icon-gun-rose:before {
	content: "\f6e3";
}
.game-icon-gun-stock:before {
	content: "\f6e4";
}
.game-icon-gunshot:before {
	content: "\f6e5";
}
.game-icon-gym-bag:before {
	content: "\f6e6";
}
.game-icon-h2o:before {
	content: "\f6e7";
}
.game-icon-habitat-dome:before {
	content: "\f6e8";
}
.game-icon-hades-symbol:before {
	content: "\f6e9";
}
.game-icon-hair-strands:before {
	content: "\f6ea";
}
.game-icon-halberd-shuriken:before {
	content: "\f6eb";
}
.game-icon-halberd:before {
	content: "\f6ec";
}
.game-icon-half-body-crawling:before {
	content: "\f6ed";
}
.game-icon-half-dead:before {
	content: "\f6ee";
}
.game-icon-half-heart:before {
	content: "\f6ef";
}
.game-icon-half-log:before {
	content: "\f6f0";
}
.game-icon-half-tornado:before {
	content: "\f6f1";
}
.game-icon-halt:before {
	content: "\f6f2";
}
.game-icon-ham-shank:before {
	content: "\f6f3";
}
.game-icon-hamburger-menu:before {
	content: "\f6f4";
}
.game-icon-hamburger:before {
	content: "\f6f5";
}
.game-icon-hammer-break:before {
	content: "\f6f6";
}
.game-icon-hammer-drop:before {
	content: "\f6f7";
}
.game-icon-hammer-nails:before {
	content: "\f6f8";
}
.game-icon-hammer-sickle:before {
	content: "\f6f9";
}
.game-icon-hand-2:before {
	content: "\f6fa";
}
.game-icon-hand-bag:before {
	content: "\f6fb";
}
.game-icon-hand-bandage:before {
	content: "\f6fc";
}
.game-icon-hand-grip:before {
	content: "\f6fd";
}
.game-icon-hand-of-god:before {
	content: "\f6fe";
}
.game-icon-hand-ok:before {
	content: "\f6ff";
}
.game-icon-hand-saw:before {
	content: "\f700";
}
.game-icon-hand-truck:before {
	content: "\f701";
}
.game-icon-hand-wing:before {
	content: "\f702";
}
.game-icon-hand:before {
	content: "\f703";
}
.game-icon-handcuffed:before {
	content: "\f704";
}
.game-icon-handcuffs:before {
	content: "\f705";
}
.game-icon-handheld-fan:before {
	content: "\f706";
}
.game-icon-hang-glider-2:before {
	content: "\f707";
}
.game-icon-hang-glider:before {
	content: "\f708";
}
.game-icon-hanger:before {
	content: "\f709";
}
.game-icon-hanging-sign:before {
	content: "\f70a";
}
.game-icon-hanging-spider:before {
	content: "\f70b";
}
.game-icon-happy-skull:before {
	content: "\f70c";
}
.game-icon-harbor-dock:before {
	content: "\f70d";
}
.game-icon-harp:before {
	content: "\f70e";
}
.game-icon-harpoon-chain:before {
	content: "\f70f";
}
.game-icon-harpoon-trident:before {
	content: "\f710";
}
.game-icon-harpy:before {
	content: "\f711";
}
.game-icon-harry-potter-skull:before {
	content: "\f712";
}
.game-icon-hasty-grave:before {
	content: "\f713";
}
.game-icon-hatchet:before {
	content: "\f714";
}
.game-icon-hatchets:before {
	content: "\f715";
}
.game-icon-haunting:before {
	content: "\f716";
}
.game-icon-hawk-emblem:before {
	content: "\f717";
}
.game-icon-hazard-sign:before {
	content: "\f718";
}
.game-icon-hazmat-suit:before {
	content: "\f719";
}
.game-icon-head-shot:before {
	content: "\f71a";
}
.game-icon-headband-knot:before {
	content: "\f71b";
}
.game-icon-headphones:before {
	content: "\f71c";
}
.game-icon-headshot-2:before {
	content: "\f71d";
}
.game-icon-headshot:before {
	content: "\f71e";
}
.game-icon-healing-shield:before {
	content: "\f71f";
}
.game-icon-healing:before {
	content: "\f720";
}
.game-icon-health-capsule:before {
	content: "\f721";
}
.game-icon-health-decrease:before {
	content: "\f722";
}
.game-icon-health-increase:before {
	content: "\f723";
}
.game-icon-health-normal:before {
	content: "\f724";
}
.game-icon-health-potion:before {
	content: "\f725";
}
.game-icon-hearing-disabled:before {
	content: "\f726";
}
.game-icon-heart-armor:before {
	content: "\f727";
}
.game-icon-heart-battery:before {
	content: "\f728";
}
.game-icon-heart-beats:before {
	content: "\f729";
}
.game-icon-heart-bottle:before {
	content: "\f72a";
}
.game-icon-heart-drop:before {
	content: "\f72b";
}
.game-icon-heart-earrings:before {
	content: "\f72c";
}
.game-icon-heart-inside:before {
	content: "\f72d";
}
.game-icon-heart-key:before {
	content: "\f72e";
}
.game-icon-heart-minus:before {
	content: "\f72f";
}
.game-icon-heart-necklace:before {
	content: "\f730";
}
.game-icon-heart-organ:before {
	content: "\f731";
}
.game-icon-heart-plus:before {
	content: "\f732";
}
.game-icon-heart-shield:before {
	content: "\f733";
}
.game-icon-heart-stake:before {
	content: "\f734";
}
.game-icon-heart-tower:before {
	content: "\f735";
}
.game-icon-heart-wings:before {
	content: "\f736";
}
.game-icon-heartburn:before {
	content: "\f737";
}
.game-icon-hearts:before {
	content: "\f738";
}
.game-icon-heat-haze:before {
	content: "\f739";
}
.game-icon-heaven-gate:before {
	content: "\f73a";
}
.game-icon-heavy-arrow:before {
	content: "\f73b";
}
.game-icon-heavy-bullets:before {
	content: "\f73c";
}
.game-icon-heavy-collar:before {
	content: "\f73d";
}
.game-icon-heavy-fall:before {
	content: "\f73e";
}
.game-icon-heavy-fighter:before {
	content: "\f73f";
}
.game-icon-heavy-helm:before {
	content: "\f740";
}
.game-icon-heavy-lightning:before {
	content: "\f741";
}
.game-icon-heavy-rain:before {
	content: "\f742";
}
.game-icon-heavy-thorny-triskelion:before {
	content: "\f743";
}
.game-icon-heavy-timer:before {
	content: "\f744";
}
.game-icon-hedgehog:before {
	content: "\f745";
}
.game-icon-hedjet-white-crown:before {
	content: "\f746";
}
.game-icon-helicoprion:before {
	content: "\f747";
}
.game-icon-helicopter-tail:before {
	content: "\f748";
}
.game-icon-helicopter:before {
	content: "\f749";
}
.game-icon-hell-crosses:before {
	content: "\f74a";
}
.game-icon-helmet-head-shot:before {
	content: "\f74b";
}
.game-icon-helmet:before {
	content: "\f74c";
}
.game-icon-help:before {
	content: "\f74d";
}
.game-icon-hemp:before {
	content: "\f74e";
}
.game-icon-heptagram:before {
	content: "\f74f";
}
.game-icon-heraldic-sun:before {
	content: "\f750";
}
.game-icon-herbs-bundle:before {
	content: "\f751";
}
.game-icon-heron:before {
	content: "\f752";
}
.game-icon-hexagonal-nut:before {
	content: "\f753";
}
.game-icon-hexes:before {
	content: "\f754";
}
.game-icon-hidden:before {
	content: "\f755";
}
.game-icon-hieroglyph-legs:before {
	content: "\f756";
}
.game-icon-hieroglyph-y:before {
	content: "\f757";
}
.game-icon-high-five:before {
	content: "\f758";
}
.game-icon-high-grass:before {
	content: "\f759";
}
.game-icon-high-heel:before {
	content: "\f75a";
}
.game-icon-high-kick:before {
	content: "\f75b";
}
.game-icon-high-punch:before {
	content: "\f75c";
}
.game-icon-high-shot:before {
	content: "\f75d";
}
.game-icon-high-tide:before {
	content: "\f75e";
}
.game-icon-highlighter:before {
	content: "\f75f";
}
.game-icon-hiking:before {
	content: "\f760";
}
.game-icon-hill-conquest:before {
	content: "\f761";
}
.game-icon-hill-fort:before {
	content: "\f762";
}
.game-icon-hills:before {
	content: "\f763";
}
.game-icon-histogram:before {
	content: "\f764";
}
.game-icon-hive-mind:before {
	content: "\f765";
}
.game-icon-hive:before {
	content: "\f766";
}
.game-icon-hobbit-door:before {
	content: "\f767";
}
.game-icon-hobbit-dwelling:before {
	content: "\f768";
}
.game-icon-hockey:before {
	content: "\f769";
}
.game-icon-hole-ladder:before {
	content: "\f76a";
}
.game-icon-hole:before {
	content: "\f76b";
}
.game-icon-hollow-cat:before {
	content: "\f76c";
}
.game-icon-holosphere:before {
	content: "\f76d";
}
.game-icon-holy-grail:before {
	content: "\f76e";
}
.game-icon-holy-hand-grenade:before {
	content: "\f76f";
}
.game-icon-holy-oak:before {
	content: "\f770";
}
.game-icon-holy-symbol:before {
	content: "\f771";
}
.game-icon-holy-water:before {
	content: "\f772";
}
.game-icon-home-garage:before {
	content: "\f773";
}
.game-icon-honey-jar:before {
	content: "\f774";
}
.game-icon-honeycomb:before {
	content: "\f775";
}
.game-icon-honeypot:before {
	content: "\f776";
}
.game-icon-hood:before {
	content: "\f777";
}
.game-icon-hooded-assassin:before {
	content: "\f778";
}
.game-icon-hooded-figure:before {
	content: "\f779";
}
.game-icon-hoodie:before {
	content: "\f77a";
}
.game-icon-hoof:before {
	content: "\f77b";
}
.game-icon-hook:before {
	content: "\f77c";
}
.game-icon-hops:before {
	content: "\f77d";
}
.game-icon-horizon-road:before {
	content: "\f77e";
}
.game-icon-horizontal-flip:before {
	content: "\f77f";
}
.game-icon-horn-internal:before {
	content: "\f780";
}
.game-icon-horned-helm:before {
	content: "\f781";
}
.game-icon-horned-reptile:before {
	content: "\f782";
}
.game-icon-horned-skull:before {
	content: "\f783";
}
.game-icon-horse-head-2:before {
	content: "\f784";
}
.game-icon-horse-head:before {
	content: "\f785";
}
.game-icon-horseshoe:before {
	content: "\f786";
}
.game-icon-horus:before {
	content: "\f787";
}
.game-icon-hospital-cross:before {
	content: "\f788";
}
.game-icon-hospital:before {
	content: "\f789";
}
.game-icon-hot-dog:before {
	content: "\f78a";
}
.game-icon-hot-meal:before {
	content: "\f78b";
}
.game-icon-hot-spices:before {
	content: "\f78c";
}
.game-icon-hot-surface:before {
	content: "\f78d";
}
.game-icon-hound:before {
	content: "\f78e";
}
.game-icon-hourglass:before {
	content: "\f78f";
}
.game-icon-house-keys:before {
	content: "\f790";
}
.game-icon-house:before {
	content: "\f791";
}
.game-icon-human-cannonball:before {
	content: "\f792";
}
.game-icon-human-ear:before {
	content: "\f793";
}
.game-icon-human-pyramid:before {
	content: "\f794";
}
.game-icon-human-target:before {
	content: "\f795";
}
.game-icon-hummingbird:before {
	content: "\f796";
}
.game-icon-hungary:before {
	content: "\f797";
}
.game-icon-hunter-eyes:before {
	content: "\f798";
}
.game-icon-hunting-bolas:before {
	content: "\f799";
}
.game-icon-hunting-horn:before {
	content: "\f79a";
}
.game-icon-hut:before {
	content: "\f79b";
}
.game-icon-huts-village:before {
	content: "\f79c";
}
.game-icon-hydra-shot:before {
	content: "\f79d";
}
.game-icon-hydra:before {
	content: "\f79e";
}
.game-icon-hyena-head:before {
	content: "\f79f";
}
.game-icon-hypersonic-bolt:before {
	content: "\f7a0";
}
.game-icon-hypersonic-melon:before {
	content: "\f7a1";
}
.game-icon-hypodermic-test:before {
	content: "\f7a2";
}
.game-icon-i-beam:before {
	content: "\f7a3";
}
.game-icon-i-brick:before {
	content: "\f7a4";
}
.game-icon-ibis:before {
	content: "\f7a5";
}
.game-icon-icarus:before {
	content: "\f7a6";
}
.game-icon-ice-bolt:before {
	content: "\f7a7";
}
.game-icon-ice-bomb:before {
	content: "\f7a8";
}
.game-icon-ice-cream-cone:before {
	content: "\f7a9";
}
.game-icon-ice-cream-scoop:before {
	content: "\f7aa";
}
.game-icon-ice-cube:before {
	content: "\f7ab";
}
.game-icon-ice-cubes:before {
	content: "\f7ac";
}
.game-icon-ice-golem:before {
	content: "\f7ad";
}
.game-icon-ice-iris:before {
	content: "\f7ae";
}
.game-icon-ice-pop:before {
	content: "\f7af";
}
.game-icon-ice-shield:before {
	content: "\f7b0";
}
.game-icon-ice-skate:before {
	content: "\f7b1";
}
.game-icon-ice-spear:before {
	content: "\f7b2";
}
.game-icon-ice-spell-cast:before {
	content: "\f7b3";
}
.game-icon-iceberg:before {
	content: "\f7b4";
}
.game-icon-icebergs:before {
	content: "\f7b5";
}
.game-icon-iceland:before {
	content: "\f7b6";
}
.game-icon-icicles-aura:before {
	content: "\f7b7";
}
.game-icon-icicles-fence:before {
	content: "\f7b8";
}
.game-icon-id-card:before {
	content: "\f7b9";
}
.game-icon-idea:before {
	content: "\f7ba";
}
.game-icon-ifrit:before {
	content: "\f7bb";
}
.game-icon-igloo:before {
	content: "\f7bc";
}
.game-icon-imbricated-arrows:before {
	content: "\f7bd";
}
.game-icon-imp-laugh:before {
	content: "\f7be";
}
.game-icon-imp:before {
	content: "\f7bf";
}
.game-icon-impact-point:before {
	content: "\f7c0";
}
.game-icon-imperial-crown:before {
	content: "\f7c1";
}
.game-icon-implosion:before {
	content: "\f7c2";
}
.game-icon-imprisoned:before {
	content: "\f7c3";
}
.game-icon-inauguration:before {
	content: "\f7c4";
}
.game-icon-incense:before {
	content: "\f7c5";
}
.game-icon-incisors:before {
	content: "\f7c6";
}
.game-icon-incoming-rocket:before {
	content: "\f7c7";
}
.game-icon-incubator:before {
	content: "\f7c8";
}
.game-icon-india-gate:before {
	content: "\f7c9";
}
.game-icon-indian-palace:before {
	content: "\f7ca";
}
.game-icon-inferno-bomb:before {
	content: "\f7cb";
}
.game-icon-infested-mass:before {
	content: "\f7cc";
}
.game-icon-infinity:before {
	content: "\f7cd";
}
.game-icon-info:before {
	content: "\f7ce";
}
.game-icon-injustice:before {
	content: "\f7cf";
}
.game-icon-ink-swirl:before {
	content: "\f7d0";
}
.game-icon-inner-self:before {
	content: "\f7d1";
}
.game-icon-insect-jaws:before {
	content: "\f7d2";
}
.game-icon-inspiration:before {
	content: "\f7d3";
}
.game-icon-interceptor-ship:before {
	content: "\f7d4";
}
.game-icon-interdiction:before {
	content: "\f7d5";
}
.game-icon-interlaced-tentacles:before {
	content: "\f7d6";
}
.game-icon-interleaved-arrows:before {
	content: "\f7d7";
}
.game-icon-interleaved-claws:before {
	content: "\f7d8";
}
.game-icon-internal-injury:before {
	content: "\f7d9";
}
.game-icon-internal-organ:before {
	content: "\f7da";
}
.game-icon-interstellar-path:before {
	content: "\f7db";
}
.game-icon-intricate-necklace:before {
	content: "\f7dc";
}
.game-icon-inverted-dice-1:before {
	content: "\f7dd";
}
.game-icon-inverted-dice-2:before {
	content: "\f7de";
}
.game-icon-inverted-dice-3:before {
	content: "\f7df";
}
.game-icon-inverted-dice-4:before {
	content: "\f7e0";
}
.game-icon-inverted-dice-5:before {
	content: "\f7e1";
}
.game-icon-inverted-dice-6:before {
	content: "\f7e2";
}
.game-icon-invisible-face:before {
	content: "\f7e3";
}
.game-icon-invisible:before {
	content: "\f7e4";
}
.game-icon-ion-cannon-blast:before {
	content: "\f7e5";
}
.game-icon-ionic-column:before {
	content: "\f7e6";
}
.game-icon-iraq:before {
	content: "\f7e7";
}
.game-icon-iron-cross:before {
	content: "\f7e8";
}
.game-icon-iron-hulled-warship:before {
	content: "\f7e9";
}
.game-icon-iron-mask:before {
	content: "\f7ea";
}
.game-icon-island:before {
	content: "\f7eb";
}
.game-icon-italia:before {
	content: "\f7ec";
}
.game-icon-ivory-tusks:before {
	content: "\f7ed";
}
.game-icon-j-brick:before {
	content: "\f7ee";
}
.game-icon-jack-plug:before {
	content: "\f7ef";
}
.game-icon-james-bond-aperture:before {
	content: "\f7f0";
}
.game-icon-japan:before {
	content: "\f7f1";
}
.game-icon-japanese-bridge:before {
	content: "\f7f2";
}
.game-icon-jasmine:before {
	content: "\f7f3";
}
.game-icon-jason-mask:before {
	content: "\f7f4";
}
.game-icon-jawbone:before {
	content: "\f7f5";
}
.game-icon-jawless-cyclop:before {
	content: "\f7f6";
}
.game-icon-jeep-2:before {
	content: "\f7f7";
}
.game-icon-jeep:before {
	content: "\f7f8";
}
.game-icon-jelly-beans:before {
	content: "\f7f9";
}
.game-icon-jelly:before {
	content: "\f7fa";
}
.game-icon-jellyfish:before {
	content: "\f7fb";
}
.game-icon-jerrycan:before {
	content: "\f7fc";
}
.game-icon-jerusalem-cross:before {
	content: "\f7fd";
}
.game-icon-jester-hat:before {
	content: "\f7fe";
}
.game-icon-jet-fighter:before {
	content: "\f7ff";
}
.game-icon-jet-pack:before {
	content: "\f800";
}
.game-icon-jetpack:before {
	content: "\f801";
}
.game-icon-jewel-crown:before {
	content: "\f802";
}
.game-icon-jeweled-chalice:before {
	content: "\f803";
}
.game-icon-jigsaw-box:before {
	content: "\f804";
}
.game-icon-jigsaw-piece:before {
	content: "\f805";
}
.game-icon-join:before {
	content: "\f806";
}
.game-icon-joint:before {
	content: "\f807";
}
.game-icon-journey:before {
	content: "\f808";
}
.game-icon-joystick:before {
	content: "\f809";
}
.game-icon-jug:before {
	content: "\f80a";
}
.game-icon-juggler:before {
	content: "\f80b";
}
.game-icon-juggling-clubs:before {
	content: "\f80c";
}
.game-icon-juggling-seal:before {
	content: "\f80d";
}
.game-icon-jump-across:before {
	content: "\f80e";
}
.game-icon-jumping-dog:before {
	content: "\f80f";
}
.game-icon-jumping-rope:before {
	content: "\f810";
}
.game-icon-jungle:before {
	content: "\f811";
}
.game-icon-jupiter:before {
	content: "\f812";
}
.game-icon-justice-star:before {
	content: "\f813";
}
.game-icon-kaleidoscope-pearls:before {
	content: "\f814";
}
.game-icon-kangaroo:before {
	content: "\f815";
}
.game-icon-katana:before {
	content: "\f816";
}
.game-icon-kebab-spit:before {
	content: "\f817";
}
.game-icon-kenku-head:before {
	content: "\f818";
}
.game-icon-kenya:before {
	content: "\f819";
}
.game-icon-ketchup:before {
	content: "\f81a";
}
.game-icon-kevlar-vest:before {
	content: "\f81b";
}
.game-icon-kevlar:before {
	content: "\f81c";
}
.game-icon-key-2:before {
	content: "\f81d";
}
.game-icon-key-card:before {
	content: "\f81e";
}
.game-icon-key-lock:before {
	content: "\f81f";
}
.game-icon-key:before {
	content: "\f820";
}
.game-icon-keyboard:before {
	content: "\f821";
}
.game-icon-keyring:before {
	content: "\f822";
}
.game-icon-kick-scooter:before {
	content: "\f823";
}
.game-icon-kid-slide:before {
	content: "\f824";
}
.game-icon-kidneys:before {
	content: "\f825";
}
.game-icon-kimono:before {
	content: "\f826";
}
.game-icon-kindle:before {
	content: "\f827";
}
.game-icon-king-ju-mask:before {
	content: "\f828";
}
.game-icon-king:before {
	content: "\f829";
}
.game-icon-kitchen-knives:before {
	content: "\f82a";
}
.game-icon-kitchen-scale:before {
	content: "\f82b";
}
.game-icon-kitchen-tap:before {
	content: "\f82c";
}
.game-icon-kite:before {
	content: "\f82d";
}
.game-icon-kiwi-bird:before {
	content: "\f82e";
}
.game-icon-kiwi-fruit:before {
	content: "\f82f";
}
.game-icon-klingon:before {
	content: "\f830";
}
.game-icon-knapsack:before {
	content: "\f831";
}
.game-icon-knee-bandage:before {
	content: "\f832";
}
.game-icon-knee-cap:before {
	content: "\f833";
}
.game-icon-knee-pad:before {
	content: "\f834";
}
.game-icon-kneeling:before {
	content: "\f835";
}
.game-icon-knife-fork:before {
	content: "\f836";
}
.game-icon-knife-thrust:before {
	content: "\f837";
}
.game-icon-knight-banner:before {
	content: "\f838";
}
.game-icon-knocked-out-stars:before {
	content: "\f839";
}
.game-icon-knockout:before {
	content: "\f83a";
}
.game-icon-knot:before {
	content: "\f83b";
}
.game-icon-koala:before {
	content: "\f83c";
}
.game-icon-koholint-egg:before {
	content: "\f83d";
}
.game-icon-kraken-tentacle:before {
	content: "\f83e";
}
.game-icon-kusarigama:before {
	content: "\f83f";
}
.game-icon-l-brick:before {
	content: "\f840";
}
.game-icon-lab-coat:before {
	content: "\f841";
}
.game-icon-labrador-head:before {
	content: "\f842";
}
.game-icon-ladder:before {
	content: "\f843";
}
.game-icon-ladders-platform:before {
	content: "\f844";
}
.game-icon-ladle:before {
	content: "\f845";
}
.game-icon-ladybug:before {
	content: "\f846";
}
.game-icon-lamellar:before {
	content: "\f847";
}
.game-icon-lamprey-mouth:before {
	content: "\f848";
}
.game-icon-land-mine:before {
	content: "\f849";
}
.game-icon-lantern-flame:before {
	content: "\f84a";
}
.game-icon-lantern:before {
	content: "\f84b";
}
.game-icon-laptop:before {
	content: "\f84c";
}
.game-icon-large-dress:before {
	content: "\f84d";
}
.game-icon-large-paint-brush:before {
	content: "\f84e";
}
.game-icon-large-wound:before {
	content: "\f84f";
}
.game-icon-laser-blast:before {
	content: "\f850";
}
.game-icon-laser-burst:before {
	content: "\f851";
}
.game-icon-laser-gun:before {
	content: "\f852";
}
.game-icon-laser-precision:before {
	content: "\f853";
}
.game-icon-laser-sparks:before {
	content: "\f854";
}
.game-icon-laser-turret:before {
	content: "\f855";
}
.game-icon-laser-warning:before {
	content: "\f856";
}
.game-icon-laserburn:before {
	content: "\f857";
}
.game-icon-lasso:before {
	content: "\f858";
}
.game-icon-latvia:before {
	content: "\f859";
}
.game-icon-laurel-crown:before {
	content: "\f85a";
}
.game-icon-laurels-trophy:before {
	content: "\f85b";
}
.game-icon-laurels:before {
	content: "\f85c";
}
.game-icon-lava:before {
	content: "\f85d";
}
.game-icon-law-star:before {
	content: "\f85e";
}
.game-icon-layered-armor:before {
	content: "\f85f";
}
.game-icon-lead-pipe:before {
	content: "\f860";
}
.game-icon-leaf-skeleton:before {
	content: "\f861";
}
.game-icon-leaf-swirl:before {
	content: "\f862";
}
.game-icon-leak:before {
	content: "\f863";
}
.game-icon-leaky-skull:before {
	content: "\f864";
}
.game-icon-leapfrog:before {
	content: "\f865";
}
.game-icon-leather-armor:before {
	content: "\f866";
}
.game-icon-leather-boot:before {
	content: "\f867";
}
.game-icon-leather-vest:before {
	content: "\f868";
}
.game-icon-led:before {
	content: "\f869";
}
.game-icon-lee-enfield:before {
	content: "\f86a";
}
.game-icon-leeching-worm:before {
	content: "\f86b";
}
.game-icon-leek:before {
	content: "\f86c";
}
.game-icon-leg-armor:before {
	content: "\f86d";
}
.game-icon-leg:before {
	content: "\f86e";
}
.game-icon-lemon:before {
	content: "\f86f";
}
.game-icon-leo:before {
	content: "\f870";
}
.game-icon-letter-bomb:before {
	content: "\f871";
}
.game-icon-level-crossing:before {
	content: "\f872";
}
.game-icon-level-end-flag:before {
	content: "\f873";
}
.game-icon-level-four-advanced:before {
	content: "\f874";
}
.game-icon-level-four:before {
	content: "\f875";
}
.game-icon-level-three-advanced:before {
	content: "\f876";
}
.game-icon-level-three:before {
	content: "\f877";
}
.game-icon-level-two-advanced:before {
	content: "\f878";
}
.game-icon-level-two:before {
	content: "\f879";
}
.game-icon-lever:before {
	content: "\f87a";
}
.game-icon-liar:before {
	content: "\f87b";
}
.game-icon-liberty-wing:before {
	content: "\f87c";
}
.game-icon-libra:before {
	content: "\f87d";
}
.game-icon-libya:before {
	content: "\f87e";
}
.game-icon-life-bar:before {
	content: "\f87f";
}
.game-icon-life-buoy:before {
	content: "\f880";
}
.game-icon-life-in-the-balance:before {
	content: "\f881";
}
.game-icon-life-jacket:before {
	content: "\f882";
}
.game-icon-life-support:before {
	content: "\f883";
}
.game-icon-life-tap:before {
	content: "\f884";
}
.game-icon-lift:before {
	content: "\f885";
}
.game-icon-light-backpack:before {
	content: "\f886";
}
.game-icon-light-bulb:before {
	content: "\f887";
}
.game-icon-light-fighter:before {
	content: "\f888";
}
.game-icon-light-helm:before {
	content: "\f889";
}
.game-icon-light-projector:before {
	content: "\f88a";
}
.game-icon-light-sabers:before {
	content: "\f88b";
}
.game-icon-light-thorny-triskelion:before {
	content: "\f88c";
}
.game-icon-lighter:before {
	content: "\f88d";
}
.game-icon-lighthouse:before {
	content: "\f88e";
}
.game-icon-lightning-arc:before {
	content: "\f88f";
}
.game-icon-lightning-bow:before {
	content: "\f890";
}
.game-icon-lightning-branches:before {
	content: "\f891";
}
.game-icon-lightning-dissipation:before {
	content: "\f892";
}
.game-icon-lightning-dome:before {
	content: "\f893";
}
.game-icon-lightning-electron:before {
	content: "\f894";
}
.game-icon-lightning-flame:before {
	content: "\f895";
}
.game-icon-lightning-frequency:before {
	content: "\f896";
}
.game-icon-lightning-helix:before {
	content: "\f897";
}
.game-icon-lightning-mask:before {
	content: "\f898";
}
.game-icon-lightning-saber:before {
	content: "\f899";
}
.game-icon-lightning-shadow:before {
	content: "\f89a";
}
.game-icon-lightning-shield:before {
	content: "\f89b";
}
.game-icon-lightning-shout:before {
	content: "\f89c";
}
.game-icon-lightning-slashes:before {
	content: "\f89d";
}
.game-icon-lightning-spanner:before {
	content: "\f89e";
}
.game-icon-lightning-storm:before {
	content: "\f89f";
}
.game-icon-lightning-tear:before {
	content: "\f8a0";
}
.game-icon-lightning-tree:before {
	content: "\f8a1";
}
.game-icon-lightning-trio:before {
	content: "\f8a2";
}
.game-icon-lily-pads:before {
	content: "\f8a3";
}
.game-icon-linden-leaf:before {
	content: "\f8a4";
}
.game-icon-linked-rings:before {
	content: "\f8a5";
}
.game-icon-lion:before {
	content: "\f8a6";
}
.game-icon-lips:before {
	content: "\f8a7";
}
.game-icon-lipstick:before {
	content: "\f8a8";
}
.game-icon-liquid-soap:before {
	content: "\f8a9";
}
.game-icon-lit-candelabra:before {
	content: "\f8aa";
}
.game-icon-liver:before {
	content: "\f8ab";
}
.game-icon-lizard-tongue:before {
	content: "\f8ac";
}
.game-icon-lizardman:before {
	content: "\f8ad";
}
.game-icon-load:before {
	content: "\f8ae";
}
.game-icon-lob-arrow:before {
	content: "\f8af";
}
.game-icon-lock-picking:before {
	content: "\f8b0";
}
.game-icon-lock-spy:before {
	content: "\f8b1";
}
.game-icon-locked-box:before {
	content: "\f8b2";
}
.game-icon-locked-chest:before {
	content: "\f8b3";
}
.game-icon-locked-door:before {
	content: "\f8b4";
}
.game-icon-locked-fortress:before {
	content: "\f8b5";
}
.game-icon-locked-heart:before {
	content: "\f8b6";
}
.game-icon-lockers:before {
	content: "\f8b7";
}
.game-icon-lockpicks:before {
	content: "\f8b8";
}
.game-icon-log:before {
	content: "\f8b9";
}
.game-icon-logging:before {
	content: "\f8ba";
}
.game-icon-logic-gate-and:before {
	content: "\f8bb";
}
.game-icon-logic-gate-nand:before {
	content: "\f8bc";
}
.game-icon-logic-gate-nor:before {
	content: "\f8bd";
}
.game-icon-logic-gate-not:before {
	content: "\f8be";
}
.game-icon-logic-gate-nxor:before {
	content: "\f8bf";
}
.game-icon-logic-gate-or:before {
	content: "\f8c0";
}
.game-icon-logic-gate-xor:before {
	content: "\f8c1";
}
.game-icon-loincloth:before {
	content: "\f8c2";
}
.game-icon-long-antennae-bug:before {
	content: "\f8c3";
}
.game-icon-long-legged-spider:before {
	content: "\f8c4";
}
.game-icon-look-at:before {
	content: "\f8c5";
}
.game-icon-lorgnette:before {
	content: "\f8c6";
}
.game-icon-lost-limb:before {
	content: "\f8c7";
}
.game-icon-lotus-flower:before {
	content: "\f8c8";
}
.game-icon-lotus:before {
	content: "\f8c9";
}
.game-icon-louvre-pyramid:before {
	content: "\f8ca";
}
.game-icon-love-howl:before {
	content: "\f8cb";
}
.game-icon-love-injection:before {
	content: "\f8cc";
}
.game-icon-love-letter:before {
	content: "\f8cd";
}
.game-icon-love-mystery:before {
	content: "\f8ce";
}
.game-icon-love-song:before {
	content: "\f8cf";
}
.game-icon-lovers:before {
	content: "\f8d0";
}
.game-icon-low-tide:before {
	content: "\f8d1";
}
.game-icon-luchador:before {
	content: "\f8d2";
}
.game-icon-lucifer-cannon:before {
	content: "\f8d3";
}
.game-icon-lucky-fisherman:before {
	content: "\f8d4";
}
.game-icon-luger:before {
	content: "\f8d5";
}
.game-icon-lunar-module:before {
	content: "\f8d6";
}
.game-icon-lunar-wand:before {
	content: "\f8d7";
}
.game-icon-lungs:before {
	content: "\f8d8";
}
.game-icon-lynx-head:before {
	content: "\f8d9";
}
.game-icon-lyre:before {
	content: "\f8da";
}
.game-icon-m3-grease-gun:before {
	content: "\f8db";
}
.game-icon-mac-10:before {
	content: "\f8dc";
}
.game-icon-mace-head:before {
	content: "\f8dd";
}
.game-icon-machete:before {
	content: "\f8de";
}
.game-icon-machine-gun-magazine:before {
	content: "\f8df";
}
.game-icon-machine-gun:before {
	content: "\f8e0";
}
.game-icon-mad-scientist:before {
	content: "\f8e1";
}
.game-icon-maggot:before {
	content: "\f8e2";
}
.game-icon-magic-axe:before {
	content: "\f8e3";
}
.game-icon-magic-broom:before {
	content: "\f8e4";
}
.game-icon-magic-gate:before {
	content: "\f8e5";
}
.game-icon-magic-hat:before {
	content: "\f8e6";
}
.game-icon-magic-lamp:before {
	content: "\f8e7";
}
.game-icon-magic-palm:before {
	content: "\f8e8";
}
.game-icon-magic-portal:before {
	content: "\f8e9";
}
.game-icon-magic-potion:before {
	content: "\f8ea";
}
.game-icon-magic-shield:before {
	content: "\f8eb";
}
.game-icon-magic-swirl:before {
	content: "\f8ec";
}
.game-icon-magic-trident:before {
	content: "\f8ed";
}
.game-icon-magick-trick:before {
	content: "\f8ee";
}
.game-icon-magnet-blast:before {
	content: "\f8ef";
}
.game-icon-magnet-man:before {
	content: "\f8f0";
}
.game-icon-magnet:before {
	content: "\f8f1";
}
.game-icon-magnifying-glass:before {
	content: "\f8f2";
}
.game-icon-mail-shirt:before {
	content: "\f8f3";
}
.game-icon-mailbox:before {
	content: "\f8f4";
}
.game-icon-mailed-fist:before {
	content: "\f8f5";
}
.game-icon-male:before {
	content: "\f8f6";
}
.game-icon-mammoth:before {
	content: "\f8f7";
}
.game-icon-manacles:before {
	content: "\f8f8";
}
.game-icon-mandrill-head:before {
	content: "\f8f9";
}
.game-icon-manta-ray:before {
	content: "\f8fa";
}
.game-icon-mantrap:before {
	content: "\f8fb";
}
.game-icon-manual-juicer:before {
	content: "\f8fc";
}
.game-icon-manual-meat-grinder:before {
	content: "\f8fd";
}
.game-icon-maple-leaf:before {
	content: "\f8fe";
}
.game-icon-maracas:before {
	content: "\f8ff";
}
.game-icon-marble-tap:before {
	content: "\f900";
}
.game-icon-marbles:before {
	content: "\f901";
}
.game-icon-marrow-drain:before {
	content: "\f902";
}
.game-icon-mars-curiosity:before {
	content: "\f903";
}
.game-icon-mars-pathfinder:before {
	content: "\f904";
}
.game-icon-marshmallows:before {
	content: "\f905";
}
.game-icon-martini:before {
	content: "\f906";
}
.game-icon-martyr-memorial:before {
	content: "\f907";
}
.game-icon-masked-spider:before {
	content: "\f908";
}
.game-icon-mason-jar:before {
	content: "\f909";
}
.game-icon-mass-driver:before {
	content: "\f90a";
}
.game-icon-master-of-arms:before {
	content: "\f90b";
}
.game-icon-match-head:before {
	content: "\f90c";
}
.game-icon-match-tip:before {
	content: "\f90d";
}
.game-icon-matchbox:before {
	content: "\f90e";
}
.game-icon-materials-science:before {
	content: "\f90f";
}
.game-icon-matryoshka-dolls:before {
	content: "\f910";
}
.game-icon-matter-states:before {
	content: "\f911";
}
.game-icon-mayan-pyramid:before {
	content: "\f912";
}
.game-icon-maze-cornea:before {
	content: "\f913";
}
.game-icon-maze-saw:before {
	content: "\f914";
}
.game-icon-maze:before {
	content: "\f915";
}
.game-icon-meal:before {
	content: "\f916";
}
.game-icon-measure-tape:before {
	content: "\f917";
}
.game-icon-meat-cleaver:before {
	content: "\f918";
}
.game-icon-meat-hook:before {
	content: "\f919";
}
.game-icon-meat:before {
	content: "\f91a";
}
.game-icon-mecha-head:before {
	content: "\f91b";
}
.game-icon-mecha-mask:before {
	content: "\f91c";
}
.game-icon-mechanic-garage:before {
	content: "\f91d";
}
.game-icon-mechanical-arm:before {
	content: "\f91e";
}
.game-icon-medal-skull:before {
	content: "\f91f";
}
.game-icon-medal:before {
	content: "\f920";
}
.game-icon-medallist:before {
	content: "\f921";
}
.game-icon-medical-drip:before {
	content: "\f922";
}
.game-icon-medical-pack-alt:before {
	content: "\f923";
}
.game-icon-medical-pack:before {
	content: "\f924";
}
.game-icon-medical-thermometer:before {
	content: "\f925";
}
.game-icon-medicine-pills:before {
	content: "\f926";
}
.game-icon-medicines:before {
	content: "\f927";
}
.game-icon-medieval-barracks:before {
	content: "\f928";
}
.game-icon-medieval-gate:before {
	content: "\f929";
}
.game-icon-medieval-pavilion:before {
	content: "\f92a";
}
.game-icon-meditation:before {
	content: "\f92b";
}
.game-icon-medusa-head:before {
	content: "\f92c";
}
.game-icon-meeple-army:before {
	content: "\f92d";
}
.game-icon-meeple-circle:before {
	content: "\f92e";
}
.game-icon-meeple-group:before {
	content: "\f92f";
}
.game-icon-meeple-king:before {
	content: "\f930";
}
.game-icon-meeple:before {
	content: "\f931";
}
.game-icon-megabot:before {
	content: "\f932";
}
.game-icon-megaphone:before {
	content: "\f933";
}
.game-icon-melting-ice-cube:before {
	content: "\f934";
}
.game-icon-melting-metal:before {
	content: "\f935";
}
.game-icon-menhir:before {
	content: "\f936";
}
.game-icon-mermaid:before {
	content: "\f937";
}
.game-icon-mesh-ball:before {
	content: "\f938";
}
.game-icon-mesh-network:before {
	content: "\f939";
}
.game-icon-metal-bar:before {
	content: "\f93a";
}
.game-icon-metal-boot:before {
	content: "\f93b";
}
.game-icon-metal-detector:before {
	content: "\f93c";
}
.game-icon-metal-disc:before {
	content: "\f93d";
}
.game-icon-metal-golem-head:before {
	content: "\f93e";
}
.game-icon-metal-hand:before {
	content: "\f93f";
}
.game-icon-metal-plate:before {
	content: "\f940";
}
.game-icon-metal-scales:before {
	content: "\f941";
}
.game-icon-metal-skirt:before {
	content: "\f942";
}
.game-icon-meteor-impact:before {
	content: "\f943";
}
.game-icon-metroid:before {
	content: "\f944";
}
.game-icon-metronome:before {
	content: "\f945";
}
.game-icon-mexico:before {
	content: "\f946";
}
.game-icon-microchip:before {
	content: "\f947";
}
.game-icon-microphone:before {
	content: "\f948";
}
.game-icon-microscope-lens:before {
	content: "\f949";
}
.game-icon-microscope:before {
	content: "\f94a";
}
.game-icon-middle-arrow:before {
	content: "\f94b";
}
.game-icon-midnight-claw:before {
	content: "\f94c";
}
.game-icon-mighty-boosh:before {
	content: "\f94d";
}
.game-icon-mighty-force:before {
	content: "\f94e";
}
.game-icon-mighty-horn:before {
	content: "\f94f";
}
.game-icon-mighty-spanner:before {
	content: "\f950";
}
.game-icon-military-ambulance:before {
	content: "\f951";
}
.game-icon-military-fort:before {
	content: "\f952";
}
.game-icon-milk-carton:before {
	content: "\f953";
}
.game-icon-millenium-key:before {
	content: "\f954";
}
.game-icon-mimic-chest:before {
	content: "\f955";
}
.game-icon-mine-explosion:before {
	content: "\f956";
}
.game-icon-mine-truck:before {
	content: "\f957";
}
.game-icon-mine-wagon:before {
	content: "\f958";
}
.game-icon-minefield:before {
	content: "\f959";
}
.game-icon-miner:before {
	content: "\f95a";
}
.game-icon-mineral-heart:before {
	content: "\f95b";
}
.game-icon-mineral-pearls:before {
	content: "\f95c";
}
.game-icon-minerals:before {
	content: "\f95d";
}
.game-icon-mini-submarine:before {
	content: "\f95e";
}
.game-icon-minigun:before {
	content: "\f95f";
}
.game-icon-mining-helmet:before {
	content: "\f960";
}
.game-icon-mining:before {
	content: "\f961";
}
.game-icon-minions:before {
	content: "\f962";
}
.game-icon-minotaur:before {
	content: "\f963";
}
.game-icon-miracle-medecine:before {
	content: "\f964";
}
.game-icon-mirror-mirror:before {
	content: "\f965";
}
.game-icon-misdirection:before {
	content: "\f966";
}
.game-icon-missile-launcher:before {
	content: "\f967";
}
.game-icon-missile-mech:before {
	content: "\f968";
}
.game-icon-missile-pod:before {
	content: "\f969";
}
.game-icon-missile-swarm:before {
	content: "\f96a";
}
.game-icon-mite-alt:before {
	content: "\f96b";
}
.game-icon-mite:before {
	content: "\f96c";
}
.game-icon-moai:before {
	content: "\f96d";
}
.game-icon-modern-city:before {
	content: "\f96e";
}
.game-icon-moebius-star:before {
	content: "\f96f";
}
.game-icon-moebius-trefoil:before {
	content: "\f970";
}
.game-icon-moebius-triangle:before {
	content: "\f971";
}
.game-icon-moka-pot:before {
	content: "\f972";
}
.game-icon-moldova:before {
	content: "\f973";
}
.game-icon-molecule-2:before {
	content: "\f974";
}
.game-icon-molecule:before {
	content: "\f975";
}
.game-icon-molotov:before {
	content: "\f976";
}
.game-icon-mona-lisa:before {
	content: "\f977";
}
.game-icon-moncler-jacket:before {
	content: "\f978";
}
.game-icon-money-stack:before {
	content: "\f979";
}
.game-icon-mongolia:before {
	content: "\f97a";
}
.game-icon-monk-face:before {
	content: "\f97b";
}
.game-icon-monkey-wrench:before {
	content: "\f97c";
}
.game-icon-monkey:before {
	content: "\f97d";
}
.game-icon-mono-wheel-robot:before {
	content: "\f97e";
}
.game-icon-monster-grasp:before {
	content: "\f97f";
}
.game-icon-monstera-leaf:before {
	content: "\f980";
}
.game-icon-monument-valley:before {
	content: "\f981";
}
.game-icon-moon-bats:before {
	content: "\f982";
}
.game-icon-moon-claws:before {
	content: "\f983";
}
.game-icon-moon-orbit:before {
	content: "\f984";
}
.game-icon-moon:before {
	content: "\f985";
}
.game-icon-mooring-bollard:before {
	content: "\f986";
}
.game-icon-morbid-humour:before {
	content: "\f987";
}
.game-icon-morgue-feet:before {
	content: "\f988";
}
.game-icon-morph-ball:before {
	content: "\f989";
}
.game-icon-mortar:before {
	content: "\f98a";
}
.game-icon-mountain-cave:before {
	content: "\f98b";
}
.game-icon-mountain-climbing:before {
	content: "\f98c";
}
.game-icon-mountain-road:before {
	content: "\f98d";
}
.game-icon-mountains:before {
	content: "\f98e";
}
.game-icon-mountaintop:before {
	content: "\f98f";
}
.game-icon-mounted-knight:before {
	content: "\f990";
}
.game-icon-mouse-2:before {
	content: "\f991";
}
.game-icon-mouse:before {
	content: "\f992";
}
.game-icon-mouth-watering:before {
	content: "\f993";
}
.game-icon-move:before {
	content: "\f994";
}
.game-icon-movement-sensor:before {
	content: "\f995";
}
.game-icon-mp-40:before {
	content: "\f996";
}
.game-icon-mp5-2:before {
	content: "\f997";
}
.game-icon-mp5:before {
	content: "\f998";
}
.game-icon-mp5k:before {
	content: "\f999";
}
.game-icon-mucous-pillar:before {
	content: "\f99a";
}
.game-icon-mug-shot:before {
	content: "\f99b";
}
.game-icon-multi-directions:before {
	content: "\f99c";
}
.game-icon-multiple-targets:before {
	content: "\f99d";
}
.game-icon-mummy-head:before {
	content: "\f99e";
}
.game-icon-muscle-fat:before {
	content: "\f99f";
}
.game-icon-muscle-up:before {
	content: "\f9a0";
}
.game-icon-muscular-torso:before {
	content: "\f9a1";
}
.game-icon-mushroom-cloud:before {
	content: "\f9a2";
}
.game-icon-mushroom-gills:before {
	content: "\f9a3";
}
.game-icon-mushroom-house:before {
	content: "\f9a4";
}
.game-icon-mushroom:before {
	content: "\f9a5";
}
.game-icon-mushrooms-cluster:before {
	content: "\f9a6";
}
.game-icon-mushrooms:before {
	content: "\f9a7";
}
.game-icon-music-spell:before {
	content: "\f9a8";
}
.game-icon-musical-keyboard:before {
	content: "\f9a9";
}
.game-icon-musical-notes:before {
	content: "\f9aa";
}
.game-icon-musical-score:before {
	content: "\f9ab";
}
.game-icon-musket:before {
	content: "\f9ac";
}
.game-icon-mussel:before {
	content: "\f9ad";
}
.game-icon-mustache:before {
	content: "\f9ae";
}
.game-icon-mute:before {
	content: "\f9af";
}
.game-icon-nachos:before {
	content: "\f9b0";
}
.game-icon-nailed-foot:before {
	content: "\f9b1";
}
.game-icon-nailed-head:before {
	content: "\f9b2";
}
.game-icon-nails:before {
	content: "\f9b3";
}
.game-icon-nano-bot:before {
	content: "\f9b4";
}
.game-icon-nautilus-shell:before {
	content: "\f9b5";
}
.game-icon-neck-bite:before {
	content: "\f9b6";
}
.game-icon-necklace-display:before {
	content: "\f9b7";
}
.game-icon-necklace:before {
	content: "\f9b8";
}
.game-icon-nectar:before {
	content: "\f9b9";
}
.game-icon-needle-drill:before {
	content: "\f9ba";
}
.game-icon-needle-jaws:before {
	content: "\f9bb";
}
.game-icon-nefertiti:before {
	content: "\f9bc";
}
.game-icon-nest-birds:before {
	content: "\f9bd";
}
.game-icon-nest-eggs:before {
	content: "\f9be";
}
.game-icon-nested-eclipses:before {
	content: "\f9bf";
}
.game-icon-nested-hearts:before {
	content: "\f9c0";
}
.game-icon-nested-hexagons:before {
	content: "\f9c1";
}
.game-icon-network-bars:before {
	content: "\f9c2";
}
.game-icon-new-born:before {
	content: "\f9c3";
}
.game-icon-new-shoot:before {
	content: "\f9c4";
}
.game-icon-newspaper:before {
	content: "\f9c5";
}
.game-icon-next-button:before {
	content: "\f9c6";
}
.game-icon-nigeria:before {
	content: "\f9c7";
}
.game-icon-night-sky:before {
	content: "\f9c8";
}
.game-icon-night-sleep:before {
	content: "\f9c9";
}
.game-icon-night-vision:before {
	content: "\f9ca";
}
.game-icon-ninja-armor:before {
	content: "\f9cb";
}
.game-icon-ninja-head:before {
	content: "\f9cc";
}
.game-icon-ninja-heroic-stance:before {
	content: "\f9cd";
}
.game-icon-ninja-mask:before {
	content: "\f9ce";
}
.game-icon-ninja-star:before {
	content: "\f9cf";
}
.game-icon-ninja-velociraptor:before {
	content: "\f9d0";
}
.game-icon-nodular:before {
	content: "\f9d1";
}
.game-icon-noodle-ball:before {
	content: "\f9d2";
}
.game-icon-noodles:before {
	content: "\f9d3";
}
.game-icon-north-star-shuriken:before {
	content: "\f9d4";
}
.game-icon-nose-front:before {
	content: "\f9d5";
}
.game-icon-nose-side:before {
	content: "\f9d6";
}
.game-icon-notebook:before {
	content: "\f9d7";
}
.game-icon-nothing-to-say:before {
	content: "\f9d8";
}
.game-icon-nuclear-bomb:before {
	content: "\f9d9";
}
.game-icon-nuclear-plant:before {
	content: "\f9da";
}
.game-icon-nuclear-waste:before {
	content: "\f9db";
}
.game-icon-nuclear:before {
	content: "\f9dc";
}
.game-icon-nun-face:before {
	content: "\f9dd";
}
.game-icon-nunchaku:before {
	content: "\f9de";
}
.game-icon-nurse-female:before {
	content: "\f9df";
}
.game-icon-nurse-male:before {
	content: "\f9e0";
}
.game-icon-o-brick:before {
	content: "\f9e1";
}
.game-icon-oak-leaf:before {
	content: "\f9e2";
}
.game-icon-oak:before {
	content: "\f9e3";
}
.game-icon-oasis:before {
	content: "\f9e4";
}
.game-icon-oat:before {
	content: "\f9e5";
}
.game-icon-obelisk:before {
	content: "\f9e6";
}
.game-icon-observatory:before {
	content: "\f9e7";
}
.game-icon-ocarina:before {
	content: "\f9e8";
}
.game-icon-occupy:before {
	content: "\f9e9";
}
.game-icon-octogonal-eye:before {
	content: "\f9ea";
}
.game-icon-octoman:before {
	content: "\f9eb";
}
.game-icon-octopus:before {
	content: "\f9ec";
}
.game-icon-oden:before {
	content: "\f9ed";
}
.game-icon-office-chair:before {
	content: "\f9ee";
}
.game-icon-offshore-platform:before {
	content: "\f9ef";
}
.game-icon-ogre:before {
	content: "\f9f0";
}
.game-icon-oil-drum:before {
	content: "\f9f1";
}
.game-icon-oil-pump:before {
	content: "\f9f2";
}
.game-icon-oil-rig:before {
	content: "\f9f3";
}
.game-icon-oily-spiral:before {
	content: "\f9f4";
}
.game-icon-old-king:before {
	content: "\f9f5";
}
.game-icon-old-lantern:before {
	content: "\f9f6";
}
.game-icon-old-microphone:before {
	content: "\f9f7";
}
.game-icon-old-wagon:before {
	content: "\f9f8";
}
.game-icon-olive:before {
	content: "\f9f9";
}
.game-icon-omega:before {
	content: "\f9fa";
}
.game-icon-on-sight:before {
	content: "\f9fb";
}
.game-icon-on-target:before {
	content: "\f9fc";
}
.game-icon-one-eyed:before {
	content: "\f9fd";
}
.game-icon-oni:before {
	content: "\f9fe";
}
.game-icon-onigori:before {
	content: "\f9ff";
}
.game-icon-open-book:before {
	content: "\fa00";
}
.game-icon-open-chest:before {
	content: "\fa01";
}
.game-icon-open-folder:before {
	content: "\fa02";
}
.game-icon-open-gate:before {
	content: "\fa03";
}
.game-icon-open-palm:before {
	content: "\fa04";
}
.game-icon-open-treasure-chest:before {
	content: "\fa05";
}
.game-icon-open-wound:before {
	content: "\fa06";
}
.game-icon-opened-food-can:before {
	content: "\fa07";
}
.game-icon-opening-shell:before {
	content: "\fa08";
}
.game-icon-ophiuchus:before {
	content: "\fa09";
}
.game-icon-oppidum:before {
	content: "\fa0a";
}
.game-icon-opposite-hearts:before {
	content: "\fa0b";
}
.game-icon-oppression:before {
	content: "\fa0c";
}
.game-icon-orange-slice:before {
	content: "\fa0d";
}
.game-icon-orange:before {
	content: "\fa0e";
}
.game-icon-orb-direction:before {
	content: "\fa0f";
}
.game-icon-orb-wand:before {
	content: "\fa10";
}
.game-icon-orbit:before {
	content: "\fa11";
}
.game-icon-orbital-rays:before {
	content: "\fa12";
}
.game-icon-orbital:before {
	content: "\fa13";
}
.game-icon-orc-head:before {
	content: "\fa14";
}
.game-icon-ore:before {
	content: "\fa15";
}
.game-icon-organigram:before {
	content: "\fa16";
}
.game-icon-ostrich:before {
	content: "\fa17";
}
.game-icon-ouroboros:before {
	content: "\fa18";
}
.game-icon-outback-hat:before {
	content: "\fa19";
}
.game-icon-over-infinity:before {
	content: "\fa1a";
}
.game-icon-overdose:before {
	content: "\fa1b";
}
.game-icon-overdrive:before {
	content: "\fa1c";
}
.game-icon-overhead:before {
	content: "\fa1d";
}
.game-icon-overkill:before {
	content: "\fa1e";
}
.game-icon-overlord-helm:before {
	content: "\fa1f";
}
.game-icon-overmind:before {
	content: "\fa20";
}
.game-icon-owl:before {
	content: "\fa21";
}
.game-icon-oyster-pearl:before {
	content: "\fa22";
}
.game-icon-oyster:before {
	content: "\fa23";
}
.game-icon-p90:before {
	content: "\fa24";
}
.game-icon-packed-planks:before {
	content: "\fa25";
}
.game-icon-paddle-steamer:before {
	content: "\fa26";
}
.game-icon-paddles:before {
	content: "\fa27";
}
.game-icon-padlock-open:before {
	content: "\fa28";
}
.game-icon-padlock:before {
	content: "\fa29";
}
.game-icon-pagoda:before {
	content: "\fa2a";
}
.game-icon-paint-brush:before {
	content: "\fa2b";
}
.game-icon-paint-bucket:before {
	content: "\fa2c";
}
.game-icon-paint-roller:before {
	content: "\fa2d";
}
.game-icon-painted-pottery:before {
	content: "\fa2e";
}
.game-icon-palette:before {
	content: "\fa2f";
}
.game-icon-palisade:before {
	content: "\fa30";
}
.game-icon-palm-tree:before {
	content: "\fa31";
}
.game-icon-palm:before {
	content: "\fa32";
}
.game-icon-pan-flute:before {
	content: "\fa33";
}
.game-icon-panda:before {
	content: "\fa34";
}
.game-icon-pangolin:before {
	content: "\fa35";
}
.game-icon-panzerfaust:before {
	content: "\fa36";
}
.game-icon-paper-arrow:before {
	content: "\fa37";
}
.game-icon-paper-bag-crumpled:before {
	content: "\fa38";
}
.game-icon-paper-bag-folded:before {
	content: "\fa39";
}
.game-icon-paper-bag-open:before {
	content: "\fa3a";
}
.game-icon-paper-boat:before {
	content: "\fa3b";
}
.game-icon-paper-bomb:before {
	content: "\fa3c";
}
.game-icon-paper-clip:before {
	content: "\fa3d";
}
.game-icon-paper-crane:before {
	content: "\fa3e";
}
.game-icon-paper-frog:before {
	content: "\fa3f";
}
.game-icon-paper-lantern:before {
	content: "\fa40";
}
.game-icon-paper-plane:before {
	content: "\fa41";
}
.game-icon-paper-tray:before {
	content: "\fa42";
}
.game-icon-paper-windmill:before {
	content: "\fa43";
}
.game-icon-paper:before {
	content: "\fa44";
}
.game-icon-papers:before {
	content: "\fa45";
}
.game-icon-papyrus:before {
	content: "\fa46";
}
.game-icon-parachute:before {
	content: "\fa47";
}
.game-icon-paraguay:before {
	content: "\fa48";
}
.game-icon-paranoia:before {
	content: "\fa49";
}
.game-icon-parasaurolophus:before {
	content: "\fa4a";
}
.game-icon-park-bench:before {
	content: "\fa4b";
}
.game-icon-parmecia:before {
	content: "\fa4c";
}
.game-icon-parrot-head:before {
	content: "\fa4d";
}
.game-icon-party-flags:before {
	content: "\fa4e";
}
.game-icon-party-hat:before {
	content: "\fa4f";
}
.game-icon-party-popper:before {
	content: "\fa50";
}
.game-icon-passport:before {
	content: "\fa51";
}
.game-icon-path-distance:before {
	content: "\fa52";
}
.game-icon-path-tile:before {
	content: "\fa53";
}
.game-icon-pauldrons:before {
	content: "\fa54";
}
.game-icon-pause-button:before {
	content: "\fa55";
}
.game-icon-paw-front:before {
	content: "\fa56";
}
.game-icon-paw-heart:before {
	content: "\fa57";
}
.game-icon-paw-print:before {
	content: "\fa58";
}
.game-icon-paw:before {
	content: "\fa59";
}
.game-icon-pawn:before {
	content: "\fa5a";
}
.game-icon-pawprint:before {
	content: "\fa5b";
}
.game-icon-pay-money:before {
	content: "\fa5c";
}
.game-icon-pc:before {
	content: "\fa5d";
}
.game-icon-peace-dove:before {
	content: "\fa5e";
}
.game-icon-peach:before {
	content: "\fa5f";
}
.game-icon-peaks:before {
	content: "\fa60";
}
.game-icon-peanut:before {
	content: "\fa61";
}
.game-icon-pear:before {
	content: "\fa62";
}
.game-icon-pearl-earring:before {
	content: "\fa63";
}
.game-icon-pearl-necklace:before {
	content: "\fa64";
}
.game-icon-peas:before {
	content: "\fa65";
}
.game-icon-pegasus:before {
	content: "\fa66";
}
.game-icon-pelvis-bone:before {
	content: "\fa67";
}
.game-icon-pencil-brush:before {
	content: "\fa68";
}
.game-icon-pencil-ruler:before {
	content: "\fa69";
}
.game-icon-pencil:before {
	content: "\fa6a";
}
.game-icon-pendant-key:before {
	content: "\fa6b";
}
.game-icon-pendulum-swing:before {
	content: "\fa6c";
}
.game-icon-penguin:before {
	content: "\fa6d";
}
.game-icon-pentacle:before {
	content: "\fa6e";
}
.game-icon-pentagram-rose:before {
	content: "\fa6f";
}
.game-icon-pentarrows-tornado:before {
	content: "\fa70";
}
.game-icon-perfume-bottle:before {
	content: "\fa71";
}
.game-icon-periscope:before {
	content: "\fa72";
}
.game-icon-perpendicular-rings:before {
	content: "\fa73";
}
.game-icon-person-in-bed:before {
	content: "\fa74";
}
.game-icon-person:before {
	content: "\fa75";
}
.game-icon-perspective-dice-five:before {
	content: "\fa76";
}
.game-icon-perspective-dice-four:before {
	content: "\fa77";
}
.game-icon-perspective-dice-one:before {
	content: "\fa78";
}
.game-icon-perspective-dice-six-faces-five:before {
	content: "\fa79";
}
.game-icon-perspective-dice-six-faces-four:before {
	content: "\fa7a";
}
.game-icon-perspective-dice-six-faces-one:before {
	content: "\fa7b";
}
.game-icon-perspective-dice-six-faces-random:before {
	content: "\fa7c";
}
.game-icon-perspective-dice-six-faces-six:before {
	content: "\fa7d";
}
.game-icon-perspective-dice-six-faces-three:before {
	content: "\fa7e";
}
.game-icon-perspective-dice-six-faces-two:before {
	content: "\fa7f";
}
.game-icon-perspective-dice-six:before {
	content: "\fa80";
}
.game-icon-perspective-dice-three:before {
	content: "\fa81";
}
.game-icon-perspective-dice-two:before {
	content: "\fa82";
}
.game-icon-peru:before {
	content: "\fa83";
}
.game-icon-pestle-mortar:before {
	content: "\fa84";
}
.game-icon-pharoah:before {
	content: "\fa85";
}
.game-icon-philosopher-bust:before {
	content: "\fa86";
}
.game-icon-phone:before {
	content: "\fa87";
}
.game-icon-photo-camera:before {
	content: "\fa88";
}
.game-icon-phrygian-cap:before {
	content: "\fa89";
}
.game-icon-pianist:before {
	content: "\fa8a";
}
.game-icon-piano-keys:before {
	content: "\fa8b";
}
.game-icon-pick-of-destiny:before {
	content: "\fa8c";
}
.game-icon-pickelhaube:before {
	content: "\fa8d";
}
.game-icon-pickle:before {
	content: "\fa8e";
}
.game-icon-pie-chart:before {
	content: "\fa8f";
}
.game-icon-pie-slice:before {
	content: "\fa90";
}
.game-icon-piece-skull:before {
	content: "\fa91";
}
.game-icon-pierced-body:before {
	content: "\fa92";
}
.game-icon-pierced-heart:before {
	content: "\fa93";
}
.game-icon-piercing-sword:before {
	content: "\fa94";
}
.game-icon-pig-face:before {
	content: "\fa95";
}
.game-icon-pig:before {
	content: "\fa96";
}
.game-icon-piggy-bank:before {
	content: "\fa97";
}
.game-icon-pikeman:before {
	content: "\fa98";
}
.game-icon-pilgrim-hat:before {
	content: "\fa99";
}
.game-icon-pill-drop:before {
	content: "\fa9a";
}
.game-icon-pill:before {
	content: "\fa9b";
}
.game-icon-pillow:before {
	content: "\fa9c";
}
.game-icon-pimiento:before {
	content: "\fa9d";
}
.game-icon-pin:before {
	content: "\fa9e";
}
.game-icon-pinata:before {
	content: "\fa9f";
}
.game-icon-pinball-flipper:before {
	content: "\faa0";
}
.game-icon-pincers:before {
	content: "\faa1";
}
.game-icon-pine-tree:before {
	content: "\faa2";
}
.game-icon-pineapple:before {
	content: "\faa3";
}
.game-icon-ping-pong-bat:before {
	content: "\faa4";
}
.game-icon-pipe-organ:before {
	content: "\faa5";
}
.game-icon-pipes:before {
	content: "\faa6";
}
.game-icon-piranha:before {
	content: "\faa7";
}
.game-icon-pirate-cannon:before {
	content: "\faa8";
}
.game-icon-pirate-captain:before {
	content: "\faa9";
}
.game-icon-pirate-coat:before {
	content: "\faaa";
}
.game-icon-pirate-flag:before {
	content: "\faab";
}
.game-icon-pirate-grave:before {
	content: "\faac";
}
.game-icon-pirate-hat:before {
	content: "\faad";
}
.game-icon-pirate-hook:before {
	content: "\faae";
}
.game-icon-pirate-skull:before {
	content: "\faaf";
}
.game-icon-pisa-tower:before {
	content: "\fab0";
}
.game-icon-pisces:before {
	content: "\fab1";
}
.game-icon-pistol-gun:before {
	content: "\fab2";
}
.game-icon-pitchfork:before {
	content: "\fab3";
}
.game-icon-pizza-cutter:before {
	content: "\fab4";
}
.game-icon-pizza-slice:before {
	content: "\fab5";
}
.game-icon-plague-doctor-profile:before {
	content: "\fab6";
}
.game-icon-plain-arrow:before {
	content: "\fab7";
}
.game-icon-plain-circle:before {
	content: "\fab8";
}
.game-icon-plain-dagger:before {
	content: "\fab9";
}
.game-icon-plain-square:before {
	content: "\faba";
}
.game-icon-plane-pilot:before {
	content: "\fabb";
}
.game-icon-plane-wing:before {
	content: "\fabc";
}
.game-icon-planet-conquest:before {
	content: "\fabd";
}
.game-icon-planet-core:before {
	content: "\fabe";
}
.game-icon-planks:before {
	content: "\fabf";
}
.game-icon-plant-roots:before {
	content: "\fac0";
}
.game-icon-plant-seed:before {
	content: "\fac1";
}
.game-icon-plant-watering:before {
	content: "\fac2";
}
.game-icon-plants-and-animals:before {
	content: "\fac3";
}
.game-icon-plasma-bolt:before {
	content: "\fac4";
}
.game-icon-plastic-duck:before {
	content: "\fac5";
}
.game-icon-plastron:before {
	content: "\fac6";
}
.game-icon-plate-claw:before {
	content: "\fac7";
}
.game-icon-platform:before {
	content: "\fac8";
}
.game-icon-play-button:before {
	content: "\fac9";
}
.game-icon-player-base:before {
	content: "\faca";
}
.game-icon-player-next:before {
	content: "\facb";
}
.game-icon-player-previous:before {
	content: "\facc";
}
.game-icon-player-time:before {
	content: "\facd";
}
.game-icon-plesiosaurus:before {
	content: "\face";
}
.game-icon-plow:before {
	content: "\facf";
}
.game-icon-plug:before {
	content: "\fad0";
}
.game-icon-plunger:before {
	content: "\fad1";
}
.game-icon-pocket-bow:before {
	content: "\fad2";
}
.game-icon-pocket-radio:before {
	content: "\fad3";
}
.game-icon-pocket-watch:before {
	content: "\fad4";
}
.game-icon-podium-second:before {
	content: "\fad5";
}
.game-icon-podium-third:before {
	content: "\fad6";
}
.game-icon-podium-winner:before {
	content: "\fad7";
}
.game-icon-podium:before {
	content: "\fad8";
}
.game-icon-pointing:before {
	content: "\fad9";
}
.game-icon-pointy-hat:before {
	content: "\fada";
}
.game-icon-pointy-sword:before {
	content: "\fadb";
}
.game-icon-poison-bottle:before {
	content: "\fadc";
}
.game-icon-poison-cloud:before {
	content: "\fadd";
}
.game-icon-poison-gas:before {
	content: "\fade";
}
.game-icon-poison:before {
	content: "\fadf";
}
.game-icon-pokecog:before {
	content: "\fae0";
}
.game-icon-poker-hand:before {
	content: "\fae1";
}
.game-icon-poland:before {
	content: "\fae2";
}
.game-icon-polar-bear:before {
	content: "\fae3";
}
.game-icon-polar-star:before {
	content: "\fae4";
}
.game-icon-police-badge:before {
	content: "\fae5";
}
.game-icon-police-car:before {
	content: "\fae6";
}
.game-icon-police-officer-head:before {
	content: "\fae7";
}
.game-icon-police-target:before {
	content: "\fae8";
}
.game-icon-pollen-dust:before {
	content: "\fae9";
}
.game-icon-polo-shirt:before {
	content: "\faea";
}
.game-icon-poncho:before {
	content: "\faeb";
}
.game-icon-pool-dive:before {
	content: "\faec";
}
.game-icon-pool-table-corner:before {
	content: "\faed";
}
.game-icon-pool-triangle:before {
	content: "\faee";
}
.game-icon-popcorn:before {
	content: "\faef";
}
.game-icon-pope-crown:before {
	content: "\faf0";
}
.game-icon-poppy:before {
	content: "\faf1";
}
.game-icon-porcelain-vase:before {
	content: "\faf2";
}
.game-icon-porcupine:before {
	content: "\faf3";
}
.game-icon-porcupinefish:before {
	content: "\faf4";
}
.game-icon-portal:before {
	content: "\faf5";
}
.game-icon-portculis:before {
	content: "\faf6";
}
.game-icon-portrait:before {
	content: "\faf7";
}
.game-icon-portugal:before {
	content: "\faf8";
}
.game-icon-position-marker:before {
	content: "\faf9";
}
.game-icon-post-office:before {
	content: "\fafa";
}
.game-icon-post-stamp:before {
	content: "\fafb";
}
.game-icon-potato:before {
	content: "\fafc";
}
.game-icon-potion-ball:before {
	content: "\fafd";
}
.game-icon-potion-of-madness:before {
	content: "\fafe";
}
.game-icon-pounce:before {
	content: "\faff";
}
.game-icon-pouring-chalice:before {
	content: "\fb00";
}
.game-icon-pouring-pot:before {
	content: "\fb01";
}
.game-icon-powder-bag:before {
	content: "\fb02";
}
.game-icon-powder:before {
	content: "\fb03";
}
.game-icon-power-button:before {
	content: "\fb04";
}
.game-icon-power-generator:before {
	content: "\fb05";
}
.game-icon-power-lightning:before {
	content: "\fb06";
}
.game-icon-power-ring:before {
	content: "\fb07";
}
.game-icon-prayer-beads:before {
	content: "\fb08";
}
.game-icon-prayer:before {
	content: "\fb09";
}
.game-icon-praying-mantis:before {
	content: "\fb0a";
}
.game-icon-present:before {
	content: "\fb0b";
}
.game-icon-pressure-cooker:before {
	content: "\fb0c";
}
.game-icon-pretty-fangs:before {
	content: "\fb0d";
}
.game-icon-pretzel:before {
	content: "\fb0e";
}
.game-icon-previous-button:before {
	content: "\fb0f";
}
.game-icon-price-tag:before {
	content: "\fb10";
}
.game-icon-primitive-necklace:before {
	content: "\fb11";
}
.game-icon-primitive-torch:before {
	content: "\fb12";
}
.game-icon-prism:before {
	content: "\fb13";
}
.game-icon-prisoner:before {
	content: "\fb14";
}
.game-icon-private-first-class:before {
	content: "\fb15";
}
.game-icon-private:before {
	content: "\fb16";
}
.game-icon-processor:before {
	content: "\fb17";
}
.game-icon-profit:before {
	content: "\fb18";
}
.game-icon-progression:before {
	content: "\fb19";
}
.game-icon-propeller-beanie:before {
	content: "\fb1a";
}
.game-icon-protection-glasses:before {
	content: "\fb1b";
}
.game-icon-pschent-double-crown:before {
	content: "\fb1c";
}
.game-icon-psychic-waves:before {
	content: "\fb1d";
}
.game-icon-pterodactylus:before {
	content: "\fb1e";
}
.game-icon-pteruges:before {
	content: "\fb1f";
}
.game-icon-public-speaker:before {
	content: "\fb20";
}
.game-icon-pull:before {
	content: "\fb21";
}
.game-icon-pulley-hook:before {
	content: "\fb22";
}
.game-icon-pulse:before {
	content: "\fb23";
}
.game-icon-pummeled:before {
	content: "\fb24";
}
.game-icon-pumpkin-lantern:before {
	content: "\fb25";
}
.game-icon-pumpkin-mask:before {
	content: "\fb26";
}
.game-icon-pumpkin:before {
	content: "\fb27";
}
.game-icon-punch-blast:before {
	content: "\fb28";
}
.game-icon-punch:before {
	content: "\fb29";
}
.game-icon-punching-bag:before {
	content: "\fb2a";
}
.game-icon-puppet:before {
	content: "\fb2b";
}
.game-icon-purple-tentacle:before {
	content: "\fb2c";
}
.game-icon-push:before {
	content: "\fb2d";
}
.game-icon-puzzle:before {
	content: "\fb2e";
}
.game-icon-pylon:before {
	content: "\fb2f";
}
.game-icon-pyre:before {
	content: "\fb30";
}
.game-icon-pyromaniac:before {
	content: "\fb31";
}
.game-icon-qaitbay-citadel:before {
	content: "\fb32";
}
.game-icon-quake-stomp:before {
	content: "\fb33";
}
.game-icon-queen-crown:before {
	content: "\fb34";
}
.game-icon-quick-man:before {
	content: "\fb35";
}
.game-icon-quick-slash:before {
	content: "\fb36";
}
.game-icon-quicksand:before {
	content: "\fb37";
}
.game-icon-quill-ink:before {
	content: "\fb38";
}
.game-icon-quill:before {
	content: "\fb39";
}
.game-icon-quiver:before {
	content: "\fb3a";
}
.game-icon-rabbit-head:before {
	content: "\fb3b";
}
.game-icon-rabbit:before {
	content: "\fb3c";
}
.game-icon-raccoon-head:before {
	content: "\fb3d";
}
.game-icon-race-car:before {
	content: "\fb3e";
}
.game-icon-radar-cross-section:before {
	content: "\fb3f";
}
.game-icon-radar-dish:before {
	content: "\fb40";
}
.game-icon-radar-sweep:before {
	content: "\fb41";
}
.game-icon-raddish:before {
	content: "\fb42";
}
.game-icon-radial-balance:before {
	content: "\fb43";
}
.game-icon-radiations:before {
	content: "\fb44";
}
.game-icon-radio-tower:before {
	content: "\fb45";
}
.game-icon-radioactive:before {
	content: "\fb46";
}
.game-icon-raft:before {
	content: "\fb47";
}
.game-icon-ragged-wound:before {
	content: "\fb48";
}
.game-icon-rail-road:before {
	content: "\fb49";
}
.game-icon-railway:before {
	content: "\fb4a";
}
.game-icon-rainbow-star:before {
	content: "\fb4b";
}
.game-icon-raining:before {
	content: "\fb4c";
}
.game-icon-raise-skeleton:before {
	content: "\fb4d";
}
.game-icon-raise-zombie:before {
	content: "\fb4e";
}
.game-icon-rake:before {
	content: "\fb4f";
}
.game-icon-rally-the-troops:before {
	content: "\fb50";
}
.game-icon-ram-2:before {
	content: "\fb51";
}
.game-icon-ram-profile:before {
	content: "\fb52";
}
.game-icon-ram:before {
	content: "\fb53";
}
.game-icon-ranch-gate:before {
	content: "\fb54";
}
.game-icon-rank-1:before {
	content: "\fb55";
}
.game-icon-rank-2:before {
	content: "\fb56";
}
.game-icon-rank-3:before {
	content: "\fb57";
}
.game-icon-rapidshare-arrow:before {
	content: "\fb58";
}
.game-icon-raspberry:before {
	content: "\fb59";
}
.game-icon-rat:before {
	content: "\fb5a";
}
.game-icon-rattlesnake:before {
	content: "\fb5b";
}
.game-icon-raven:before {
	content: "\fb5c";
}
.game-icon-raw-egg:before {
	content: "\fb5d";
}
.game-icon-ray-gun:before {
	content: "\fb5e";
}
.game-icon-razor-blade:before {
	content: "\fb5f";
}
.game-icon-razor:before {
	content: "\fb60";
}
.game-icon-reactor:before {
	content: "\fb61";
}
.game-icon-read:before {
	content: "\fb62";
}
.game-icon-reaper-scythe:before {
	content: "\fb63";
}
.game-icon-rear-aura:before {
	content: "\fb64";
}
.game-icon-receive-money:before {
	content: "\fb65";
}
.game-icon-recycle:before {
	content: "\fb66";
}
.game-icon-red-carpet:before {
	content: "\fb67";
}
.game-icon-reed:before {
	content: "\fb68";
}
.game-icon-refinery:before {
	content: "\fb69";
}
.game-icon-regeneration:before {
	content: "\fb6a";
}
.game-icon-relationship-bounds:before {
	content: "\fb6b";
}
.game-icon-relic-blade:before {
	content: "\fb6c";
}
.game-icon-reload-gun-barrel:before {
	content: "\fb6d";
}
.game-icon-remedy:before {
	content: "\fb6e";
}
.game-icon-rempart:before {
	content: "\fb6f";
}
.game-icon-reptile-tail:before {
	content: "\fb70";
}
.game-icon-resize:before {
	content: "\fb71";
}
.game-icon-resonance:before {
	content: "\fb72";
}
.game-icon-resting-vampire:before {
	content: "\fb73";
}
.game-icon-reticule:before {
	content: "\fb74";
}
.game-icon-retro-controller:before {
	content: "\fb75";
}
.game-icon-return-arrow:before {
	content: "\fb76";
}
.game-icon-revolt-2:before {
	content: "\fb77";
}
.game-icon-revolt:before {
	content: "\fb78";
}
.game-icon-revolver-2:before {
	content: "\fb79";
}
.game-icon-revolver:before {
	content: "\fb7a";
}
.game-icon-rhinoceros-horn:before {
	content: "\fb7b";
}
.game-icon-rialto-bridge:before {
	content: "\fb7c";
}
.game-icon-ribbon-medal:before {
	content: "\fb7d";
}
.game-icon-ribbon-shield:before {
	content: "\fb7e";
}
.game-icon-ribbon:before {
	content: "\fb7f";
}
.game-icon-ribcage:before {
	content: "\fb80";
}
.game-icon-rice-cooker:before {
	content: "\fb81";
}
.game-icon-rifle:before {
	content: "\fb82";
}
.game-icon-ring-box:before {
	content: "\fb83";
}
.game-icon-ring-mould:before {
	content: "\fb84";
}
.game-icon-ring:before {
	content: "\fb85";
}
.game-icon-ringed-beam:before {
	content: "\fb86";
}
.game-icon-ringed-planet:before {
	content: "\fb87";
}
.game-icon-ringing-alarm:before {
	content: "\fb88";
}
.game-icon-ringing-bell:before {
	content: "\fb89";
}
.game-icon-ringmaster:before {
	content: "\fb8a";
}
.game-icon-riot-shield:before {
	content: "\fb8b";
}
.game-icon-riposte:before {
	content: "\fb8c";
}
.game-icon-river:before {
	content: "\fb8d";
}
.game-icon-road:before {
	content: "\fb8e";
}
.game-icon-roast-chicken:before {
	content: "\fb8f";
}
.game-icon-robber-hand:before {
	content: "\fb90";
}
.game-icon-robber-mask:before {
	content: "\fb91";
}
.game-icon-robber:before {
	content: "\fb92";
}
.game-icon-robe:before {
	content: "\fb93";
}
.game-icon-robin-hood-hat:before {
	content: "\fb94";
}
.game-icon-robot-antennas:before {
	content: "\fb95";
}
.game-icon-robot-golem:before {
	content: "\fb96";
}
.game-icon-robot-grab:before {
	content: "\fb97";
}
.game-icon-robot-helmet:before {
	content: "\fb98";
}
.game-icon-robot-leg:before {
	content: "\fb99";
}
.game-icon-rock-2:before {
	content: "\fb9a";
}
.game-icon-rock-golem:before {
	content: "\fb9b";
}
.game-icon-rock:before {
	content: "\fb9c";
}
.game-icon-rocket-flight:before {
	content: "\fb9d";
}
.game-icon-rocket-thruster:before {
	content: "\fb9e";
}
.game-icon-rocket:before {
	content: "\fb9f";
}
.game-icon-rocking-chair:before {
	content: "\fba0";
}
.game-icon-rod-of-asclepius:before {
	content: "\fba1";
}
.game-icon-rogue:before {
	content: "\fba2";
}
.game-icon-rolled-cloth:before {
	content: "\fba3";
}
.game-icon-roller-skate:before {
	content: "\fba4";
}
.game-icon-rolling-bomb:before {
	content: "\fba5";
}
.game-icon-rolling-dice-cup:before {
	content: "\fba6";
}
.game-icon-rolling-dices:before {
	content: "\fba7";
}
.game-icon-rolling-energy:before {
	content: "\fba8";
}
.game-icon-rolling-suitcase:before {
	content: "\fba9";
}
.game-icon-roman-shield:before {
	content: "\fbaa";
}
.game-icon-roman-toga:before {
	content: "\fbab";
}
.game-icon-rooster:before {
	content: "\fbac";
}
.game-icon-root-tip:before {
	content: "\fbad";
}
.game-icon-rope-bridge:before {
	content: "\fbae";
}
.game-icon-rope-coil:before {
	content: "\fbaf";
}
.game-icon-rope-dart:before {
	content: "\fbb0";
}
.game-icon-ropeway:before {
	content: "\fbb1";
}
.game-icon-rosa-shield:before {
	content: "\fbb2";
}
.game-icon-rose:before {
	content: "\fbb3";
}
.game-icon-rotary-phone:before {
	content: "\fbb4";
}
.game-icon-rough-wound:before {
	content: "\fbb5";
}
.game-icon-round-bottom-flask:before {
	content: "\fbb6";
}
.game-icon-round-knob:before {
	content: "\fbb7";
}
.game-icon-round-shield:before {
	content: "\fbb8";
}
.game-icon-round-silo:before {
	content: "\fbb9";
}
.game-icon-round-star:before {
	content: "\fbba";
}
.game-icon-round-straw-bale:before {
	content: "\fbbb";
}
.game-icon-round-struck:before {
	content: "\fbbc";
}
.game-icon-round-table:before {
	content: "\fbbd";
}
.game-icon-royal-love:before {
	content: "\fbbe";
}
.game-icon-rss-2:before {
	content: "\fbbf";
}
.game-icon-rss:before {
	content: "\fbc0";
}
.game-icon-rub-el-hizb:before {
	content: "\fbc1";
}
.game-icon-rubber-boot:before {
	content: "\fbc2";
}
.game-icon-rugby-conversion:before {
	content: "\fbc3";
}
.game-icon-rule-book:before {
	content: "\fbc4";
}
.game-icon-run:before {
	content: "\fbc5";
}
.game-icon-rune-stone:before {
	content: "\fbc6";
}
.game-icon-rune-sword:before {
	content: "\fbc7";
}
.game-icon-running-ninja:before {
	content: "\fbc8";
}
.game-icon-running-shoe:before {
	content: "\fbc9";
}
.game-icon-rupee:before {
	content: "\fbca";
}
.game-icon-rusty-sword:before {
	content: "\fbcb";
}
.game-icon-s-brick:before {
	content: "\fbcc";
}
.game-icon-saber-and-pistol:before {
	content: "\fbcd";
}
.game-icon-saber-slash:before {
	content: "\fbce";
}
.game-icon-saber-tooth:before {
	content: "\fbcf";
}
.game-icon-saber-toothed-cat-head:before {
	content: "\fbd0";
}
.game-icon-sabers-choc:before {
	content: "\fbd1";
}
.game-icon-sacrificial-dagger:before {
	content: "\fbd2";
}
.game-icon-sad-crab:before {
	content: "\fbd3";
}
.game-icon-saddle:before {
	content: "\fbd4";
}
.game-icon-safety-pin:before {
	content: "\fbd5";
}
.game-icon-sagittarius:before {
	content: "\fbd6";
}
.game-icon-sai:before {
	content: "\fbd7";
}
.game-icon-sail:before {
	content: "\fbd8";
}
.game-icon-sailboat:before {
	content: "\fbd9";
}
.game-icon-saint-basil-cathedral:before {
	content: "\fbda";
}
.game-icon-saiyan-suit:before {
	content: "\fbdb";
}
.game-icon-salamander:before {
	content: "\fbdc";
}
.game-icon-salmon:before {
	content: "\fbdd";
}
.game-icon-saloon-doors:before {
	content: "\fbde";
}
.game-icon-saloon:before {
	content: "\fbdf";
}
.game-icon-salt-shaker:before {
	content: "\fbe0";
}
.game-icon-samara-mosque:before {
	content: "\fbe1";
}
.game-icon-samurai-helmet:before {
	content: "\fbe2";
}
.game-icon-samus-helmet:before {
	content: "\fbe3";
}
.game-icon-sand-castle:before {
	content: "\fbe4";
}
.game-icon-sand-snake:before {
	content: "\fbe5";
}
.game-icon-sandal:before {
	content: "\fbe6";
}
.game-icon-sands-of-time:before {
	content: "\fbe7";
}
.game-icon-sandstorm:before {
	content: "\fbe8";
}
.game-icon-sandwich:before {
	content: "\fbe9";
}
.game-icon-santa-hat:before {
	content: "\fbea";
}
.game-icon-saphir:before {
	content: "\fbeb";
}
.game-icon-sarcophagus:before {
	content: "\fbec";
}
.game-icon-sasquatch:before {
	content: "\fbed";
}
.game-icon-satellite-communication:before {
	content: "\fbee";
}
.game-icon-sattelite:before {
	content: "\fbef";
}
.game-icon-saucepan:before {
	content: "\fbf0";
}
.game-icon-sauropod-head:before {
	content: "\fbf1";
}
.game-icon-sauropod-skeleton:before {
	content: "\fbf2";
}
.game-icon-sausage:before {
	content: "\fbf3";
}
.game-icon-sausages-ribbon:before {
	content: "\fbf4";
}
.game-icon-save-arrow:before {
	content: "\fbf5";
}
.game-icon-save:before {
	content: "\fbf6";
}
.game-icon-saw-claw:before {
	content: "\fbf7";
}
.game-icon-sawed-off-shotgun:before {
	content: "\fbf8";
}
.game-icon-saxophone:before {
	content: "\fbf9";
}
.game-icon-scabbard:before {
	content: "\fbfa";
}
.game-icon-scale-mail:before {
	content: "\fbfb";
}
.game-icon-scales:before {
	content: "\fbfc";
}
.game-icon-scallop:before {
	content: "\fbfd";
}
.game-icon-scalpel-strike:before {
	content: "\fbfe";
}
.game-icon-scalpel:before {
	content: "\fbff";
}
.game-icon-scar-wound:before {
	content: "\fc00";
}
.game-icon-scarab-beetle:before {
	content: "\fc01";
}
.game-icon-scarecrow:before {
	content: "\fc02";
}
.game-icon-school-bag:before {
	content: "\fc03";
}
.game-icon-school-of-fish:before {
	content: "\fc04";
}
.game-icon-scissors-2:before {
	content: "\fc05";
}
.game-icon-scissors:before {
	content: "\fc06";
}
.game-icon-scooter:before {
	content: "\fc07";
}
.game-icon-scorpio:before {
	content: "\fc08";
}
.game-icon-scorpion-tail:before {
	content: "\fc09";
}
.game-icon-scorpion:before {
	content: "\fc0a";
}
.game-icon-scout-ship:before {
	content: "\fc0b";
}
.game-icon-screaming:before {
	content: "\fc0c";
}
.game-icon-screen-impact:before {
	content: "\fc0d";
}
.game-icon-screw-2:before {
	content: "\fc0e";
}
.game-icon-screw:before {
	content: "\fc0f";
}
.game-icon-screwdriver:before {
	content: "\fc10";
}
.game-icon-scroll-quill:before {
	content: "\fc11";
}
.game-icon-scroll-unfurled:before {
	content: "\fc12";
}
.game-icon-scuba-mask:before {
	content: "\fc13";
}
.game-icon-scuba-tanks:before {
	content: "\fc14";
}
.game-icon-scythe:before {
	content: "\fc15";
}
.game-icon-sea-cliff:before {
	content: "\fc16";
}
.game-icon-sea-creature:before {
	content: "\fc17";
}
.game-icon-sea-dragon:before {
	content: "\fc18";
}
.game-icon-sea-serpent:before {
	content: "\fc19";
}
.game-icon-sea-star:before {
	content: "\fc1a";
}
.game-icon-sea-turtle:before {
	content: "\fc1b";
}
.game-icon-seagull:before {
	content: "\fc1c";
}
.game-icon-seahorse:before {
	content: "\fc1d";
}
.game-icon-seated-mouse:before {
	content: "\fc1e";
}
.game-icon-secret-book:before {
	content: "\fc1f";
}
.game-icon-secret-door:before {
	content: "\fc20";
}
.game-icon-security-gate:before {
	content: "\fc21";
}
.game-icon-seedling:before {
	content: "\fc22";
}
.game-icon-select:before {
	content: "\fc23";
}
.game-icon-self-love:before {
	content: "\fc24";
}
.game-icon-sell-card:before {
	content: "\fc25";
}
.game-icon-semi-closed-eye:before {
	content: "\fc26";
}
.game-icon-sensuousness:before {
	content: "\fc27";
}
.game-icon-sentry-gun:before {
	content: "\fc28";
}
.game-icon-sergeant:before {
	content: "\fc29";
}
.game-icon-serrated-slash:before {
	content: "\fc2a";
}
.game-icon-server-rack:before {
	content: "\fc2b";
}
.game-icon-sesame:before {
	content: "\fc2c";
}
.game-icon-settings-knobs:before {
	content: "\fc2d";
}
.game-icon-seven-pointed-star:before {
	content: "\fc2e";
}
.game-icon-severed-hand:before {
	content: "\fc2f";
}
.game-icon-sewed-shell:before {
	content: "\fc30";
}
.game-icon-sewing-machine:before {
	content: "\fc31";
}
.game-icon-sewing-needle:before {
	content: "\fc32";
}
.game-icon-sewing-string:before {
	content: "\fc33";
}
.game-icon-sextant:before {
	content: "\fc34";
}
.game-icon-shadow-follower:before {
	content: "\fc35";
}
.game-icon-shadow-grasp:before {
	content: "\fc36";
}
.game-icon-shaking-hands:before {
	content: "\fc37";
}
.game-icon-shambling-mound:before {
	content: "\fc38";
}
.game-icon-shambling-zombie:before {
	content: "\fc39";
}
.game-icon-shamrock:before {
	content: "\fc3a";
}
.game-icon-shard-sword:before {
	content: "\fc3b";
}
.game-icon-share:before {
	content: "\fc3c";
}
.game-icon-shark-bite:before {
	content: "\fc3d";
}
.game-icon-shark-fin:before {
	content: "\fc3e";
}
.game-icon-shark-jaws:before {
	content: "\fc3f";
}
.game-icon-sharp-axe:before {
	content: "\fc40";
}
.game-icon-sharp-crown:before {
	content: "\fc41";
}
.game-icon-sharp-halberd:before {
	content: "\fc42";
}
.game-icon-sharp-lips:before {
	content: "\fc43";
}
.game-icon-sharp-shuriken:before {
	content: "\fc44";
}
.game-icon-sharp-smile:before {
	content: "\fc45";
}
.game-icon-sharped-teeth-skull:before {
	content: "\fc46";
}
.game-icon-shatter:before {
	content: "\fc47";
}
.game-icon-shattered-glass:before {
	content: "\fc48";
}
.game-icon-shattered-heart:before {
	content: "\fc49";
}
.game-icon-shattered-sword:before {
	content: "\fc4a";
}
.game-icon-shears:before {
	content: "\fc4b";
}
.game-icon-sheep:before {
	content: "\fc4c";
}
.game-icon-sheikah-eye:before {
	content: "\fc4d";
}
.game-icon-shepherds-crook:before {
	content: "\fc4e";
}
.game-icon-sherlock-holmes:before {
	content: "\fc4f";
}
.game-icon-shield-bash:before {
	content: "\fc50";
}
.game-icon-shield-bounces:before {
	content: "\fc51";
}
.game-icon-shield-disabled:before {
	content: "\fc52";
}
.game-icon-shield-echoes:before {
	content: "\fc53";
}
.game-icon-shield-impact:before {
	content: "\fc54";
}
.game-icon-shield-opposition:before {
	content: "\fc55";
}
.game-icon-shield-reflect:before {
	content: "\fc56";
}
.game-icon-shield:before {
	content: "\fc57";
}
.game-icon-shieldcomb:before {
	content: "\fc58";
}
.game-icon-shining-claw:before {
	content: "\fc59";
}
.game-icon-shining-heart:before {
	content: "\fc5a";
}
.game-icon-shining-sword:before {
	content: "\fc5b";
}
.game-icon-shinto-shrine-mirror:before {
	content: "\fc5c";
}
.game-icon-shinto-shrine:before {
	content: "\fc5d";
}
.game-icon-shiny-apple:before {
	content: "\fc5e";
}
.game-icon-shiny-entrance:before {
	content: "\fc5f";
}
.game-icon-shiny-iris:before {
	content: "\fc60";
}
.game-icon-shiny-omega:before {
	content: "\fc61";
}
.game-icon-shiny-purse:before {
	content: "\fc62";
}
.game-icon-ship-bow:before {
	content: "\fc63";
}
.game-icon-ship-wheel:before {
	content: "\fc64";
}
.game-icon-ship-wreck:before {
	content: "\fc65";
}
.game-icon-shirt-button:before {
	content: "\fc66";
}
.game-icon-shirt:before {
	content: "\fc67";
}
.game-icon-shoebill-stork:before {
	content: "\fc68";
}
.game-icon-shooner-sailboat:before {
	content: "\fc69";
}
.game-icon-shop:before {
	content: "\fc6a";
}
.game-icon-shopping-bag:before {
	content: "\fc6b";
}
.game-icon-shopping-cart:before {
	content: "\fc6c";
}
.game-icon-shorts:before {
	content: "\fc6d";
}
.game-icon-shotgun-rounds:before {
	content: "\fc6e";
}
.game-icon-shotgun:before {
	content: "\fc6f";
}
.game-icon-shoulder-armor:before {
	content: "\fc70";
}
.game-icon-shoulder-bag:before {
	content: "\fc71";
}
.game-icon-shoulder-scales:before {
	content: "\fc72";
}
.game-icon-shouting:before {
	content: "\fc73";
}
.game-icon-shower:before {
	content: "\fc74";
}
.game-icon-shrimp:before {
	content: "\fc75";
}
.game-icon-shrug:before {
	content: "\fc76";
}
.game-icon-shuriken-2:before {
	content: "\fc77";
}
.game-icon-shuriken-aperture:before {
	content: "\fc78";
}
.game-icon-shuriken:before {
	content: "\fc79";
}
.game-icon-shut-rose:before {
	content: "\fc7a";
}
.game-icon-shuttlecock:before {
	content: "\fc7b";
}
.game-icon-sickle:before {
	content: "\fc7c";
}
.game-icon-sideswipe:before {
	content: "\fc7d";
}
.game-icon-siege-ram:before {
	content: "\fc7e";
}
.game-icon-siege-tower:before {
	content: "\fc7f";
}
.game-icon-sight-disabled:before {
	content: "\fc80";
}
.game-icon-silence:before {
	content: "\fc81";
}
.game-icon-silenced:before {
	content: "\fc82";
}
.game-icon-silex:before {
	content: "\fc83";
}
.game-icon-silver-bullet:before {
	content: "\fc84";
}
.game-icon-sinagot:before {
	content: "\fc85";
}
.game-icon-sing:before {
	content: "\fc86";
}
.game-icon-sinking-ship:before {
	content: "\fc87";
}
.game-icon-sinking-trap:before {
	content: "\fc88";
}
.game-icon-sinusoidal-beam:before {
	content: "\fc89";
}
.game-icon-siren:before {
	content: "\fc8a";
}
.game-icon-sitting-dog:before {
	content: "\fc8b";
}
.game-icon-six-eyes:before {
	content: "\fc8c";
}
.game-icon-skateboard:before {
	content: "\fc8d";
}
.game-icon-skeletal-hand:before {
	content: "\fc8e";
}
.game-icon-skeleton-inside:before {
	content: "\fc8f";
}
.game-icon-skeleton-key:before {
	content: "\fc90";
}
.game-icon-skeleton:before {
	content: "\fc91";
}
.game-icon-ski-boot:before {
	content: "\fc92";
}
.game-icon-skid-mark:before {
	content: "\fc93";
}
.game-icon-skier:before {
	content: "\fc94";
}
.game-icon-skills:before {
	content: "\fc95";
}
.game-icon-skipping-rope:before {
	content: "\fc96";
}
.game-icon-skirt:before {
	content: "\fc97";
}
.game-icon-skis:before {
	content: "\fc98";
}
.game-icon-skull-bolt:before {
	content: "\fc99";
}
.game-icon-skull-crack:before {
	content: "\fc9a";
}
.game-icon-skull-crossed-bones:before {
	content: "\fc9b";
}
.game-icon-skull-in-jar:before {
	content: "\fc9c";
}
.game-icon-skull-mask:before {
	content: "\fc9d";
}
.game-icon-skull-ring:before {
	content: "\fc9e";
}
.game-icon-skull-sabertooth:before {
	content: "\fc9f";
}
.game-icon-skull-shield:before {
	content: "\fca0";
}
.game-icon-skull-signet:before {
	content: "\fca1";
}
.game-icon-skull-slices:before {
	content: "\fca2";
}
.game-icon-skull-staff:before {
	content: "\fca3";
}
.game-icon-skull-with-syringe:before {
	content: "\fca4";
}
.game-icon-slalom:before {
	content: "\fca5";
}
.game-icon-slap:before {
	content: "\fca6";
}
.game-icon-slashed-shield:before {
	content: "\fca7";
}
.game-icon-slavery-whip:before {
	content: "\fca8";
}
.game-icon-sleeping-bag:before {
	content: "\fca9";
}
.game-icon-sleepy:before {
	content: "\fcaa";
}
.game-icon-sleeveless-jacket:before {
	content: "\fcab";
}
.game-icon-sleeveless-top:before {
	content: "\fcac";
}
.game-icon-sliced-bread:before {
	content: "\fcad";
}
.game-icon-sliced-mushroom:before {
	content: "\fcae";
}
.game-icon-sliced-sausage:before {
	content: "\fcaf";
}
.game-icon-slicing-arrow:before {
	content: "\fcb0";
}
.game-icon-slime:before {
	content: "\fcb1";
}
.game-icon-sling:before {
	content: "\fcb2";
}
.game-icon-slingshot:before {
	content: "\fcb3";
}
.game-icon-slipknot:before {
	content: "\fcb4";
}
.game-icon-slippers:before {
	content: "\fcb5";
}
.game-icon-slot-machine:before {
	content: "\fcb6";
}
.game-icon-sloth:before {
	content: "\fcb7";
}
.game-icon-slow-blob:before {
	content: "\fcb8";
}
.game-icon-slumbering-sanctuary:before {
	content: "\fcb9";
}
.game-icon-sly:before {
	content: "\fcba";
}
.game-icon-small-fire:before {
	content: "\fcbb";
}
.game-icon-small-fishing-sailboat:before {
	content: "\fcbc";
}
.game-icon-smart:before {
	content: "\fcbd";
}
.game-icon-smartphone-2:before {
	content: "\fcbe";
}
.game-icon-smartphone:before {
	content: "\fcbf";
}
.game-icon-smash-arrows:before {
	content: "\fcc0";
}
.game-icon-smitten:before {
	content: "\fcc1";
}
.game-icon-smoke-bomb:before {
	content: "\fcc2";
}
.game-icon-smoking-finger:before {
	content: "\fcc3";
}
.game-icon-smoking-orb:before {
	content: "\fcc4";
}
.game-icon-smoking-pipe:before {
	content: "\fcc5";
}
.game-icon-smoking-volcano:before {
	content: "\fcc6";
}
.game-icon-snail-eyes:before {
	content: "\fcc7";
}
.game-icon-snail:before {
	content: "\fcc8";
}
.game-icon-snake-bite:before {
	content: "\fcc9";
}
.game-icon-snake-egg:before {
	content: "\fcca";
}
.game-icon-snake-jar:before {
	content: "\fccb";
}
.game-icon-snake-spiral:before {
	content: "\fccc";
}
.game-icon-snake-tongue:before {
	content: "\fccd";
}
.game-icon-snake-totem:before {
	content: "\fcce";
}
.game-icon-snake:before {
	content: "\fccf";
}
.game-icon-snatch:before {
	content: "\fcd0";
}
.game-icon-sniffing-dog:before {
	content: "\fcd1";
}
.game-icon-snitch-quidditch-ball:before {
	content: "\fcd2";
}
.game-icon-snorkel:before {
	content: "\fcd3";
}
.game-icon-snout:before {
	content: "\fcd4";
}
.game-icon-snow-bottle:before {
	content: "\fcd5";
}
.game-icon-snowboard:before {
	content: "\fcd6";
}
.game-icon-snowflake-1:before {
	content: "\fcd7";
}
.game-icon-snowflake-2:before {
	content: "\fcd8";
}
.game-icon-snowing:before {
	content: "\fcd9";
}
.game-icon-snowman:before {
	content: "\fcda";
}
.game-icon-soap-experiment:before {
	content: "\fcdb";
}
.game-icon-soap:before {
	content: "\fcdc";
}
.game-icon-soccer-ball:before {
	content: "\fcdd";
}
.game-icon-soccer-field:before {
	content: "\fcde";
}
.game-icon-soccer-kick:before {
	content: "\fcdf";
}
.game-icon-socks:before {
	content: "\fce0";
}
.game-icon-soda-can:before {
	content: "\fce1";
}
.game-icon-sofa:before {
	content: "\fce2";
}
.game-icon-solar-power:before {
	content: "\fce3";
}
.game-icon-solar-system:before {
	content: "\fce4";
}
.game-icon-solar-time:before {
	content: "\fce5";
}
.game-icon-soldering-iron:before {
	content: "\fce6";
}
.game-icon-solid-leaf:before {
	content: "\fce7";
}
.game-icon-sombrero:before {
	content: "\fce8";
}
.game-icon-sonic-boom:before {
	content: "\fce9";
}
.game-icon-sonic-lightning:before {
	content: "\fcea";
}
.game-icon-sonic-screech:before {
	content: "\fceb";
}
.game-icon-sonic-shoes:before {
	content: "\fcec";
}
.game-icon-sonic-shout:before {
	content: "\fced";
}
.game-icon-soul-vessel:before {
	content: "\fcee";
}
.game-icon-sound-off:before {
	content: "\fcef";
}
.game-icon-sound-on:before {
	content: "\fcf0";
}
.game-icon-sound-waves:before {
	content: "\fcf1";
}
.game-icon-south-africa-flag:before {
	content: "\fcf2";
}
.game-icon-south-africa:before {
	content: "\fcf3";
}
.game-icon-south-america:before {
	content: "\fcf4";
}
.game-icon-south-korea:before {
	content: "\fcf5";
}
.game-icon-space-needle:before {
	content: "\fcf6";
}
.game-icon-space-shuttle:before {
	content: "\fcf7";
}
.game-icon-space-suit:before {
	content: "\fcf8";
}
.game-icon-spaceship:before {
	content: "\fcf9";
}
.game-icon-spade-skull:before {
	content: "\fcfa";
}
.game-icon-spade:before {
	content: "\fcfb";
}
.game-icon-spades:before {
	content: "\fcfc";
}
.game-icon-spain:before {
	content: "\fcfd";
}
.game-icon-spanner:before {
	content: "\fcfe";
}
.game-icon-spark-plug:before {
	content: "\fcff";
}
.game-icon-spark-spirit:before {
	content: "\fd00";
}
.game-icon-sparkles:before {
	content: "\fd01";
}
.game-icon-sparkling-sabre:before {
	content: "\fd02";
}
.game-icon-sparky-bomb:before {
	content: "\fd03";
}
.game-icon-sparrow:before {
	content: "\fd04";
}
.game-icon-spartan-helmet:before {
	content: "\fd05";
}
.game-icon-spartan:before {
	content: "\fd06";
}
.game-icon-spatter:before {
	content: "\fd07";
}
.game-icon-spawn-node:before {
	content: "\fd08";
}
.game-icon-speaker-off:before {
	content: "\fd09";
}
.game-icon-speaker:before {
	content: "\fd0a";
}
.game-icon-spear-feather:before {
	content: "\fd0b";
}
.game-icon-spear-hook:before {
	content: "\fd0c";
}
.game-icon-spearfishing:before {
	content: "\fd0d";
}
.game-icon-spears:before {
	content: "\fd0e";
}
.game-icon-spectacle-lenses:before {
	content: "\fd0f";
}
.game-icon-spectacles:before {
	content: "\fd10";
}
.game-icon-spectre-m4:before {
	content: "\fd11";
}
.game-icon-spectre:before {
	content: "\fd12";
}
.game-icon-speed-boat:before {
	content: "\fd13";
}
.game-icon-speedometer:before {
	content: "\fd14";
}
.game-icon-spell-book:before {
	content: "\fd15";
}
.game-icon-sperm-whale:before {
	content: "\fd16";
}
.game-icon-spider-alt:before {
	content: "\fd17";
}
.game-icon-spider-bot:before {
	content: "\fd18";
}
.game-icon-spider-eye:before {
	content: "\fd19";
}
.game-icon-spider-face:before {
	content: "\fd1a";
}
.game-icon-spider-mask:before {
	content: "\fd1b";
}
.game-icon-spider-web:before {
	content: "\fd1c";
}
.game-icon-spikeball:before {
	content: "\fd1d";
}
.game-icon-spiked-armor:before {
	content: "\fd1e";
}
.game-icon-spiked-ball:before {
	content: "\fd1f";
}
.game-icon-spiked-bat:before {
	content: "\fd20";
}
.game-icon-spiked-collar:before {
	content: "\fd21";
}
.game-icon-spiked-dragon-head:before {
	content: "\fd22";
}
.game-icon-spiked-fence:before {
	content: "\fd23";
}
.game-icon-spiked-halo:before {
	content: "\fd24";
}
.game-icon-spiked-mace:before {
	content: "\fd25";
}
.game-icon-spiked-shell:before {
	content: "\fd26";
}
.game-icon-spiked-shield:before {
	content: "\fd27";
}
.game-icon-spiked-shoulder-armor:before {
	content: "\fd28";
}
.game-icon-spiked-snail:before {
	content: "\fd29";
}
.game-icon-spiked-tail:before {
	content: "\fd2a";
}
.game-icon-spiked-tentacle:before {
	content: "\fd2b";
}
.game-icon-spiked-trunk:before {
	content: "\fd2c";
}
.game-icon-spiked-wall:before {
	content: "\fd2d";
}
.game-icon-spikes-full:before {
	content: "\fd2e";
}
.game-icon-spikes-half:before {
	content: "\fd2f";
}
.game-icon-spikes-init:before {
	content: "\fd30";
}
.game-icon-spikes:before {
	content: "\fd31";
}
.game-icon-spiky-eclipse:before {
	content: "\fd32";
}
.game-icon-spiky-explosion:before {
	content: "\fd33";
}
.game-icon-spiky-field:before {
	content: "\fd34";
}
.game-icon-spiky-pit:before {
	content: "\fd35";
}
.game-icon-spiky-wing:before {
	content: "\fd36";
}
.game-icon-spill:before {
	content: "\fd37";
}
.game-icon-spinal-coil:before {
	content: "\fd38";
}
.game-icon-spine-arrow:before {
	content: "\fd39";
}
.game-icon-spinning-blades:before {
	content: "\fd3a";
}
.game-icon-spinning-ribbons:before {
	content: "\fd3b";
}
.game-icon-spinning-sword:before {
	content: "\fd3c";
}
.game-icon-spinning-top:before {
	content: "\fd3d";
}
.game-icon-spinning-wheel:before {
	content: "\fd3e";
}
.game-icon-spiral-arrow:before {
	content: "\fd3f";
}
.game-icon-spiral-bloom:before {
	content: "\fd40";
}
.game-icon-spiral-bottle:before {
	content: "\fd41";
}
.game-icon-spiral-hilt:before {
	content: "\fd42";
}
.game-icon-spiral-lollipop:before {
	content: "\fd43";
}
.game-icon-spiral-shell:before {
	content: "\fd44";
}
.game-icon-spiral-tentacle:before {
	content: "\fd45";
}
.game-icon-spiral-thrust:before {
	content: "\fd46";
}
.game-icon-splash-2:before {
	content: "\fd47";
}
.game-icon-splash:before {
	content: "\fd48";
}
.game-icon-splashy-stream:before {
	content: "\fd49";
}
.game-icon-split-arrows:before {
	content: "\fd4a";
}
.game-icon-split-body:before {
	content: "\fd4b";
}
.game-icon-split-cross:before {
	content: "\fd4c";
}
.game-icon-splurt:before {
	content: "\fd4d";
}
.game-icon-spock-hand:before {
	content: "\fd4e";
}
.game-icon-spooky-house:before {
	content: "\fd4f";
}
.game-icon-spoon:before {
	content: "\fd50";
}
.game-icon-sport-medal:before {
	content: "\fd51";
}
.game-icon-spoted-flower:before {
	content: "\fd52";
}
.game-icon-spotted-arrowhead:before {
	content: "\fd53";
}
.game-icon-spotted-bug:before {
	content: "\fd54";
}
.game-icon-spotted-mushroom:before {
	content: "\fd55";
}
.game-icon-spotted-wound:before {
	content: "\fd56";
}
.game-icon-spoutnik:before {
	content: "\fd57";
}
.game-icon-spray:before {
	content: "\fd58";
}
.game-icon-spring:before {
	content: "\fd59";
}
.game-icon-sprint:before {
	content: "\fd5a";
}
.game-icon-sprout-disc:before {
	content: "\fd5b";
}
.game-icon-sprout:before {
	content: "\fd5c";
}
.game-icon-spy:before {
	content: "\fd5d";
}
.game-icon-spyglass:before {
	content: "\fd5e";
}
.game-icon-square-bottle:before {
	content: "\fd5f";
}
.game-icon-square:before {
	content: "\fd60";
}
.game-icon-squib:before {
	content: "\fd61";
}
.game-icon-squid-head:before {
	content: "\fd62";
}
.game-icon-squid:before {
	content: "\fd63";
}
.game-icon-squirrel:before {
	content: "\fd64";
}
.game-icon-sri-lanka:before {
	content: "\fd65";
}
.game-icon-stabbed-note:before {
	content: "\fd66";
}
.game-icon-stable:before {
	content: "\fd67";
}
.game-icon-stack:before {
	content: "\fd68";
}
.game-icon-stag-head:before {
	content: "\fd69";
}
.game-icon-stahlhelm:before {
	content: "\fd6a";
}
.game-icon-stairs-cake:before {
	content: "\fd6b";
}
.game-icon-stairs-goal:before {
	content: "\fd6c";
}
.game-icon-stairs:before {
	content: "\fd6d";
}
.game-icon-stake-hammer:before {
	content: "\fd6e";
}
.game-icon-stakes-fence:before {
	content: "\fd6f";
}
.game-icon-stalactites:before {
	content: "\fd70";
}
.game-icon-stalagtite:before {
	content: "\fd71";
}
.game-icon-stamper:before {
	content: "\fd72";
}
.game-icon-standing-potion:before {
	content: "\fd73";
}
.game-icon-stapler-heavy-duty:before {
	content: "\fd74";
}
.game-icon-stapler-pneumatic:before {
	content: "\fd75";
}
.game-icon-stapler:before {
	content: "\fd76";
}
.game-icon-star-altar:before {
	content: "\fd77";
}
.game-icon-star-cycle:before {
	content: "\fd78";
}
.game-icon-star-flag:before {
	content: "\fd79";
}
.game-icon-star-formation:before {
	content: "\fd7a";
}
.game-icon-star-gate:before {
	content: "\fd7b";
}
.game-icon-star-key:before {
	content: "\fd7c";
}
.game-icon-star-medal:before {
	content: "\fd7d";
}
.game-icon-star-prominences:before {
	content: "\fd7e";
}
.game-icon-star-pupil:before {
	content: "\fd7f";
}
.game-icon-star-sattelites:before {
	content: "\fd80";
}
.game-icon-star-shuriken:before {
	content: "\fd81";
}
.game-icon-star-skull:before {
	content: "\fd82";
}
.game-icon-star-struck:before {
	content: "\fd83";
}
.game-icon-star-swirl:before {
	content: "\fd84";
}
.game-icon-starfighter:before {
	content: "\fd85";
}
.game-icon-stars-stack:before {
	content: "\fd86";
}
.game-icon-staryu:before {
	content: "\fd87";
}
.game-icon-static-guard:before {
	content: "\fd88";
}
.game-icon-static-waves:before {
	content: "\fd89";
}
.game-icon-static:before {
	content: "\fd8a";
}
.game-icon-steak:before {
	content: "\fd8b";
}
.game-icon-stealth-bomber:before {
	content: "\fd8c";
}
.game-icon-steam-blast:before {
	content: "\fd8d";
}
.game-icon-steam-locomotive:before {
	content: "\fd8e";
}
.game-icon-steam:before {
	content: "\fd8f";
}
.game-icon-steampunk-goggles:before {
	content: "\fd90";
}
.game-icon-steamroller:before {
	content: "\fd91";
}
.game-icon-steel-claws-2:before {
	content: "\fd92";
}
.game-icon-steel-claws:before {
	content: "\fd93";
}
.game-icon-steel-door:before {
	content: "\fd94";
}
.game-icon-steeltoe-boots:before {
	content: "\fd95";
}
.game-icon-steelwing-emblem:before {
	content: "\fd96";
}
.game-icon-steering-wheel:before {
	content: "\fd97";
}
.game-icon-stegosaurus-scales:before {
	content: "\fd98";
}
.game-icon-stethoscope:before {
	content: "\fd99";
}
.game-icon-steyr-aug:before {
	content: "\fd9a";
}
.game-icon-stick-frame:before {
	content: "\fd9b";
}
.game-icon-stick-grenade:before {
	content: "\fd9c";
}
.game-icon-stick-splitting:before {
	content: "\fd9d";
}
.game-icon-sticking-plaster:before {
	content: "\fd9e";
}
.game-icon-sticky-boot:before {
	content: "\fd9f";
}
.game-icon-stigmata:before {
	content: "\fda0";
}
.game-icon-stiletto-2:before {
	content: "\fda1";
}
.game-icon-stiletto:before {
	content: "\fda2";
}
.game-icon-stitched-wound:before {
	content: "\fda3";
}
.game-icon-stockpiles:before {
	content: "\fda4";
}
.game-icon-stomach:before {
	content: "\fda5";
}
.game-icon-stomp-tornado:before {
	content: "\fda6";
}
.game-icon-stomp:before {
	content: "\fda7";
}
.game-icon-stone-axe:before {
	content: "\fda8";
}
.game-icon-stone-block:before {
	content: "\fda9";
}
.game-icon-stone-bridge:before {
	content: "\fdaa";
}
.game-icon-stone-bust:before {
	content: "\fdab";
}
.game-icon-stone-crafting:before {
	content: "\fdac";
}
.game-icon-stone-path:before {
	content: "\fdad";
}
.game-icon-stone-pile:before {
	content: "\fdae";
}
.game-icon-stone-spear:before {
	content: "\fdaf";
}
.game-icon-stone-sphere:before {
	content: "\fdb0";
}
.game-icon-stone-stack:before {
	content: "\fdb1";
}
.game-icon-stone-tablet:before {
	content: "\fdb2";
}
.game-icon-stone-throne:before {
	content: "\fdb3";
}
.game-icon-stone-tower:before {
	content: "\fdb4";
}
.game-icon-stone-wall:before {
	content: "\fdb5";
}
.game-icon-stone-wheel:before {
	content: "\fdb6";
}
.game-icon-stoned-skull:before {
	content: "\fdb7";
}
.game-icon-stop-sign:before {
	content: "\fdb8";
}
.game-icon-stopwatch-2:before {
	content: "\fdb9";
}
.game-icon-stopwatch:before {
	content: "\fdba";
}
.game-icon-stork-delivery:before {
	content: "\fdbb";
}
.game-icon-strafe:before {
	content: "\fdbc";
}
.game-icon-straight-pipe:before {
	content: "\fdbd";
}
.game-icon-strawberry:before {
	content: "\fdbe";
}
.game-icon-street-light:before {
	content: "\fdbf";
}
.game-icon-striking-arrows:before {
	content: "\fdc0";
}
.game-icon-striking-balls:before {
	content: "\fdc1";
}
.game-icon-striking-clamps:before {
	content: "\fdc2";
}
.game-icon-striking-diamonds:before {
	content: "\fdc3";
}
.game-icon-striking-splinter:before {
	content: "\fdc4";
}
.game-icon-striped-sun:before {
	content: "\fdc5";
}
.game-icon-striped-sword:before {
	content: "\fdc6";
}
.game-icon-strong-man:before {
	content: "\fdc7";
}
.game-icon-strong:before {
	content: "\fdc8";
}
.game-icon-strongbox:before {
	content: "\fdc9";
}
.game-icon-stump-regrowth:before {
	content: "\fdca";
}
.game-icon-stun-grenade:before {
	content: "\fdcb";
}
.game-icon-submarine-missile:before {
	content: "\fdcc";
}
.game-icon-submarine:before {
	content: "\fdcd";
}
.game-icon-subway:before {
	content: "\fdce";
}
.game-icon-suckered-tentacle:before {
	content: "\fdcf";
}
.game-icon-sugar-cane:before {
	content: "\fdd0";
}
.game-icon-suicide:before {
	content: "\fdd1";
}
.game-icon-suitcase:before {
	content: "\fdd2";
}
.game-icon-suits:before {
	content: "\fdd3";
}
.game-icon-summits:before {
	content: "\fdd4";
}
.game-icon-sun-cloud:before {
	content: "\fdd5";
}
.game-icon-sun-priest:before {
	content: "\fdd6";
}
.game-icon-sun-radiations:before {
	content: "\fdd7";
}
.game-icon-sun-spear:before {
	content: "\fdd8";
}
.game-icon-sun:before {
	content: "\fdd9";
}
.game-icon-sunbeams:before {
	content: "\fdda";
}
.game-icon-sundial:before {
	content: "\fddb";
}
.game-icon-sunflower:before {
	content: "\fddc";
}
.game-icon-sunglasses:before {
	content: "\fddd";
}
.game-icon-sunken-eye:before {
	content: "\fdde";
}
.game-icon-sunrise-2:before {
	content: "\fddf";
}
.game-icon-sunrise:before {
	content: "\fde0";
}
.game-icon-sunset:before {
	content: "\fde1";
}
.game-icon-super-mushroom:before {
	content: "\fde2";
}
.game-icon-supersonic-arrow:before {
	content: "\fde3";
}
.game-icon-supersonic-bullet:before {
	content: "\fde4";
}
.game-icon-surf-board:before {
	content: "\fde5";
}
.game-icon-surfer-van:before {
	content: "\fde6";
}
.game-icon-surprised-skull:before {
	content: "\fde7";
}
.game-icon-surprised:before {
	content: "\fde8";
}
.game-icon-surrounded-eye:before {
	content: "\fde9";
}
.game-icon-surrounded-shield:before {
	content: "\fdea";
}
.game-icon-sushis:before {
	content: "\fdeb";
}
.game-icon-suspension-bridge:before {
	content: "\fdec";
}
.game-icon-suspicious:before {
	content: "\fded";
}
.game-icon-sverd-i-fjell:before {
	content: "\fdee";
}
.game-icon-swallow-2:before {
	content: "\fdef";
}
.game-icon-swallow:before {
	content: "\fdf0";
}
.game-icon-swallower:before {
	content: "\fdf1";
}
.game-icon-swamp-bat:before {
	content: "\fdf2";
}
.game-icon-swamp:before {
	content: "\fdf3";
}
.game-icon-swan-breeze:before {
	content: "\fdf4";
}
.game-icon-swan:before {
	content: "\fdf5";
}
.game-icon-swap-bag:before {
	content: "\fdf6";
}
.game-icon-swimfins:before {
	content: "\fdf7";
}
.game-icon-swipe-card:before {
	content: "\fdf8";
}
.game-icon-swirl-ring:before {
	content: "\fdf9";
}
.game-icon-swirl-string:before {
	content: "\fdfa";
}
.game-icon-swirled-shell:before {
	content: "\fdfb";
}
.game-icon-swiss-army-knife:before {
	content: "\fdfc";
}
.game-icon-switch-weapon:before {
	content: "\fdfd";
}
.game-icon-switchblade:before {
	content: "\fdfe";
}
.game-icon-switzerland:before {
	content: "\fdff";
}
.game-icon-sword-altar:before {
	content: "\fe00";
}
.game-icon-sword-array:before {
	content: "\fe01";
}
.game-icon-sword-brandish:before {
	content: "\fe02";
}
.game-icon-sword-break:before {
	content: "\fe03";
}
.game-icon-sword-clash:before {
	content: "\fe04";
}
.game-icon-sword-hilt:before {
	content: "\fe05";
}
.game-icon-sword-in-stone:before {
	content: "\fe06";
}
.game-icon-sword-mold:before {
	content: "\fe07";
}
.game-icon-sword-slice:before {
	content: "\fe08";
}
.game-icon-sword-smithing:before {
	content: "\fe09";
}
.game-icon-sword-spade:before {
	content: "\fe0a";
}
.game-icon-sword-spin:before {
	content: "\fe0b";
}
.game-icon-sword-tie:before {
	content: "\fe0c";
}
.game-icon-sword-wound:before {
	content: "\fe0d";
}
.game-icon-swordman:before {
	content: "\fe0e";
}
.game-icon-swords-emblem:before {
	content: "\fe0f";
}
.game-icon-swords-power:before {
	content: "\fe10";
}
.game-icon-swordwoman:before {
	content: "\fe11";
}
.game-icon-sydney-opera-house:before {
	content: "\fe12";
}
.game-icon-syringe-2:before {
	content: "\fe13";
}
.game-icon-syringe:before {
	content: "\fe14";
}
.game-icon-t-brick:before {
	content: "\fe15";
}
.game-icon-t-rex-skull:before {
	content: "\fe16";
}
.game-icon-t-shirt:before {
	content: "\fe17";
}
.game-icon-tabi-boot:before {
	content: "\fe18";
}
.game-icon-table:before {
	content: "\fe19";
}
.game-icon-tablet:before {
	content: "\fe1a";
}
.game-icon-tabletop-players:before {
	content: "\fe1b";
}
.game-icon-tacos:before {
	content: "\fe1c";
}
.game-icon-tadpole:before {
	content: "\fe1d";
}
.game-icon-take-my-money:before {
	content: "\fe1e";
}
.game-icon-talk:before {
	content: "\fe1f";
}
.game-icon-tall-bridge:before {
	content: "\fe20";
}
.game-icon-tambourine:before {
	content: "\fe21";
}
.game-icon-tangerine:before {
	content: "\fe22";
}
.game-icon-tank-top:before {
	content: "\fe23";
}
.game-icon-tank-tread:before {
	content: "\fe24";
}
.game-icon-tank:before {
	content: "\fe25";
}
.game-icon-tanzania:before {
	content: "\fe26";
}
.game-icon-tap:before {
	content: "\fe27";
}
.game-icon-tapir:before {
	content: "\fe28";
}
.game-icon-target-arrows:before {
	content: "\fe29";
}
.game-icon-target-dummy:before {
	content: "\fe2a";
}
.game-icon-target-laser:before {
	content: "\fe2b";
}
.game-icon-target-poster:before {
	content: "\fe2c";
}
.game-icon-target-prize:before {
	content: "\fe2d";
}
.game-icon-target-shot:before {
	content: "\fe2e";
}
.game-icon-targeted:before {
	content: "\fe2f";
}
.game-icon-targeting:before {
	content: "\fe30";
}
.game-icon-tattered-banner:before {
	content: "\fe31";
}
.game-icon-taurus:before {
	content: "\fe32";
}
.game-icon-tavern-sign:before {
	content: "\fe33";
}
.game-icon-teacher:before {
	content: "\fe34";
}
.game-icon-team-downgrade:before {
	content: "\fe35";
}
.game-icon-team-idea:before {
	content: "\fe36";
}
.game-icon-team-upgrade:before {
	content: "\fe37";
}
.game-icon-teapot-leaves:before {
	content: "\fe38";
}
.game-icon-teapot:before {
	content: "\fe39";
}
.game-icon-tear-tracks:before {
	content: "\fe3a";
}
.game-icon-tearing:before {
	content: "\fe3b";
}
.game-icon-tec-9:before {
	content: "\fe3c";
}
.game-icon-techno-heart:before {
	content: "\fe3d";
}
.game-icon-tee-pipe:before {
	content: "\fe3e";
}
.game-icon-telefrag:before {
	content: "\fe3f";
}
.game-icon-telepathy:before {
	content: "\fe40";
}
.game-icon-teleport:before {
	content: "\fe41";
}
.game-icon-telescopic-baton:before {
	content: "\fe42";
}
.game-icon-teller-mine:before {
	content: "\fe43";
}
.game-icon-templar-eye:before {
	content: "\fe44";
}
.game-icon-templar-heart:before {
	content: "\fe45";
}
.game-icon-templar-shield:before {
	content: "\fe46";
}
.game-icon-temple-door:before {
	content: "\fe47";
}
.game-icon-temple-gate:before {
	content: "\fe48";
}
.game-icon-temporary-shield:before {
	content: "\fe49";
}
.game-icon-temptation:before {
	content: "\fe4a";
}
.game-icon-tennis-ball:before {
	content: "\fe4b";
}
.game-icon-tennis-court:before {
	content: "\fe4c";
}
.game-icon-tennis-racket:before {
	content: "\fe4d";
}
.game-icon-tension-snowflake:before {
	content: "\fe4e";
}
.game-icon-tentacle-heart:before {
	content: "\fe4f";
}
.game-icon-tentacle-strike:before {
	content: "\fe50";
}
.game-icon-tentacles-barrier:before {
	content: "\fe51";
}
.game-icon-tentacles-skull:before {
	content: "\fe52";
}
.game-icon-tentacurl:before {
	content: "\fe53";
}
.game-icon-terror:before {
	content: "\fe54";
}
.game-icon-tesla-coil:before {
	content: "\fe55";
}
.game-icon-tesla-turret:before {
	content: "\fe56";
}
.game-icon-tesla:before {
	content: "\fe57";
}
.game-icon-test-tubes:before {
	content: "\fe58";
}
.game-icon-texas:before {
	content: "\fe59";
}
.game-icon-theater-curtains:before {
	content: "\fe5a";
}
.game-icon-theater:before {
	content: "\fe5b";
}
.game-icon-thermometer-cold:before {
	content: "\fe5c";
}
.game-icon-thermometer-hot:before {
	content: "\fe5d";
}
.game-icon-thermometer-scale:before {
	content: "\fe5e";
}
.game-icon-think:before {
	content: "\fe5f";
}
.game-icon-third-eye:before {
	content: "\fe60";
}
.game-icon-thompson-m1:before {
	content: "\fe61";
}
.game-icon-thompson-m1928:before {
	content: "\fe62";
}
.game-icon-thor-fist:before {
	content: "\fe63";
}
.game-icon-thor-hammer:before {
	content: "\fe64";
}
.game-icon-thorn-helix:before {
	content: "\fe65";
}
.game-icon-thorned-arrow:before {
	content: "\fe66";
}
.game-icon-thorny-tentacle:before {
	content: "\fe67";
}
.game-icon-thorny-vine:before {
	content: "\fe68";
}
.game-icon-three-burning-balls:before {
	content: "\fe69";
}
.game-icon-three-friends:before {
	content: "\fe6a";
}
.game-icon-three-keys:before {
	content: "\fe6b";
}
.game-icon-three-leaves:before {
	content: "\fe6c";
}
.game-icon-three-pointed-shuriken:before {
	content: "\fe6d";
}
.game-icon-throne-king:before {
	content: "\fe6e";
}
.game-icon-throwing-ball:before {
	content: "\fe6f";
}
.game-icon-thrown-charcoal:before {
	content: "\fe70";
}
.game-icon-thrown-daggers:before {
	content: "\fe71";
}
.game-icon-thrown-knife:before {
	content: "\fe72";
}
.game-icon-thrown-spear:before {
	content: "\fe73";
}
.game-icon-thrust-bend:before {
	content: "\fe74";
}
.game-icon-thrust:before {
	content: "\fe75";
}
.game-icon-thumb-down:before {
	content: "\fe76";
}
.game-icon-thumb-up:before {
	content: "\fe77";
}
.game-icon-thunder-blade:before {
	content: "\fe78";
}
.game-icon-thunder-skull:before {
	content: "\fe79";
}
.game-icon-thunder-struck:before {
	content: "\fe7a";
}
.game-icon-thunderball:before {
	content: "\fe7b";
}
.game-icon-thwomp:before {
	content: "\fe7c";
}
.game-icon-tiara:before {
	content: "\fe7d";
}
.game-icon-tic-tac-toe:before {
	content: "\fe7e";
}
.game-icon-tick:before {
	content: "\fe7f";
}
.game-icon-ticket:before {
	content: "\fe80";
}
.game-icon-tie:before {
	content: "\fe81";
}
.game-icon-tied-scroll:before {
	content: "\fe82";
}
.game-icon-tiger-head:before {
	content: "\fe83";
}
.game-icon-tiger:before {
	content: "\fe84";
}
.game-icon-tightrope:before {
	content: "\fe85";
}
.game-icon-time-bomb:before {
	content: "\fe86";
}
.game-icon-time-dynamite:before {
	content: "\fe87";
}
.game-icon-time-synchronization:before {
	content: "\fe88";
}
.game-icon-time-trap:before {
	content: "\fe89";
}
.game-icon-tinker:before {
	content: "\fe8a";
}
.game-icon-tipi:before {
	content: "\fe8b";
}
.game-icon-tire-iron-cross:before {
	content: "\fe8c";
}
.game-icon-tire-iron:before {
	content: "\fe8d";
}
.game-icon-tire-tracks:before {
	content: "\fe8e";
}
.game-icon-tired-eye:before {
	content: "\fe8f";
}
.game-icon-toad-teeth:before {
	content: "\fe90";
}
.game-icon-toaster:before {
	content: "\fe91";
}
.game-icon-toggles:before {
	content: "\fe92";
}
.game-icon-token:before {
	content: "\fe93";
}
.game-icon-tomahawk:before {
	content: "\fe94";
}
.game-icon-tomato:before {
	content: "\fe95";
}
.game-icon-tombstone-2:before {
	content: "\fe96";
}
.game-icon-tombstone:before {
	content: "\fe97";
}
.game-icon-tongue:before {
	content: "\fe98";
}
.game-icon-toolbox:before {
	content: "\fe99";
}
.game-icon-tooth:before {
	content: "\fe9a";
}
.game-icon-toothbrush:before {
	content: "\fe9b";
}
.game-icon-top-hat:before {
	content: "\fe9c";
}
.game-icon-top-paw:before {
	content: "\fe9d";
}
.game-icon-topaz:before {
	content: "\fe9e";
}
.game-icon-torc:before {
	content: "\fe9f";
}
.game-icon-torch:before {
	content: "\fea0";
}
.game-icon-tornado-discs:before {
	content: "\fea1";
}
.game-icon-tornado:before {
	content: "\fea2";
}
.game-icon-torpedo:before {
	content: "\fea3";
}
.game-icon-tortoise:before {
	content: "\fea4";
}
.game-icon-totem-head:before {
	content: "\fea5";
}
.game-icon-totem-mask:before {
	content: "\fea6";
}
.game-icon-totem:before {
	content: "\fea7";
}
.game-icon-toucan:before {
	content: "\fea8";
}
.game-icon-tow-truck:before {
	content: "\fea9";
}
.game-icon-towel:before {
	content: "\feaa";
}
.game-icon-tower-bridge:before {
	content: "\feab";
}
.game-icon-tower-fall:before {
	content: "\feac";
}
.game-icon-tower-flag:before {
	content: "\fead";
}
.game-icon-toy-mallet:before {
	content: "\feae";
}
.game-icon-tracked-robot:before {
	content: "\feaf";
}
.game-icon-trade:before {
	content: "\feb0";
}
.game-icon-traffic-cone:before {
	content: "\feb1";
}
.game-icon-traffic-lights-green:before {
	content: "\feb2";
}
.game-icon-traffic-lights-orange:before {
	content: "\feb3";
}
.game-icon-traffic-lights-ready-to-go:before {
	content: "\feb4";
}
.game-icon-traffic-lights-red:before {
	content: "\feb5";
}
.game-icon-trail:before {
	content: "\feb6";
}
.game-icon-trample:before {
	content: "\feb7";
}
.game-icon-transform:before {
	content: "\feb8";
}
.game-icon-transfuse:before {
	content: "\feb9";
}
.game-icon-transparent-slime:before {
	content: "\feba";
}
.game-icon-transparent-tubes:before {
	content: "\febb";
}
.game-icon-transportation-rings:before {
	content: "\febc";
}
.game-icon-trap-mask:before {
	content: "\febd";
}
.game-icon-trash-can:before {
	content: "\febe";
}
.game-icon-travel-dress:before {
	content: "\febf";
}
.game-icon-tread:before {
	content: "\fec0";
}
.game-icon-treasure-map:before {
	content: "\fec1";
}
.game-icon-trebuchet:before {
	content: "\fec2";
}
.game-icon-tree-beehive:before {
	content: "\fec3";
}
.game-icon-tree-branch:before {
	content: "\fec4";
}
.game-icon-tree-door:before {
	content: "\fec5";
}
.game-icon-tree-face:before {
	content: "\fec6";
}
.game-icon-tree-growth:before {
	content: "\fec7";
}
.game-icon-tree-roots:before {
	content: "\fec8";
}
.game-icon-tree-swing:before {
	content: "\fec9";
}
.game-icon-treehouse:before {
	content: "\feca";
}
.game-icon-trefoil-lily:before {
	content: "\fecb";
}
.game-icon-trefoil-shuriken:before {
	content: "\fecc";
}
.game-icon-trench-assault:before {
	content: "\fecd";
}
.game-icon-trench-body-armor:before {
	content: "\fece";
}
.game-icon-trench-knife:before {
	content: "\fecf";
}
.game-icon-trench-spade:before {
	content: "\fed0";
}
.game-icon-triangle-target:before {
	content: "\fed1";
}
.game-icon-tribal-gear:before {
	content: "\fed2";
}
.game-icon-tribal-mask:before {
	content: "\fed3";
}
.game-icon-tribal-pendant:before {
	content: "\fed4";
}
.game-icon-tribal-shield:before {
	content: "\fed5";
}
.game-icon-tribunal-jury:before {
	content: "\fed6";
}
.game-icon-triceratops-head:before {
	content: "\fed7";
}
.game-icon-trident-shield:before {
	content: "\fed8";
}
.game-icon-trident:before {
	content: "\fed9";
}
.game-icon-triforce:before {
	content: "\feda";
}
.game-icon-trigger-hurt:before {
	content: "\fedb";
}
.game-icon-trilobite:before {
	content: "\fedc";
}
.game-icon-trinacria:before {
	content: "\fedd";
}
.game-icon-triorb:before {
	content: "\fede";
}
.game-icon-triple-beak:before {
	content: "\fedf";
}
.game-icon-triple-claws:before {
	content: "\fee0";
}
.game-icon-triple-corn:before {
	content: "\fee1";
}
.game-icon-triple-gate:before {
	content: "\fee2";
}
.game-icon-triple-lock:before {
	content: "\fee3";
}
.game-icon-triple-needle:before {
	content: "\fee4";
}
.game-icon-triple-plier:before {
	content: "\fee5";
}
.game-icon-triple-scratches:before {
	content: "\fee6";
}
.game-icon-triple-shells:before {
	content: "\fee7";
}
.game-icon-triple-skulls:before {
	content: "\fee8";
}
.game-icon-triple-yin:before {
	content: "\fee9";
}
.game-icon-tripwire:before {
	content: "\feea";
}
.game-icon-triquetra:before {
	content: "\feeb";
}
.game-icon-trireme:before {
	content: "\feec";
}
.game-icon-triton-head:before {
	content: "\feed";
}
.game-icon-troglodyte:before {
	content: "\feee";
}
.game-icon-trojan-horse:before {
	content: "\feef";
}
.game-icon-troll:before {
	content: "\fef0";
}
.game-icon-trombone:before {
	content: "\fef1";
}
.game-icon-tron-arrow:before {
	content: "\fef2";
}
.game-icon-trophies-shelf:before {
	content: "\fef3";
}
.game-icon-trophy-cup:before {
	content: "\fef4";
}
.game-icon-trophy:before {
	content: "\fef5";
}
.game-icon-tropical-fish:before {
	content: "\fef6";
}
.game-icon-trousers-2:before {
	content: "\fef7";
}
.game-icon-trousers:before {
	content: "\fef8";
}
.game-icon-trowel:before {
	content: "\fef9";
}
.game-icon-truce:before {
	content: "\fefa";
}
.game-icon-truck:before {
	content: "\fefb";
}
.game-icon-trumpet-flag:before {
	content: "\fefc";
}
.game-icon-trumpet:before {
	content: "\fefd";
}
.game-icon-trunk-mushroom:before {
	content: "\fefe";
}
.game-icon-tuba:before {
	content: "\feff";
}
.game-icon-tumbleweed:before {
	content: "\ff00";
}
.game-icon-tumor:before {
	content: "\ff01";
}
.game-icon-tumulus:before {
	content: "\ff02";
}
.game-icon-tune-pitch:before {
	content: "\ff03";
}
.game-icon-tunisia:before {
	content: "\ff04";
}
.game-icon-turban:before {
	content: "\ff05";
}
.game-icon-turbine:before {
	content: "\ff06";
}
.game-icon-turd:before {
	content: "\ff07";
}
.game-icon-turnstile:before {
	content: "\ff08";
}
.game-icon-turret:before {
	content: "\ff09";
}
.game-icon-turtle-shell:before {
	content: "\ff0a";
}
.game-icon-turtle:before {
	content: "\ff0b";
}
.game-icon-tusks-flag:before {
	content: "\ff0c";
}
.game-icon-tv-remote:before {
	content: "\ff0d";
}
.game-icon-tv-tower:before {
	content: "\ff0e";
}
.game-icon-tv:before {
	content: "\ff0f";
}
.game-icon-twin-shell:before {
	content: "\ff10";
}
.game-icon-twirl-center:before {
	content: "\ff11";
}
.game-icon-twirly-flower:before {
	content: "\ff12";
}
.game-icon-twister:before {
	content: "\ff13";
}
.game-icon-two-coins:before {
	content: "\ff14";
}
.game-icon-two-feathers:before {
	content: "\ff15";
}
.game-icon-two-handed-sword:before {
	content: "\ff16";
}
.game-icon-two-shadows:before {
	content: "\ff17";
}
.game-icon-tyre:before {
	content: "\ff18";
}
.game-icon-ubisoft-sun:before {
	content: "\ff19";
}
.game-icon-udder:before {
	content: "\ff1a";
}
.game-icon-ufo:before {
	content: "\ff1b";
}
.game-icon-ultrasound:before {
	content: "\ff1c";
}
.game-icon-uluru:before {
	content: "\ff1d";
}
.game-icon-umbrella-bayonet:before {
	content: "\ff1e";
}
.game-icon-umbrella:before {
	content: "\ff1f";
}
.game-icon-unbalanced:before {
	content: "\ff20";
}
.game-icon-uncertainty:before {
	content: "\ff21";
}
.game-icon-underground-cave:before {
	content: "\ff22";
}
.game-icon-underhand:before {
	content: "\ff23";
}
.game-icon-underwear-shorts:before {
	content: "\ff24";
}
.game-icon-underwear:before {
	content: "\ff25";
}
.game-icon-unfriendly-fire:before {
	content: "\ff26";
}
.game-icon-unicorn:before {
	content: "\ff27";
}
.game-icon-unicycle:before {
	content: "\ff28";
}
.game-icon-union-jack:before {
	content: "\ff29";
}
.game-icon-unlit-bomb:before {
	content: "\ff2a";
}
.game-icon-unlit-candelabra:before {
	content: "\ff2b";
}
.game-icon-unlocking:before {
	content: "\ff2c";
}
.game-icon-unplugged:before {
	content: "\ff2d";
}
.game-icon-unstable-orb:before {
	content: "\ff2e";
}
.game-icon-unstable-projectile:before {
	content: "\ff2f";
}
.game-icon-up-card:before {
	content: "\ff30";
}
.game-icon-upgrade:before {
	content: "\ff31";
}
.game-icon-uprising:before {
	content: "\ff32";
}
.game-icon-ursa-major:before {
	content: "\ff33";
}
.game-icon-uruguay:before {
	content: "\ff34";
}
.game-icon-usa-flag:before {
	content: "\ff35";
}
.game-icon-usable:before {
	content: "\ff36";
}
.game-icon-usb-key:before {
	content: "\ff37";
}
.game-icon-ushanka:before {
	content: "\ff38";
}
.game-icon-uzi:before {
	content: "\ff39";
}
.game-icon-vacuum-cleaner:before {
	content: "\ff3a";
}
.game-icon-valley:before {
	content: "\ff3b";
}
.game-icon-valve:before {
	content: "\ff3c";
}
.game-icon-vampire-cape:before {
	content: "\ff3d";
}
.game-icon-vampire-dracula:before {
	content: "\ff3e";
}
.game-icon-van-damme-split:before {
	content: "\ff3f";
}
.game-icon-vanilla-flower:before {
	content: "\ff40";
}
.game-icon-velocipede:before {
	content: "\ff41";
}
.game-icon-velociraptor-tracks:before {
	content: "\ff42";
}
.game-icon-velociraptor:before {
	content: "\ff43";
}
.game-icon-vending-machine:before {
	content: "\ff44";
}
.game-icon-venezuela:before {
	content: "\ff45";
}
.game-icon-venus-flytrap:before {
	content: "\ff46";
}
.game-icon-venus-of-willendorf:before {
	content: "\ff47";
}
.game-icon-vertical-banner:before {
	content: "\ff48";
}
.game-icon-vertical-flip:before {
	content: "\ff49";
}
.game-icon-vhs:before {
	content: "\ff4a";
}
.game-icon-vial:before {
	content: "\ff4b";
}
.game-icon-vibrating-ball:before {
	content: "\ff4c";
}
.game-icon-vibrating-shield:before {
	content: "\ff4d";
}
.game-icon-vibrating-smartphone:before {
	content: "\ff4e";
}
.game-icon-video-camera:before {
	content: "\ff4f";
}
.game-icon-video-conference:before {
	content: "\ff50";
}
.game-icon-viking-church:before {
	content: "\ff51";
}
.game-icon-viking-head:before {
	content: "\ff52";
}
.game-icon-viking-helmet:before {
	content: "\ff53";
}
.game-icon-viking-longhouse:before {
	content: "\ff54";
}
.game-icon-viking-shield:before {
	content: "\ff55";
}
.game-icon-vile-fluid:before {
	content: "\ff56";
}
.game-icon-village:before {
	content: "\ff57";
}
.game-icon-vine-flower:before {
	content: "\ff58";
}
.game-icon-vine-leaf:before {
	content: "\ff59";
}
.game-icon-vine-whip:before {
	content: "\ff5a";
}
.game-icon-vines:before {
	content: "\ff5b";
}
.game-icon-vintage-robot:before {
	content: "\ff5c";
}
.game-icon-viola:before {
	content: "\ff5d";
}
.game-icon-violin:before {
	content: "\ff5e";
}
.game-icon-virgo:before {
	content: "\ff5f";
}
.game-icon-virtual-marker:before {
	content: "\ff60";
}
.game-icon-virus:before {
	content: "\ff61";
}
.game-icon-visored-helm:before {
	content: "\ff62";
}
.game-icon-vitruvian-man:before {
	content: "\ff63";
}
.game-icon-volcano:before {
	content: "\ff64";
}
.game-icon-volleyball-ball:before {
	content: "\ff65";
}
.game-icon-vomiting:before {
	content: "\ff66";
}
.game-icon-voodoo-doll:before {
	content: "\ff67";
}
.game-icon-vortex:before {
	content: "\ff68";
}
.game-icon-vote:before {
	content: "\ff69";
}
.game-icon-vr-headset:before {
	content: "\ff6a";
}
.game-icon-vulture:before {
	content: "\ff6b";
}
.game-icon-vuvuzelas:before {
	content: "\ff6c";
}
.game-icon-walk:before {
	content: "\ff6d";
}
.game-icon-walkie-talkie:before {
	content: "\ff6e";
}
.game-icon-walking-boot:before {
	content: "\ff6f";
}
.game-icon-walking-scout:before {
	content: "\ff70";
}
.game-icon-walking-turret:before {
	content: "\ff71";
}
.game-icon-wall-light:before {
	content: "\ff72";
}
.game-icon-wallet:before {
	content: "\ff73";
}
.game-icon-walrus-head:before {
	content: "\ff74";
}
.game-icon-walther-ppk:before {
	content: "\ff75";
}
.game-icon-wanted-reward:before {
	content: "\ff76";
}
.game-icon-war-axe:before {
	content: "\ff77";
}
.game-icon-war-bonnet:before {
	content: "\ff78";
}
.game-icon-war-pick:before {
	content: "\ff79";
}
.game-icon-warhammer:before {
	content: "\ff7a";
}
.game-icon-warlock-eye:before {
	content: "\ff7b";
}
.game-icon-warlock-hood:before {
	content: "\ff7c";
}
.game-icon-warp-pipe:before {
	content: "\ff7d";
}
.game-icon-washing-machine:before {
	content: "\ff7e";
}
.game-icon-wasp-sting:before {
	content: "\ff7f";
}
.game-icon-watch:before {
	content: "\ff80";
}
.game-icon-watchtower:before {
	content: "\ff81";
}
.game-icon-water-bolt:before {
	content: "\ff82";
}
.game-icon-water-bottle:before {
	content: "\ff83";
}
.game-icon-water-diviner-stick:before {
	content: "\ff84";
}
.game-icon-water-drop:before {
	content: "\ff85";
}
.game-icon-water-flask:before {
	content: "\ff86";
}
.game-icon-water-fountain:before {
	content: "\ff87";
}
.game-icon-water-gallon:before {
	content: "\ff88";
}
.game-icon-water-gun:before {
	content: "\ff89";
}
.game-icon-water-mill:before {
	content: "\ff8a";
}
.game-icon-water-polo:before {
	content: "\ff8b";
}
.game-icon-water-recycling:before {
	content: "\ff8c";
}
.game-icon-water-splash:before {
	content: "\ff8d";
}
.game-icon-water-tank:before {
	content: "\ff8e";
}
.game-icon-water-tower:before {
	content: "\ff8f";
}
.game-icon-waterfall:before {
	content: "\ff90";
}
.game-icon-watering-can:before {
	content: "\ff91";
}
.game-icon-watermelon:before {
	content: "\ff92";
}
.game-icon-wave-crest:before {
	content: "\ff93";
}
.game-icon-wave-strike:before {
	content: "\ff94";
}
.game-icon-wave-surfer:before {
	content: "\ff95";
}
.game-icon-waves:before {
	content: "\ff96";
}
.game-icon-wavy-chains:before {
	content: "\ff97";
}
.game-icon-wavy-itinerary:before {
	content: "\ff98";
}
.game-icon-wax-seal:before {
	content: "\ff99";
}
.game-icon-wax-tablet:before {
	content: "\ff9a";
}
.game-icon-web-spit:before {
	content: "\ff9b";
}
.game-icon-weight-crush:before {
	content: "\ff9c";
}
.game-icon-weight-lifting-down:before {
	content: "\ff9d";
}
.game-icon-weight-lifting-up:before {
	content: "\ff9e";
}
.game-icon-weight-scale:before {
	content: "\ff9f";
}
.game-icon-weight:before {
	content: "\ffa0";
}
.game-icon-well:before {
	content: "\ffa1";
}
.game-icon-werewolf:before {
	content: "\ffa2";
}
.game-icon-western-hat:before {
	content: "\ffa3";
}
.game-icon-whale-tail:before {
	content: "\ffa4";
}
.game-icon-wheat:before {
	content: "\ffa5";
}
.game-icon-wheelbarrow:before {
	content: "\ffa6";
}
.game-icon-whip:before {
	content: "\ffa7";
}
.game-icon-whiplash:before {
	content: "\ffa8";
}
.game-icon-whirlpool-shuriken:before {
	content: "\ffa9";
}
.game-icon-whirlwind:before {
	content: "\ffaa";
}
.game-icon-whisk:before {
	content: "\ffab";
}
.game-icon-whistle:before {
	content: "\ffac";
}
.game-icon-white-book:before {
	content: "\ffad";
}
.game-icon-white-cat:before {
	content: "\ffae";
}
.game-icon-white-tower:before {
	content: "\ffaf";
}
.game-icon-wide-arrow-dunk:before {
	content: "\ffb0";
}
.game-icon-wifi-router:before {
	content: "\ffb1";
}
.game-icon-wildfires:before {
	content: "\ffb2";
}
.game-icon-william-tell-skull:before {
	content: "\ffb3";
}
.game-icon-william-tell:before {
	content: "\ffb4";
}
.game-icon-willow-tree:before {
	content: "\ffb5";
}
.game-icon-winchester-rifle:before {
	content: "\ffb6";
}
.game-icon-wind-hole:before {
	content: "\ffb7";
}
.game-icon-wind-slap:before {
	content: "\ffb8";
}
.game-icon-wind-turbine:before {
	content: "\ffb9";
}
.game-icon-windchimes:before {
	content: "\ffba";
}
.game-icon-windmill:before {
	content: "\ffbb";
}
.game-icon-window-bars:before {
	content: "\ffbc";
}
.game-icon-window:before {
	content: "\ffbd";
}
.game-icon-windpump:before {
	content: "\ffbe";
}
.game-icon-windsock:before {
	content: "\ffbf";
}
.game-icon-windy-stripes:before {
	content: "\ffc0";
}
.game-icon-wine-bottle:before {
	content: "\ffc1";
}
.game-icon-wine-glass:before {
	content: "\ffc2";
}
.game-icon-wing-cloak:before {
	content: "\ffc3";
}
.game-icon-winged-arrow:before {
	content: "\ffc4";
}
.game-icon-winged-emblem:before {
	content: "\ffc5";
}
.game-icon-winged-leg:before {
	content: "\ffc6";
}
.game-icon-winged-scepter:before {
	content: "\ffc7";
}
.game-icon-winged-shield:before {
	content: "\ffc8";
}
.game-icon-winged-sword:before {
	content: "\ffc9";
}
.game-icon-wingfoot:before {
	content: "\ffca";
}
.game-icon-winter-gloves:before {
	content: "\ffcb";
}
.game-icon-winter-hat:before {
	content: "\ffcc";
}
.game-icon-wire-coil:before {
	content: "\ffcd";
}
.game-icon-wireframe-globe:before {
	content: "\ffce";
}
.game-icon-wisdom:before {
	content: "\ffcf";
}
.game-icon-witch-face:before {
	content: "\ffd0";
}
.game-icon-witch-flight:before {
	content: "\ffd1";
}
.game-icon-wizard-face:before {
	content: "\ffd2";
}
.game-icon-wizard-staff:before {
	content: "\ffd3";
}
.game-icon-wok:before {
	content: "\ffd4";
}
.game-icon-wolf-head:before {
	content: "\ffd5";
}
.game-icon-wolf-howl:before {
	content: "\ffd6";
}
.game-icon-wolf-trap:before {
	content: "\ffd7";
}
.game-icon-wolverine-claws-2:before {
	content: "\ffd8";
}
.game-icon-wolverine-claws:before {
	content: "\ffd9";
}
.game-icon-woman-elf-face:before {
	content: "\ffda";
}
.game-icon-wood-axe:before {
	content: "\ffdb";
}
.game-icon-wood-beam:before {
	content: "\ffdc";
}
.game-icon-wood-cabin:before {
	content: "\ffdd";
}
.game-icon-wood-canoe:before {
	content: "\ffde";
}
.game-icon-wood-club:before {
	content: "\ffdf";
}
.game-icon-wood-frame:before {
	content: "\ffe0";
}
.game-icon-wood-pile:before {
	content: "\ffe1";
}
.game-icon-wood-stick:before {
	content: "\ffe2";
}
.game-icon-wooden-chair:before {
	content: "\ffe3";
}
.game-icon-wooden-clogs:before {
	content: "\ffe4";
}
.game-icon-wooden-crate:before {
	content: "\ffe5";
}
.game-icon-wooden-door:before {
	content: "\ffe6";
}
.game-icon-wooden-fence:before {
	content: "\ffe7";
}
.game-icon-wooden-helmet:before {
	content: "\ffe8";
}
.game-icon-wooden-pegleg:before {
	content: "\ffe9";
}
.game-icon-wooden-pier:before {
	content: "\ffea";
}
.game-icon-wooden-sign:before {
	content: "\ffeb";
}
.game-icon-wool:before {
	content: "\ffec";
}
.game-icon-world:before {
	content: "\ffed";
}
.game-icon-worm-mouth:before {
	content: "\ffee";
}
.game-icon-worms:before {
	content: "\ffef";
}
.game-icon-worried-eyes:before {
	content: "\fff0";
}
.game-icon-wrapped-heart:before {
	content: "\fff1";
}
.game-icon-wrapped-sweet:before {
	content: "\fff2";
}
.game-icon-wrapping-star:before {
	content: "\fff3";
}
.game-icon-wrecking-ball:before {
	content: "\fff4";
}
.game-icon-wrench:before {
	content: "\fff5";
}
.game-icon-wyvern:before {
	content: "\fff6";
}
.game-icon-xylophone:before {
	content: "\fff7";
}
.game-icon-yarn:before {
	content: "\fff8";
}
.game-icon-yin-yang:before {
	content: "\fff9";
}
.game-icon-yunluo:before {
	content: "\fffa";
}
.game-icon-z-brick:before {
	content: "\fffb";
}
.game-icon-zat-gun:before {
	content: "\fffc";
}
.game-icon-zebra-shield:before {
	content: "\fffd";
}
.game-icon-zeppelin:before {
	content: "\fffe";
}
.game-icon-zeus-sword:before {
	content: "\ffff";
}
.game-icon-zig-arrow:before {
	content: "\10000";
}
.game-icon-zigzag-cage:before {
	content: "\10001";
}
.game-icon-zigzag-hieroglyph:before {
	content: "\10002";
}
.game-icon-zigzag-leaf:before {
	content: "\10003";
}
.game-icon-zigzag-tune:before {
	content: "\10004";
}
.game-icon-zipper:before {
	content: "\10005";
}
