@import 'styles/common/index.scss';

.breakdown-window {
    @include panel();
    $standardWidthRatio: 0.9;
    $slimWidthRatio: 0.6;

    top: 64px;
    position: relative;
    padding: $panelPadding;
    width: 100%;
    min-height: 92px;
    max-height: $breakdownWindowMaxHeight;
    overflow-y: auto;

    max-width: $phoneThreshold * $standardWidthRatio;
    
    &.slim {
        max-width: $phoneThreshold * $slimWidthRatio;
    }

    @media screen and (min-width: $tabletThreshold) {
        max-width: $tabletThreshold * $standardWidthRatio;

        &.slim {
            max-width: $tabletThreshold * $slimWidthRatio;
        }
    }

    @media screen and (min-width: $laptopThreshold) {
        max-width: $laptopThreshold * $standardWidthRatio;

        &.slim {
            max-width: $laptopThreshold * $slimWidthRatio;
        }
    }

    @media screen and (min-width: $desktopThreshold) {
        max-width: $desktopThreshold * $standardWidthRatio;

        &.slim {
            max-width: $desktopThreshold * $slimWidthRatio;
        }
    }

    >header {
        font-size: $fontSizeTitle;

        >h1 {
            margin-top: 0;
            margin-bottom: $spacer;
            padding-right: 1em;
        }
    }

    .value-modifier {
        display: flex;

        >label {
            display: block;
            width: 80%;
        }

        div.value {
            padding-right: 22px;
        }

        .value {
            display: block;
            width: 20%;
            text-align: right;
        }

        &.total {
            @include standout();
            margin-top: $spacer;
            border-top: $borderThin;
        }
    }
}