@import 'styles/common/index.scss';

.adventure-event {
    header {
        padding: $halfPadder 0;
        border-bottom: $borderThick;
        border-color: $colorWhite;
    }

    .event-main-row {
        display: flex;

        .event-text {
            @include flex-fill();
        }
    
        .timestamp {
            font-size: 0.75em;
        }
    }

    .event-rolls {
        @include notice($backgroundStandard);
        margin-top: $halfPadder;
        padding: $halfPadder;
    }
}