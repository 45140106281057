@import 'styles/pages.scss';

.adventure {
    @include themed-page();

    .adventure-header {
        display: flex;

        .adventure-name {
            @include flex-fill();
        }
    }

    .adventure-invite {
        .copyable-text {
            margin-bottom: $padder;
        }
    }

    .adventure-content {
        margin-top: $padder;

        .notice {
            margin-right: $padder;
        }
    }

    .communal-rolls {
        border-top: $borderThin;
    }

    .adventure-notes {
        display: flex;

        .notes-section {
            flex: 1 0 0;
        }
    }
}

.adventurers {
    padding: $padder 0;

    .adventurer {
        @include panel-darker();
        margin-bottom: $padder;
        padding: $padder;

        &:last-child {
            margin-bottom: 0;
        }

        label {
            font-weight: bold;
        }
    }
}

.adventure-log {
    margin: $padder 0;

    .notice {
        margin: $halfPadder 0;
        width: 100%;
    }
}