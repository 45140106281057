@import 'styles/common/index.scss';

.character-quick-access {
    margin: $spacer 0;
    display: flex;
    align-items: center;

    .strict-toggle {
        @include flex-fill();
    }
}