$backgroundStandardImage: url('/public/assets/images/backgrounds/background-standard.png');
$backgroundStandard: $backgroundStandardImage repeat;

$backgroundInterestImage: url('/public/assets/images/backgrounds/background-interest.png');
$backgroundInterest: $backgroundInterestImage repeat;

$backgroundDarkerImage: url('/public/assets/images/backgrounds/background-darker.png');
$backgroundDarker: $backgroundDarkerImage repeat;

$backgroundDarkImage: url('/public/assets/images/backgrounds/background-dark.png');
$backgroundDark: $backgroundDarkImage repeat;

$backgroundRedImage: url('/public/assets/images/backgrounds/background-red.png');
$backgroundRed: $backgroundRedImage repeat;

$backgroundGreenImage: url('/public/assets/images/backgrounds/background-green.png');
$backgroundGreen: $backgroundGreenImage repeat;

$backgroundBlueImage: url('/public/assets/images/backgrounds/background-blue.png');
$backgroundBlue: $backgroundBlueImage repeat;

$backgroundCaret: url('/public/assets/images/backgrounds/caret.png');