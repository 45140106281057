@import '../selects.scss';

.multiselect {
    @include select;
    
    .selections {
        position: relative;
        z-index: 2;
        cursor: text;
        text-align: left;

        .selection {
            display: inline-block;
            margin: 2px;
            line-height: 32px;
        }
    }
}