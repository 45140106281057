@import 'styles/common/index.scss';

@mixin die() {
    $dieDimension: 24px;
    $dieDimensionBig: 64px;

    @include font-fancy();
    @include symmetrical($dieDimension);
    position: relative;
    display: inline-block;
    background: url('/public/assets/images/symbols/single-shape-icon.svg');
    box-sizing: content-box;
    margin: 0 ($padder * 0.5);
    color: $colorBlack;
    text-align: center;
    line-height: $dieDimension;

    &.disabled {
        font-style: normal;
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    .die-face {
        @include animate-transitions();
        $faceHeight: 16px;
        position: relative;
        top: 2px;
        left: 2px;
        display: inline-block;
        width: 18px;
        height: $faceHeight;
        background: $backgroundStandard;
        font-size: $fontSize;
        line-height: $faceHeight;
        user-select: none;
    }

    &.big {
        @include symmetrical($dieDimensionBig);
        
        .die-face {
            @include symmetrical(44px);
            $faceFontSize: 42px;
            top: 15px;
            left: 5px;
            font-size: $faceFontSize;
            line-height: $faceFontSize;
        }
    }

    &.interactable:hover:not(.disabled):not(.rolling) .die-face, &.selected .die-face {
        background: $backgroundInterest;
    }

    @keyframes rolling-face {
        #{100% * 0/6} {
            content: "2";
        }
        #{100% * 1/6} {
            content: "5";
        }
        #{100% * 2/6} {
            content: "1";
        }
        #{100% * 3/6} {
            content: "3";
        }
        #{100% * 4/6} {
            content: "6";
        }
        #{100% * 5/6} {
            content: "4";
        }
    }

    .rolling-face {
        display: none;

        &:after {
            animation: rolling-face 0.2s linear infinite;
            content: "2";
        }
    }

    @keyframes rolling {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    &.rolling {
        animation: rolling 0.25s linear infinite;

        .rolling-face {
            display: inline-block;
        }

        .set-face {
            display: none;
        }
    }
}

.die {
    @include die();
}