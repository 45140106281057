@import 'styles/common/index.scss';

.themed-checkbox {
    $checkWidth: 48px;
    $checkHeight: $checkWidth * 0.5;
    position: relative;
    display: inline-block;
    width: $checkWidth;
    height: $checkHeight;
    vertical-align: middle;
    font-size: $checkWidth;
    cursor: pointer;
    user-select: none;

    >input[type="checkbox"] {
        display: none;
    }

    &.checked {
        color: $colorInputOrange;
    }

    &.unchecked {
        color: $colorInputGray;
    }

    &.disabled {
        // color: $colorDisabledGray;

        .icon-wrapper.outlines {
            color: $colorDisabledGray;
        }
    }

    .icon-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        width: 100%;
        height: 100%;

        &.outlines {
            z-index: 1;
            color: $colorBlack;
        }
    }

    .site-icon {
        line-height: 0.5em;
    }
}