.move-item-container {
    display: inline;
    position: relative;
}

.move-item-window {
    top: 0;
    left: 100%;
    cursor: default;
    white-space: nowrap;
}